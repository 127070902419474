.form-contato-options {
    display: inline-block;
}

.form-contato-options_paragraph {
    display: inline-block;
    margin-right: 15px;
}

.MuiFilledInput-root {
    //align-items: baseline;
    padding: 0px;
    padding-left: 10px;
}

.fale-conosco-mensagem-adornment {
    align-items: flex-start;
    padding: 0px;
}

.dropzone-curriculo {
    text-align: center;
    background-color: white;

    .pimaco-icons.upload-icon svg {
        height: 120px;

        .st0 {
            fill:none;
            stroke:#020203;
            stroke-miterlimit:10;
        }

        .st1 {
            fill:none;
            stroke:#231F20;
            stroke-miterlimit:10;
        }

    }
}

.trabalhe-conosco-p-radioboxes {
    display: inline-block;
    margin-right: 1em;
    vertical-align: middle;
}

.trabalhe-conosco-radioGroup {
    display: inline-block;
}

.form-contato-submit-button {
    margin-top: 25px;
}

.trabalhe-conosco-h1-curriculo {
    margin-left: 16px;
    margin-bottom: 0px;
}

.forms-accept-buttons {
    margin-left: 16px;
    margin-top: 15px;
}

.dropzone-icon {
    font-size: 45px;
}

.MuiFormControlLabel-root, .MuiTypography-root {
    font-family: 'Manrope';
}