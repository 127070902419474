.editor-container {
    flex: 1 !important;
    position: relative;

    .editor-components {
        position: absolute;
        top: 8px;
        left: 0;
        z-index: 1;
    }

    .toolbar-container {    
        display: flex;
        align-items: flex-start;
        margin-left: 5px;
        margin-top: 5px;
        z-index: 2;
        position: absolute;
        left: 0;
        transition: left 0.5s;

        .MuiIconButton-root {
            background-color: #f2f2f2;
            border-radius: 5px;
            margin-left: 5px;
            transition: transform 0.5s;
        }

        .toolbar-accordion-container {
            width: 250px;
        }

        &.hidden {
            left: -250px;

            .MuiIconButton-root {
                transform: rotate(180deg);
            }
        }

        .search-field-faq {
            margin-bottom: 10px;
            
            .pimaco-icons {
                width: 1em;
                height: 1em;
            }
        }
    }
    
    .editor-tb-button-container {
        display: flex;
        justify-content: center;
        margin: 5px 0;
        position: sticky;
        bottom: 0;
        background: #f8f8f8;
    }
    
}