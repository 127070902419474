

img.gabaritos-downloads-item-image {
    object-fit: cover;
    width: -webkit-fill-available;
    height: 100%;
}

.parametros-container {
    
    .pimaco-download-box-container { 
        //width: 180px; 
        height: 280px;
        position: relative;
        padding: 15px;
    }
    
    .pimaco-download-box-info-container {
        position: absolute;
        height: 40%;
        bottom: 0;
    }    

}

.content-header-parametros {
    position: absolute;
    left: 65%;
    top: 20%;
    max-width: 300px;

    h1 {
        font-size: 2.5em;
        font-weight: 600;
        margin: 10px 0;
    }
}

@media screen and (max-width: 900px) {
    .content-header-parametros {       
        top: 10%;
    
        h1 {
            font-size: 2em;
        }

    }
}

@media screen and (max-width: 600px) {
    .content-header-parametros {
        left: 45%;
        top: 20%;
        max-width: 200px;
    
        h1 {
            font-size: 1.5em;
        }

        p {
            font-size: 0.8em;
        }
    }

    .content-header-image-parametros {
        object-position: 90%;
    }
}