@charset "UTF-8";
.whatsapp-floating-button {
  position: fixed;
  right: 0;
  top: 50%;
}

.btn-link {
  text-decoration: none;
}
.btn-link.download-text {
  font-weight: bold;
}
.btn-link.download-text:hover, .btn-link.download-text:link, .btn-link.download-text:visited, .btn-link.download-text:active {
  color: inherit;
}

.btn {
  padding: 10px 50px;
  border-radius: 0;
  font-family: "Stara";
  font-weight: bolder;
}

@media screen and (max-width: 600px) {
  .btn {
    padding: 10px 25px;
  }
}
.btn-contained-primary {
  background-color: #202020;
  border: 2px solid #202020;
}
.btn-contained-primary:hover {
  background-color: black;
}

.btn-contained-secondary {
  background-color: white;
  color: #202020;
  border: 2px solid #202020;
  transition: all 0.5s;
}
.btn-contained-secondary:hover {
  background-color: #202020;
  color: white;
  transition: all 0.5s;
}

.btn-contained-danger {
  background-color: #D32F2F;
  color: white;
  transition: all 0.5s;
}
.btn-contained-danger :not(.Mui-disabled) {
  border: 2px solid #D32F2F;
}
.btn-contained-danger:hover {
  background-color: #A82424;
  color: white;
  transition: all 0.5s;
}

.btn-contained-thertiary {
  background-color: #ffcb05;
  color: black;
  border: 2px solid #ffcb05;
  transition: all 0.5s;
}
.btn-contained-thertiary:hover {
  background-color: #ffd100;
  color: black;
  transition: all 0.5s;
}

.btn-outlined-primary {
  background-color: transparent;
  border: 2px solid black;
  color: black;
}
.btn-outlined-primary:hover {
  border: 2px solid black;
}

.button-slider-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 35px !important;
  flex-direction: row;
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
}

.btn-slider-container {
  border-bottom: 4px solid;
  width: 170px;
  display: flex;
  cursor: pointer;
}
.btn-slider-container.btn-slider-next {
  justify-content: flex-end;
  border-color: white;
}
.btn-slider-container.btn-slider-prev {
  justify-content: flex-start;
  border-color: black;
}
.btn-slider-container p {
  margin-bottom: 2px;
  color: black;
}

.white-bg-btn .btn-slider-container.btn-slider-next {
  border-color: #ebebe3;
}

.MuiFilledInput-root {
  background-color: white;
  outline: 1px solid black;
  font-family: "Manrope";
  border-radius: 0;
}
.MuiFilledInput-root:hover {
  background-color: rgba(255, 255, 255, 0.9);
}
.MuiFilledInput-root.Mui-disabled:hover {
  background-color: rgba(0, 0, 0, 0.12);
}
.MuiFilledInput-root.Mui-focused {
  background-color: white;
}
.MuiFilledInput-root:after, .MuiFilledInput-root::before {
  display: none;
}

.MuiFilledInput-input {
  padding: 18px 12px;
  font-weight: 500;
}

.MuiAutocomplete-popper, .MuiAutocomplete-listbox {
  font-family: "Manrope";
}

.MuiAutocomplete-groupLabel {
  font-family: "Manrope";
  font-weight: 800;
  font-size: 1rem;
}

.MuiAutocomplete-inputRoot {
  padding-top: 9px !important;
  padding-bottom: 9px;
}

.MuiFormControlLabel-root .MuiSvgIcon-root {
  background-color: white;
  color: transparent;
  width: 0.9em;
  height: 0.9em;
}
.MuiFormControlLabel-root .MuiCheckbox-root.Mui-checked .MuiSvgIcon-root {
  color: #202020;
}

.MuiMenuItem-root {
  font-family: "Manrope";
  font-weight: 500;
}

.MuiInputLabel-root, .MuiFormControlLabel-root, .MuiTypography-root {
  font-family: "Manrope";
  color: #202020;
  font-weight: 600;
}

.MuiInputAdornment-root {
  color: black;
  font-weight: 600;
  margin-top: 0 !important;
}
.MuiInputAdornment-root.MuiInputAdornment-positionEnd {
  padding: 0 10px;
}

.MuiFilledInput-root.number-input .MuiFilledInput-input {
  padding-left: 0;
}
.MuiFilledInput-root.number-input .MuiInputAdornment-root .MuiTypography-root {
  font-size: 0.9em;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
}

.MuiFilledInput-root.Mui-error {
  outline: 3px solid red;
}

@media screen and (max-width: 900px) {
  .MuiTextField-root {
    width: 100%;
  }
}
.content-header-container {
  width: 100%;
  position: relative;
  height: 600px;
}
.content-header-container .content-header-image {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 900px) {
  .content-header-container {
    height: 250px;
  }
}
.content-box-container {
  width: 90%;
  max-width: 400px;
  text-align: center;
  margin: 40px auto;
  background-color: #ebebe3;
}
.content-box-container .content-box-content {
  padding: 20px;
}
.content-box-container .content-box-content h3 {
  font-weight: 500;
  font-size: 1.5em;
  margin: 5px;
}
.content-box-container .content-box-content p {
  margin: 5px;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
.placeholder {
  display: flex;
  justify-content: center;
}
.placeholder .loading-image {
  height: 160px;
  width: 120px;
  background-color: #dddddd;
  border-radius: 4px;
  max-width: 10vw;
}
.placeholder .loading-text {
  background-color: #dddddd;
  border-radius: 4px;
  height: 20px;
  width: 200px;
  max-width: 10vw;
}
.placeholder .loading-text.short {
  width: 100px;
  max-width: 5vw;
}
.placeholder .loading-text.long {
  width: 325px;
  max-width: 17vw;
}
.placeholder .loading-text.extra-long {
  width: 450px;
  max-width: 30vw;
}
.placeholder .loading-button {
  background-color: #dddddd;
  border-radius: 4px;
  height: 70px;
  width: 200px;
  max-width: 13vw;
}
.placeholder .loading-button.actions {
  width: 70px;
}
.placeholder.shimmer {
  overflow: hidden;
  position: relative;
}
.placeholder.shimmer::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  animation: shimmer 1s infinite;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0) 100%);
}

.floating-button {
  position: fixed;
  right: 0;
  bottom: 5px;
  margin-right: 5px;
  cursor: pointer;
}

svg.whatsapp-icon {
  height: 3.5em;
  width: 3.5em;
}
svg.whatsapp-icon .st0 {
  fill: #FFFFFF;
}
svg.whatsapp-icon .st1 {
  fill: #38AF4A;
}

p.page-paragraph, li.page-paragraph {
  font-size: 1.2em;
}

li.page-paragraph span {
  display: block;
  margin: 1em 0;
}

.MuiSnackbar-root .MuiPaper-root {
  justify-content: center;
  font-family: "Stara";
  font-weight: 500;
}
.MuiSnackbar-root.info .MuiPaper-root {
  color: #202020;
  background-color: #ffd100;
}
.MuiSnackbar-root.success .MuiPaper-root {
  color: white;
  background-color: #47b44d;
}

.clickable-span {
  cursor: pointer;
}

.assistant-container {
  background-color: #ffcb05;
  padding: 30px 50px;
}
.assistant-container h2 {
  margin: 10px;
  text-transform: uppercase;
}
.assistant-container h2.step-text {
  font-weight: 500;
}
.assistant-container button {
  width: 100%;
  padding: 0;
  color: #202020;
  font-family: "Manrope";
}
.assistant-container .assistant-grid-container {
  margin-bottom: 45px;
}

.assistant-grid-item {
  padding: 15px 30px;
  background-color: white;
  display: flex;
  align-items: center;
  outline: 1px solid black;
  justify-content: center;
  cursor: pointer;
  transition: all 0.5s;
  text-align: center;
  width: 100%;
}
.assistant-grid-item:active {
  background-color: #ffcb05;
  transition: all 0.5s;
}
.assistant-grid-item p {
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.assistant-main-button-container {
  margin: 40px 0;
}

.step-info-box-container {
  padding: 5px 20px;
  outline: 1px solid black;
  margin: 20px 0;
}
.step-info-box-container .page-paragraph {
  display: flex;
  gap: 10px;
}
.step-info-box-container.old-step {
  background-color: white;
  cursor: pointer;
}

h1.content-header-ferramenta-busca {
  position: absolute;
  top: 20%;
  width: 20px;
  font-weight: 600;
  color: white;
  font-size: 3.5em;
  left: 60%;
}

.ferramenta-busca-header-image {
  -o-object-position: 0% 0;
     object-position: 0% 0;
}

@media screen and (max-width: 900px) {
  h1.content-header-ferramenta-busca {
    right: 40%;
    font-size: 1.5em;
  }
}
@media screen and (max-width: 600px) {
  h1.content-header-ferramenta-busca {
    top: 25%;
    right: 150px;
    font-size: 1.2em;
  }
}
@media screen and (max-width: 600px) {
  .parametros-container .pimaco-download-box-container, .gabaritos-container .pimaco-download-box-container {
    width: 39%;
  }
}
@media screen and (max-width: 300px) {
  .parametros-container .pimaco-download-box-container, .gabaritos-container .pimaco-download-box-container {
    width: 100%;
  }
}
.gabaritos-container .pimaco-download-box-container {
  height: 450px;
  width: 300px;
  position: relative;
}
.gabaritos-container .pimaco-download-box-container svg {
  padding: 20px 15px;
}
.gabaritos-container .pimaco-download-box-info-container {
  height: 9em;
}
.gabaritos-container .pimaco-download-box-info-container .pimaco-download-box-text-container {
  height: 8em;
  justify-content: flex-end;
}
.gabaritos-container .pimaco-download-box-info-container .pimaco-download-box-text-container p.pimaco-download-box-titulo, .gabaritos-container .pimaco-download-box-info-container .pimaco-download-box-text-container p.pimaco-download-box-code {
  height: 4em;
  padding-bottom: 5px;
}
.gabaritos-container .pimaco-download-box-info-container .pimaco-download-box-text-container p.pimaco-download-box-code {
  font-weight: 600;
  font-size: 1.1em;
}

img.gabaritos-downloads-item-image {
  -o-object-fit: cover;
     object-fit: cover;
  width: -webkit-fill-available;
  height: 100%;
}

.parametros-container .pimaco-download-box-container {
  height: 280px;
  position: relative;
  padding: 15px;
}
.parametros-container .pimaco-download-box-info-container {
  position: absolute;
  height: 40%;
  bottom: 0;
}

.content-header-parametros {
  position: absolute;
  left: 65%;
  top: 20%;
  max-width: 300px;
}
.content-header-parametros h1 {
  font-size: 2.5em;
  font-weight: 600;
  margin: 10px 0;
}

@media screen and (max-width: 900px) {
  .content-header-parametros {
    top: 10%;
  }
  .content-header-parametros h1 {
    font-size: 2em;
  }
}
@media screen and (max-width: 600px) {
  .content-header-parametros {
    left: 45%;
    top: 20%;
    max-width: 200px;
  }
  .content-header-parametros h1 {
    font-size: 1.5em;
  }
  .content-header-parametros p {
    font-size: 0.8em;
  }
  .content-header-image-parametros {
    -o-object-position: 90%;
       object-position: 90%;
  }
}
div.downloads-paragraph {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 50px;
}

.pimaco-download-box-container {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px auto;
  justify-content: space-evenly;
}

div.downloads-group {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  transition: all 0.25s;
}
div.downloads-group .pimaco-download-box-container {
  background-color: #ededed;
  transition: all 0.25s;
}
div.downloads-group > :nth-child(odd).pimaco-download-box-container {
  background-color: #ebebe3;
  transition: all 0.25s;
}
div.downloads-group .pimaco-download-box-container:hover {
  background-color: #ffcb05;
  color: white;
  transition: background-color 0.25s;
}
div.downloads-group .pimaco-download-box-container:hover svg {
  fill: white;
  transition: all 0.25s;
}
div.downloads-group .pimaco-download-box-container:hover .pimaco-download-box-info-container {
  background-color: goldenrod;
  transition: all 0.25s;
}
div.downloads-group .pimaco-download-box-container:hover .btn {
  border-color: white;
  color: white;
}

.pimaco-download-box-info-container {
  background-color: lightgray;
  width: 100%;
  display: flex;
  align-items: flex-end;
}

div.pimaco-download-box-text-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
}

p.pimaco-download-box-titulo, p.pimaco-download-box-code {
  text-align: center;
  margin: 0;
  padding-bottom: 15px;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.saiba-mais-downloads-container {
  background-color: #ffcb05;
  text-align: center;
  padding: 15px;
}

.assistente-impressao-page-content {
  text-align: center;
  padding: 50px 0;
}
.assistente-impressao-page-content .destaque-header-title {
  font-weight: 800;
  text-transform: uppercase;
  color: #ffcb05;
}

.assistente-impressao-page-content-button-tutorial {
  display: flex;
  margin: 40px 0;
  align-items: center;
  justify-content: center;
}

.assistente-impressao-page-content-new-assistant-container {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.assistente-impressao-page-content-new-assistant-container .assistente-impressao-page-content-image {
  width: 50%;
  height: 100%;
}
.assistente-impressao-page-content-new-assistant-container .assistente-impressao-page-content-image img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.assistente-impressao-page-content-new-assistant-container .assistente-impressao-page-content-text {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 40px;
  box-sizing: border-box;
  width: 50%;
}
.assistente-impressao-page-content-new-assistant-container .assistente-impressao-page-content-text .MuiGrid-item {
  display: flex;
  align-items: center;
}
.assistente-impressao-page-content-new-assistant-container .assistente-impressao-page-content-text .destaques-assistente-impressao {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.assistente-impressao-page-content-new-assistant-container .assistente-impressao-page-content-text .destaques-assistente-impressao .icon {
  color: #ffcb05;
}
.assistente-impressao-page-content-new-assistant-container .assistente-impressao-page-content-text .destaques-assistente-impressao span {
  font-size: 1.7em;
  text-align: left;
}
.assistente-impressao-page-content-new-assistant-container .assistente-impressao-page-content-text .accesse-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.assistente-impressao-page-content-new-assistant-container .assistente-impressao-page-content-text .accesse-container .text-center {
  text-align: center;
  margin: 0 auto;
}
.assistente-impressao-page-content-new-assistant-container .assistente-impressao-page-content-text .accesse-container .button-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.assistente-impressao-page-content-new-assistant-container .assistente-impressao-page-content-text .extra-info {
  color: #202020;
  font-size: 0.9em;
}

.assistente-impressao-checkbox-container {
  text-align: right;
}
.assistente-impressao-checkbox-container .MuiSvgIcon-root {
  border: 1px solid black;
}

@media screen and (max-width: 600px) {
  div.downloads-group {
    justify-content: space-between;
  }
}
@media screen and (max-width: 900px) {
  .assistente-impressao-page-content-new-assistant-container {
    flex-direction: column;
  }
  .assistente-impressao-page-content-new-assistant-container .assistente-impressao-page-content-image {
    height: 400px;
    width: 100%;
    -o-object-position: top;
       object-position: top;
  }
  .assistente-impressao-page-content-new-assistant-container .assistente-impressao-page-content-text {
    width: 100%;
  }
  .assistente-impressao-page-content-new-assistant-container .assistente-impressao-page-content-text .text-center {
    display: none;
  }
  .assistente-impressao-page-content-new-assistant-container .assistente-impressao-page-content-text .button-container {
    display: none;
  }
  .assistente-impressao-page-content-new-assistant-container .assistente-impressao-page-content-text .extra-info {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 1200px) {
  .assistente-impressao-buttons-container {
    display: flex;
    max-width: 1400px;
    width: 90%;
    margin: 20px auto;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .pimaco-download-box-container {
    margin: 10px;
  }
  .assistente-impressao-buttons-container {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -100%);
    display: flex;
    justify-content: space-evenly;
    gap: 20px;
    flex-direction: column;
    top: 50%;
    width: 90%;
    max-width: 1400px;
    margin: 0 auto;
    align-items: flex-end;
  }
  .assistente-impressao-buttons-container button {
    width: 300px;
  }
}
.timeline-container {
  padding: 30px 50px;
  background-color: #ffcb05;
}
.timeline-container .horizontal.timeline-main-wrapper {
  padding: 0;
}
.timeline-container .horizontal.timeline-main-wrapper > div {
  width: 100%;
}
.timeline-container .horizontal.timeline-main-wrapper .timeline-horizontal-container {
  padding-left: 0;
  justify-content: center;
}
.timeline-container .horizontal.timeline-main-wrapper .timeline-horizontal-container .timeline-item-title {
  background: transparent;
}
.timeline-container .horizontal.timeline-main-wrapper .timeline-horizontal-container .timeline-circle.active:after {
  display: none;
}
.timeline-container .horizontal.timeline-main-wrapper .timeline-horizontal-container .timeline-circle.active {
  height: 35px;
}
.timeline-container .horizontal.timeline-main-wrapper .timeline-horizontal-container .timeline-circle {
  width: 3px;
  border-radius: 1.5px;
  height: 25px;
}

.timeline-slider-container {
  background-color: #ffcb05;
  padding: 60px 0;
}

.timeline-item-container {
  background-color: rgba(0, 0, 0, 0.15);
  width: 90%;
  margin: 0 auto;
  display: flex;
  height: 375px;
  max-width: 1400px;
}
.timeline-item-container .timeline-item-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
}
.timeline-item-container .timeline-item-image-container img {
  max-width: -webkit-fill-available;
}
.timeline-item-container .timeline-item-text-container {
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.timeline-item-container .timeline-item-text-container h1 {
  margin: 0;
  font-weight: 500;
  font-size: 2.2em;
}

@media screen and (max-width: 900px) {
  .timeline-item-container {
    height: 400px;
  }
  .timeline-item-container .timeline-item-text-container {
    padding: 15px;
  }
  .button-slider-container {
    justify-content: center;
  }
}
@media (hover: none) and (pointer: coarse) and (max-width: 600px) {
  .timeline-item-container .timeline-item-text-container {
    overflow-y: scroll;
  }
}
@media screen and (max-width: 600px) {
  .timeline-item-container {
    height: 70vh;
    flex-direction: column;
  }
  .timeline-item-container .timeline-item-image-container {
    width: 100%;
  }
  .timeline-item-container .timeline-item-text-container {
    padding-top: 0;
    margin-top: 15px;
    justify-content: flex-start;
  }
}
.politica-privacidade-image {
  -o-object-position: 15%;
     object-position: 15%;
}

.content-header-politica-privacidade {
  position: absolute;
  top: 30%;
  left: 20%;
  font-size: 3.5em;
  color: white;
  font-weight: 500;
  width: 20%;
}

.quem-somos-image {
  -o-object-position: 20%;
     object-position: 20%;
}

.content-header-quem-somos-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  max-width: 1400px;
  transform: translateX(-50%);
}
.content-header-quem-somos-container h1.content-header-quem-somos {
  color: white;
  width: 40%;
  max-width: 1400px;
  font-weight: bold;
  font-size: 3.5em;
}

@media screen and (max-width: 1200px) {
  .content-header-politica-privacidade {
    top: 20%;
    left: 10%;
    font-size: 2em;
    color: white;
    font-weight: 500;
    width: 20%;
  }
}
.page-paragraph table,
.page-paragraph td {
  border: 1px solid black;
  border-collapse: collapse;
}

td {
  padding: 10px 5px;
}

.termo-td-first-width {
  width: 330px;
}

.termo-td-second-width {
  width: 930px;
}

.MuiAccordionSummary-content {
  margin: 24px 12px;
}
.MuiAccordionSummary-content.Mui-expanded {
  margin: 24px 12px;
}

.accordion-faq-duvidas {
  margin: 15px 0;
  background-color: #ebebe3;
  font-family: "Manrope";
  font-weight: 500;
}
.accordion-faq-duvidas h2 {
  margin: 0;
}

.accordion-faq-duvidas:before {
  background-color: transparent;
}

.accordion-faq-duvidas-details {
  background-color: white;
  font-family: "Manrope";
  font-weight: 500;
  padding: 20px 28px;
  white-space: pre-wrap;
  line-height: 150%;
}

.MuiPaper-root-MuiAccordion-root:first-of-type {
  border-radius: 0 !important;
}

.search-field-faq {
  display: flex;
  margin: 30px auto;
  width: 70%;
}
.search-field-faq .pimaco-icons {
  height: 2em;
  width: 2em;
}
.search-field-faq .MuiFilledInput-root {
  align-items: center;
}

.faq-category-chooser-container {
  background-color: #ffcb05;
  width: 90%;
  margin: 0 auto;
  max-width: 1400px;
}
.faq-category-chooser-container .grid-faq-category-chooser {
  padding: 40px;
}

.faq-category-chooser-item-container {
  background-color: white;
  padding: 5px 15px;
  margin: 10px;
  text-align: center;
  height: 8em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  outline: 2px solid black;
  cursor: pointer;
  transition: all 0.5s;
}
.faq-category-chooser-item-container h4 {
  text-transform: uppercase;
}
.faq-category-chooser-item-container.active-faq-category-chooser-item {
  background-color: transparent;
  transition: all 0.5s;
}

h1.content-header-faq {
  position: absolute;
  top: 25%;
  left: 20%;
  font-size: 3.5em;
  width: 25%;
  font-weight: 600;
}

@media screen and (max-width: 900px) {
  h1.content-header-faq {
    top: 0;
    left: 5%;
    width: 20%;
    font-size: 2em;
  }
}
@media screen and (max-width: 600px) {
  .faq-category-chooser-container {
    padding: 40px 0;
    width: 100%;
  }
  .faq-category-chooser-container p {
    display: none;
  }
}
.form-contato-options {
  display: inline-block;
}

.form-contato-options_paragraph {
  display: inline-block;
  margin-right: 15px;
}

.MuiFilledInput-root {
  padding: 0px;
  padding-left: 10px;
}

.fale-conosco-mensagem-adornment {
  align-items: flex-start;
  padding: 0px;
}

.dropzone-curriculo {
  text-align: center;
  background-color: white;
}
.dropzone-curriculo .pimaco-icons.upload-icon svg {
  height: 120px;
}
.dropzone-curriculo .pimaco-icons.upload-icon svg .st0 {
  fill: none;
  stroke: #020203;
  stroke-miterlimit: 10;
}
.dropzone-curriculo .pimaco-icons.upload-icon svg .st1 {
  fill: none;
  stroke: #231F20;
  stroke-miterlimit: 10;
}

.trabalhe-conosco-p-radioboxes {
  display: inline-block;
  margin-right: 1em;
  vertical-align: middle;
}

.trabalhe-conosco-radioGroup {
  display: inline-block;
}

.form-contato-submit-button {
  margin-top: 25px;
}

.trabalhe-conosco-h1-curriculo {
  margin-left: 16px;
  margin-bottom: 0px;
}

.forms-accept-buttons {
  margin-left: 16px;
  margin-top: 15px;
}

.dropzone-icon {
  font-size: 45px;
}

.MuiFormControlLabel-root, .MuiTypography-root {
  font-family: "Manrope";
}

.form-container {
  background-color: #ffcb05;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}
.form-container form {
  padding: 40px;
}

@media screen and (max-width: 900px) {
  .form-container form {
    padding: 0;
    width: 90%;
    margin: 0 auto;
  }
}
.contato-button-switcher-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.contato-button-switcher-container .btn {
  margin: 0 20px;
}

.tela-agradecimento-container {
  text-align: center;
}
.tela-agradecimento-container h1 {
  font-weight: 600;
}

@media screen and (max-width: 600px) {
  .contato-button-switcher-container {
    flex-direction: column;
    width: 90%;
    margin: 20px auto;
  }
  .contato-button-switcher-container .btn {
    margin: 10px 0;
  }
}
div.dropzone-curriculo {
  cursor: pointer;
  outline: 1px solid black;
  padding: 30px 20px;
  margin: 25px 0;
}
div.dropzone-curriculo.dropzone-curriculo-erro {
  outline: 3px solid red;
}

h1.content-header-trabalhe-conosco {
  position: absolute;
  top: 30%;
  left: 15%;
  font-size: 3.5em;
  color: white;
  font-weight: bold;
}

h1.content-header-fale-conosco {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, 50%);
  font-size: 2.5em;
  font-weight: 600;
}

.h1-form-title, .h3-form-subtitle {
  font-weight: 600;
}

p.form-contato-initial-paragraph {
  font-size: 1.1em;
}

@media screen and (max-width: 600px) {
  h1.content-header-trabalhe-conosco {
    top: 35%;
    left: 5%;
    font-size: 2em;
  }
  p.form-contato-initial-paragraph {
    text-align: center;
  }
  h1.content-header-fale-conosco {
    top: 20%;
    transform: translate(-50%, 50%);
    font-size: 2em;
  }
}
.grid-titulos {
  display: flex;
}

.escolaridade-titulo {
  display: flex;
}

.formulario-main-text {
  display: flex;
}

.idiomas-titulo {
  display: flex;
}

.idioma-opcoes {
  display: flex;
}

span.accordion-menu.accordion-menu-selected {
  display: flex;
}

span.accordion-menu {
  display: none;
}

span.accordion-menu.accordion-filtros-selected {
  display: flex;
  flex-direction: column;
}

.navbar-mobile-search-container {
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 15px;
  height: 62px;
  width: 90%;
}
.navbar-mobile-search-container .navbar-mobile-search-textfield {
  width: 100%;
  height: 100%;
  margin: 0 15px;
  justify-content: center;
  margin-left: 0;
}
.navbar-mobile-search-container .btn {
  height: 100%;
  margin: 0 15px;
  margin-right: 0;
  min-width: 200px;
  padding: 10px 30px;
}

@media screen and (max-width: 600px) {
  .navbar-mobile-search-container .btn {
    min-width: 0;
  }
}
footer .navbar-mobile-search-container {
  display: none;
}

.accordion-menu {
  width: 90%;
  display: flex;
  margin: 0 auto;
  margin-bottom: 15px;
}
.accordion-menu .accordion {
  width: 100%;
  border: none;
}
.accordion-menu a {
  text-decoration: none;
}
.accordion-menu a:link, .accordion-menu a:visited, .accordion-menu a:hover, .accordion-menu a:active {
  color: #202020;
}
.accordion-menu .menu-produtos-category-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.accordion-menu .accordion__heading {
  padding: 15px 10px;
}
.accordion-menu .accordion__heading .accordion__button {
  padding: 0;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.accordion-menu .accordion__heading .accordion__button svg {
  height: 1.2em;
  width: 2em;
}
.accordion-menu .accordion__heading .accordion__button:hover {
  background-color: transparent;
}
.accordion-menu .accordion__heading .accordion__button:before {
  display: none;
}
.accordion-menu .accordion__heading .accordion__button[aria-expanded=true] .pimaco-icons,
.accordion-menu .accordion__heading .accordion__button[aria-selected=true] .pimaco-icons {
  transform: rotate(180deg);
}
.accordion-menu .menu-accordion-produtos .accordion__heading {
  padding: 15px 30px;
}
.accordion-menu .accordion__panel {
  padding: 0;
}
.accordion-menu .accordion__panel .accordion__panel-filtros {
  padding: 0 0 10px;
}
.accordion-menu .accordion__panel .accordion__panel-filtros ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}
.accordion-menu .accordion__panel .accordion__panel-filtros ul .menu-produtos-subcategoria-item {
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  padding-left: 50px;
  text-transform: uppercase;
}
.accordion-menu .accordion__item + .accordion__item {
  border-top: 0.5px solid rgba 0, 0, 0, 0.7;
}
.accordion-menu .menu-accordion-produtos .accordion__item + .accordion__item {
  border-top: none;
}
.accordion-menu ul.ul-navbar-mobile-itens {
  list-style: none;
  padding: 0 30px;
  margin: 0;
}
.accordion-menu ul.ul-navbar-mobile-itens li {
  padding: 15px 0;
}
.accordion-menu .accordion-menu-btn-container {
  margin: 15px 0;
}

header .accordion-menu .accordion__heading .accordion__button {
  color: #202020;
}
header .accordion-menu .accordion__panel {
  background-color: #ededef;
}
header .accordion-menu .accordion__panel .accordion__panel-filtros ul .menu-produtos-subcategoria-item {
  background-color: #f7f7f7;
}
header .accordion-menu.accordion-filtros-selected .accordion__panel {
  background-color: #f7f7f7;
}

footer .accordion-menu .accordion__heading .accordion__button {
  color: #202020;
}
footer .accordion-menu .accordion__panel {
  background-color: #d5d6d2;
}
footer .accordion-menu .accordion__panel .accordion__panel-filtros ul .menu-produtos-subcategoria-item {
  background-color: #f7f7f7;
}
footer .accordion-menu.accordion-filtros-selected .accordion__panel {
  background-color: #f7f7f7;
}

.header .navbar-produtos-container {
  position: absolute;
  background-color: white;
  display: flex;
  flex-direction: row;
  width: 100%;
  z-index: 2;
}
.header .navbar-produtos-container a {
  text-decoration: none;
}
.header .navbar-produtos-container a:link, .header .navbar-produtos-container a:visited, .header .navbar-produtos-container a:hover, .header .navbar-produtos-container a:active {
  color: black;
}
.header .navbar-produtos-container .navbar-produtos-filtros-header {
  padding-bottom: 5px;
  width: 60%;
  border-bottom: 1px solid black;
  text-transform: uppercase;
  font-weight: 600;
}
.header .navbar-produtos-container .navbar-produtos-categorias-container {
  background-color: #ebebe3;
  width: 25%;
  padding-left: 100px;
  padding-top: 30px;
}
.header .navbar-produtos-container .navbar-produtos-categorias-container ul.navbar-produtos-categorias-list {
  padding-left: 0px;
  padding-right: 0;
  list-style-type: none;
}
.header .navbar-produtos-container .navbar-produtos-categorias-container ul.navbar-produtos-categorias-list li.navbar-produtos-categorias-list-item {
  padding: 13px;
  padding-left: 0;
  background-color: transparent;
  cursor: pointer;
  display: flex;
}
.header .navbar-produtos-container .navbar-produtos-categorias-container ul.navbar-produtos-categorias-list li.navbar-produtos-categorias-list-item a {
  width: 100%;
}
.header .navbar-produtos-container .navbar-produtos-categorias-container ul.navbar-produtos-categorias-list li.navbar-produtos-categorias-list-item:hover, .header .navbar-produtos-container .navbar-produtos-categorias-container ul.navbar-produtos-categorias-list li.navbar-produtos-categorias-list-item.active-item {
  background-color: white;
}
.header .navbar-produtos-container .navbar-produtos-categorias-container ul.navbar-produtos-categorias-list li.navbar-produtos-categorias-list-item.selected-item {
  background-color: white;
  font-weight: 700;
}
.header .navbar-produtos-container .navbar-produtos-categorias-filtros-container {
  width: 75%;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 40px;
  padding-top: 30px;
  padding-right: 5%;
  display: flex;
  justify-content: flex-start;
}
.header .navbar-produtos-container .navbar-produtos-categorias-filtros-container .navbar-produtos-filtros-list-container {
  width: 25%;
}
.header .navbar-produtos-container .navbar-produtos-categorias-filtros-container .navbar-produtos-filtros-list-container ul.navbar-produtos-filtros-list {
  list-style-type: none;
  padding-left: 0;
  width: 95%;
}
.header .navbar-produtos-container .navbar-produtos-categorias-filtros-container .navbar-produtos-filtros-list-container ul.navbar-produtos-filtros-list li.navbar-produtos-filtros-list-item {
  padding: 4px 0;
}

.header {
  width: 100%;
  background-color: white;
}
.header .header-top-half-container {
  max-width: 1400px;
  margin: 0 auto;
  width: 90%;
}
.header .header-top-half-container .logo-row-container {
  display: flex;
  justify-content: center;
  position: relative;
}
.header .header-top-half-container .logo-row-container .logo {
  height: 100px;
  padding: 5px;
}
.header .header-top-half-container .logo-row-container .search-language-switcher-container {
  position: absolute;
  right: 0;
  padding: 20px 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.header .header-top-half-container .logo-row-container .search-language-switcher-container .language-switcher-text {
  text-transform: uppercase;
  cursor: pointer;
}
.header .header-top-half-container .logo-row-container .search-language-switcher-container .language-switcher-text.active {
  font-weight: 600;
}
.header .header-top-half-container .logo-row-container .search-language-switcher-container .hidden {
  display: none;
  cursor: default;
}
.header .header-top-half-container .logo-row-container .search-language-switcher-container svg {
  padding: 0 5px;
  height: 1em;
  width: 1em;
}
.header .header-top-half-container .logo-row-container .search-language-switcher-container .search-text-field {
  width: 0;
  visibility: hidden;
  transition: all 0.5s;
}
.header .header-top-half-container .logo-row-container .search-language-switcher-container .search-text-field .MuiFilledInput-input {
  padding-top: 0;
  padding-bottom: 10px;
}
.header .header-top-half-container .logo-row-container .search-language-switcher-container .search-text-field.search-text-field-active {
  width: 300px;
  visibility: visible;
  transition: all 0.5s;
}
.header .navbar-mobile-accordion-container .search-language-switcher-container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 20px;
}
.header .navbar-mobile-accordion-container .search-language-switcher-container .language-switcher-text {
  text-transform: uppercase;
  cursor: pointer;
}
.header .navbar-mobile-accordion-container .search-language-switcher-container .language-switcher-text.active {
  font-weight: 600;
}
.header .navbar-mobile-accordion-container .search-language-switcher-container .hidden {
  display: none;
  cursor: default;
}
.header .navbar-mobile-accordion-container .search-language-switcher-container svg {
  padding: 0 5px;
  height: 1em;
  width: 1em;
}
.header .navbar-dropdown-menu {
  display: none;
  position: absolute;
  background-color: white;
  top: 100%;
  width: auto;
  z-index: 1;
  font-weight: 500;
}
.header .navbar-dropdown-menu ul {
  display: flex;
  flex-direction: column !important;
  padding-left: 0;
  width: initial !important;
  padding-right: 15px;
  white-space: nowrap;
  align-items: flex-start !important;
}
.header .navbar-dropdown-menu ul li {
  padding-left: 15px !important;
  width: 100%;
}
.header .navbar-dropdown-menu ul li:hover {
  background-color: #ebebe3;
}
.header .navbar-dropdown-menu.navbar-dropdown-menu-active {
  display: initial;
}

@media screen and (min-width: 1200px) {
  .header {
    position: sticky;
    top: 0;
    z-index: 10;
  }
  .header .header-top-half-container .logo-row-container .logo {
    transition: 0.5s;
  }
  .header .header-top-half-container .logo-row-container .logo.small-logo {
    height: 50px;
    transition: 0.5s;
  }
}
@media screen and (max-width: 1200px) {
  .header .header-top-half-container .logo-row-container {
    justify-content: space-between;
  }
  .header .header-top-half-container .logo-row-container .logo {
    height: 60px;
  }
  .header .header-top-half-container .logo-row-container .menu-icon-container {
    display: flex;
    align-items: center;
  }
  .header .header-top-half-container .logo-row-container .menu-icon-container svg {
    background-color: #ebebe3;
    height: 1.7em;
    width: 1.7em;
    padding: 10px;
    border-radius: 15px;
    cursor: pointer;
  }
}
.navbar-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffcb05;
}
.navbar-container .navbar {
  max-width: 1400px;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar-container .navbar ul {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  align-items: center;
  padding-left: 0;
}
.navbar-container .navbar ul li {
  display: flex;
  cursor: pointer;
  text-transform: uppercase;
  position: relative;
  padding: 15px 0;
  font-size: 0.95em;
}
.navbar-container .navbar ul li a {
  text-decoration: none;
}
.navbar-container .navbar ul li a:link, .navbar-container .navbar ul li a:visited, .navbar-container .navbar ul li a:hover, .navbar-container .navbar ul li a:active {
  color: black;
}
.navbar-container .navbar ul li .search-icon-navbar {
  cursor: pointer;
}
.navbar-container .navbar ul li .pimaco-icons {
  height: 1em;
  width: 1em;
}
.navbar-container .navbar ul > li.active-route {
  font-weight: 600;
}
.navbar-container .search-bar-container {
  width: 90%;
  height: 0;
  transition: all 0.5s;
  display: flex;
  align-items: center;
  max-width: 1400px;
}
.navbar-container .search-bar-container .search-bar-form {
  flex-grow: 1;
}
.navbar-container .search-bar-container .pimaco-icons {
  height: 2em;
  width: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.navbar-container .search-bar-container form .MuiFilledInput-root {
  align-items: center;
}
.navbar-container .search-bar-container form .pimaco-icons {
  width: 2em;
  height: 2em;
}
.navbar-container .search-bar-container > * {
  visibility: hidden;
}
.navbar-container .search-bar-container.show {
  padding: 20px 0;
  height: 60px;
  transition: all 0.5s;
}
.navbar-container .search-bar-container.show > * {
  visibility: visible;
  transition: visibility 0.5s;
  transition-delay: 0.25s;
  margin: 0 10px;
}
.navbar-container .search-bar-container.show form {
  width: 100%;
}
.navbar-container .search-bar-container.show .btn {
  height: 100%;
}

footer {
  background-color: #c2c3bd;
}
footer a, footer a:link, footer a:visited, footer a:hover, footer a:active {
  color: black;
}
footer .footer-navbar-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 15px;
  max-width: 1600px;
  margin: 0 auto;
}
footer .MuiSvgIcon-root {
  transition: transform 0.5s;
  cursor: pointer;
}
footer .MuiSvgIcon-root.expanded {
  transform: rotate(180deg);
  transition: transform 0.5s;
}
footer .navbar-footer {
  max-width: 1400px;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 15px;
}
footer .navbar-footer .product-li {
  width: 16.6%;
}
footer .navbar-footer > ul {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 0 15px;
  align-items: flex-start;
  padding-left: 0;
  font-weight: 500;
}
footer .navbar-footer > ul > li {
  display: flex;
  cursor: pointer;
}
footer .navbar-footer > ul > li .list-item-item {
  display: flex;
  flex-direction: column;
}
footer .navbar-footer > ul > li .list-item-item .footer-navbar-ul {
  list-style-type: none;
  padding-left: 0;
  color: transparent;
  max-height: 0;
  transition: all 0.5s;
}
footer .navbar-footer > ul > li .list-item-item .footer-navbar-ul li {
  height: 0;
  padding: 0;
  font-weight: 500;
}
footer .navbar-footer > ul > li .list-item-item .footer-navbar-ul li a, footer .navbar-footer > ul > li .list-item-item .footer-navbar-ul li a:link, footer .navbar-footer > ul > li .list-item-item .footer-navbar-ul li a:visited, footer .navbar-footer > ul > li .list-item-item .footer-navbar-ul li a:hover, footer .navbar-footer > ul > li .list-item-item .footer-navbar-ul li a:active {
  color: transparent;
  text-decoration: none;
  height: 0;
  padding: 0;
  font-weight: 500;
  transition: all 0.5s;
}
footer .navbar-footer > ul > li .list-item-item .footer-navbar-ul.show {
  max-height: 500px;
  color: black;
  margin-top: 20px;
}
footer .navbar-footer > ul > li .list-item-item .footer-navbar-ul.show li {
  height: auto;
  padding: 5px 0;
}
footer .navbar-footer > ul > li .list-item-item .footer-navbar-ul.show li a, footer .navbar-footer > ul > li .list-item-item .footer-navbar-ul.show li a:link, footer .navbar-footer > ul > li .list-item-item .footer-navbar-ul.show li a:visited, footer .navbar-footer > ul > li .list-item-item .footer-navbar-ul.show li a:hover, footer .navbar-footer > ul > li .list-item-item .footer-navbar-ul.show li a:active {
  color: black;
  height: 0;
  padding: 0;
  font-weight: 500;
  transition: all 0.5s;
}
footer .search-language-switcher-container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 20px;
}
footer .search-language-switcher-container .language-switcher-text {
  text-transform: uppercase;
  cursor: pointer;
}
footer .search-language-switcher-container .language-switcher-text.active {
  font-weight: 600;
}
footer .search-language-switcher-container .hidden {
  display: none;
  cursor: default;
}
footer .search-language-switcher-container svg {
  padding: 0 5px;
  height: 1em;
  width: 1em;
}
footer .footer-social-icons-container {
  display: flex;
}
footer .footer-social-icons-container .footer-social-logo {
  height: 20px;
  padding: 0 5px;
}

.footer-credits-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.footer-credits-container p {
  font-size: 0.9em;
}

@media screen and (max-width: 1200px) {
  footer .footer-navbar-container {
    justify-content: space-between;
    align-items: center;
    width: 90%;
  }
  footer .footer-navbar-container .logo {
    height: 60px;
  }
}
.carousel-landing-slider-container {
  position: relative;
}
.carousel-landing-slider-container .carousel-landing-slider {
  height: 80vh;
}
.carousel-landing-slider-container .carousel-landing-slider.carousel-categorias-slider {
  height: 50vh;
}
.carousel-landing-slider-container .carousel-landing-slider .carousel-landing-slide-item-container, .carousel-landing-slider-container .carousel-landing-slider .categoria-header-base-container, .carousel-landing-slider-container .carousel-landing-slider .categoria-header-base-image-container {
  -o-object-fit: cover;
     object-fit: cover;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.carousel-landing-slider-container .carousel-landing-slider .carousel-landing-slide-item-container img, .carousel-landing-slider-container .carousel-landing-slider .categoria-header-base-container img, .carousel-landing-slider-container .carousel-landing-slider .categoria-header-base-image-container img {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  width: 100%;
}
.carousel-landing-slider-container .carousel-landing-slider .carousel-landing-slide-item-container img.right, .carousel-landing-slider-container .carousel-landing-slider .categoria-header-base-container img.right, .carousel-landing-slider-container .carousel-landing-slider .categoria-header-base-image-container img.right {
  -o-object-position: right;
     object-position: right;
}
.carousel-landing-slider-container .carousel-landing-slider .carousel-landing-slide-item-container img.right.bottom, .carousel-landing-slider-container .carousel-landing-slider .categoria-header-base-container img.right.bottom, .carousel-landing-slider-container .carousel-landing-slider .categoria-header-base-image-container img.right.bottom {
  -o-object-position: bottom right;
     object-position: bottom right;
}
.carousel-landing-slider-container .carousel-landing-slider .carousel-landing-slide-item-container img.right.top, .carousel-landing-slider-container .carousel-landing-slider .categoria-header-base-container img.right.top, .carousel-landing-slider-container .carousel-landing-slider .categoria-header-base-image-container img.right.top {
  -o-object-position: top right;
     object-position: top right;
}
.carousel-landing-slider-container .carousel-landing-slider .carousel-landing-slide-item-container img.left, .carousel-landing-slider-container .carousel-landing-slider .categoria-header-base-container img.left, .carousel-landing-slider-container .carousel-landing-slider .categoria-header-base-image-container img.left {
  -o-object-position: left;
     object-position: left;
}
.carousel-landing-slider-container .carousel-landing-slider .carousel-landing-slide-item-container img.left.top, .carousel-landing-slider-container .carousel-landing-slider .categoria-header-base-container img.left.top, .carousel-landing-slider-container .carousel-landing-slider .categoria-header-base-image-container img.left.top {
  -o-object-position: top left;
     object-position: top left;
}
.carousel-landing-slider-container .carousel-landing-slider .carousel-landing-slide-item-container img.center, .carousel-landing-slider-container .carousel-landing-slider .categoria-header-base-container img.center, .carousel-landing-slider-container .carousel-landing-slider .categoria-header-base-image-container img.center {
  -o-object-position: center;
     object-position: center;
}
.carousel-landing-slider-container .carousel-landing-slider .carousel-landing-slide-item-container {
  height: 80vh;
}
.carousel-landing-slider-container .carousel-landing-slider .categoria-header-base-container {
  position: relative;
}
.carousel-landing-slider-container .carousel-landing-slider .categoria-header-base-text-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
}
.carousel-landing-slider-container .carousel-landing-slider .categoria-header-base-text-container .categoria-header-base-text-content {
  width: 90%;
  margin: 0 auto;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
}
.carousel-landing-slider-container .carousel-landing-slider .categoria-header-base-text-container .categoria-header-base-text-content.top {
  justify-content: flex-start;
}
.carousel-landing-slider-container .carousel-landing-slider .categoria-header-base-text-container .categoria-header-base-text-content.bottom {
  justify-content: flex-end;
  margin-bottom: 5%;
}
.carousel-landing-slider-container .carousel-landing-slider .categoria-header-base-text-container .categoria-header-base-text-content.middle {
  justify-content: center;
}
.carousel-landing-slider-container .carousel-landing-slider .categoria-header-base-text-container .categoria-header-base-text-content.left {
  align-items: flex-start;
}
.carousel-landing-slider-container .carousel-landing-slider .categoria-header-base-text-container .categoria-header-base-text-content.right {
  align-items: flex-end;
}
.carousel-landing-slider-container .carousel-landing-slider .categoria-header-base-text-container .categoria-header-base-text-content.center {
  align-items: center;
  text-align: center;
}
.carousel-landing-slider-container .carousel-landing-slider .categoria-header-base-text-container .categoria-header-base-text-content h1 {
  background-color: #ffcb05;
  padding: 10px;
  overflow-wrap: anywhere;
  width: 40%;
}
.carousel-landing-slider-container .carousel-landing-slider .categoria-header-base-text-container .categoria-header-base-text-content h3 {
  font-weight: 500;
  font-size: 1.5em;
  width: 60%;
  background-color: #d5aa05;
  padding: 10px;
}
.carousel-landing-slider-container .carousel-landing-button-container {
  position: absolute;
  width: 100%;
  bottom: 50px;
}

.categoria-header-base-image-container, .categoria-header-base-container {
  width: 100%;
  height: 50vh;
}
.categoria-header-base-image-container img, .categoria-header-base-container img {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  width: 100%;
}
.categoria-header-base-image-container img.right, .categoria-header-base-container img.right {
  -o-object-position: right;
     object-position: right;
}
.categoria-header-base-image-container img.right.bottom, .categoria-header-base-container img.right.bottom {
  -o-object-position: bottom right;
     object-position: bottom right;
}
.categoria-header-base-image-container img.right.top, .categoria-header-base-container img.right.top {
  -o-object-position: top right;
     object-position: top right;
}
.categoria-header-base-image-container img.left, .categoria-header-base-container img.left {
  -o-object-position: left;
     object-position: left;
}
.categoria-header-base-image-container img.left.top, .categoria-header-base-container img.left.top {
  -o-object-position: top left;
     object-position: top left;
}
.categoria-header-base-image-container img.center, .categoria-header-base-container img.center {
  -o-object-position: center;
     object-position: center;
}

@media screen and (max-width: 900px) {
  .carousel-landing-slider-container .carousel-landing-slider .categoria-header-base-text-container .categoria-header-base-text-content h1 {
    width: 100%;
  }
  .carousel-landing-slider-container .carousel-landing-slider .categoria-header-base-text-container .categoria-header-base-text-content h3 {
    width: 100%;
  }
}
.conheca-lançamentos-container {
  width: 100%;
}
.conheca-lançamentos-container .btn-slider-container.btn-slider-next {
  color: #ebebe3;
  border-color: #ebebe3;
}

.conheca-lancamentos-slider {
  width: 90%;
  margin-left: 10%;
}
.conheca-lancamentos-slider .slick-list {
  padding: 0 100px 0 0 !important;
}

.conheca-lancamentos-content-container {
  position: relative;
  padding: 40px 0;
}
.conheca-lancamentos-content-container h1 {
  width: 90%;
  margin-left: auto;
}

.conheca-lancamentos-card-container {
  width: 25vw;
  margin: 10px 30px 10px 0;
}
.conheca-lancamentos-card-container.invisible {
  visibility: hidden;
}
.conheca-lancamentos-card-container .conheca-lancamentos-card-image-description-container {
  position: relative;
  height: 350px;
}
.conheca-lancamentos-card-container .conheca-lancamentos-card-image-description-container img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  height: -webkit-fill-available;
}
.conheca-lancamentos-card-container .conheca-lancamentos-card-image-description-container .conheca-lancamentos-card-description-container {
  position: absolute;
  background-color: #ffcb05;
  top: 100%;
  right: 0;
  width: 100%;
  height: 0;
  transition: top 0.5s, height 0.5s;
}
.conheca-lancamentos-card-container .conheca-lancamentos-card-image-description-container .conheca-lancamentos-card-description-container p, .conheca-lancamentos-card-container .conheca-lancamentos-card-image-description-container .conheca-lancamentos-card-description-container button {
  color: transparent;
  border: transparent;
}
.conheca-lancamentos-card-container .conheca-lancamentos-card-image-description-container .conheca-lancamentos-card-description-container .conheca-lancamentos-card-description-text-container {
  padding: 15px;
}
.conheca-lancamentos-card-container p {
  margin-top: 0;
}
.conheca-lancamentos-card-container:hover .conheca-lancamentos-card-description-container {
  top: 0;
  height: 100%;
}
.conheca-lancamentos-card-container:hover .conheca-lancamentos-card-description-container p {
  color: black;
  transition: color 0.5s;
  transition-delay: 0.25s;
}
.conheca-lancamentos-card-container:hover .conheca-lancamentos-card-description-container button {
  background-color: transparent;
  border: 2px solid black;
  color: black;
  transition: all 0.5s;
}
.conheca-lancamentos-card-container .conheca-lancamentos-card-info-container {
  background-color: #ebebe3;
  transition: background-color 0.5s;
  padding: 15px;
  display: flex;
  align-items: center;
  height: 4em;
}
.conheca-lancamentos-card-container .conheca-lancamentos-card-info-container h2 {
  font-family: "Stara";
  font-weight: 500;
  margin: 0;
  font-size: 19.2px;
  color: #231F20;
}
.conheca-lancamentos-card-container:hover .conheca-lancamentos-card-info-container {
  background-color: #d5aa05;
  color: white;
  transition: all 0.5s;
}
.conheca-lancamentos-card-container:hover .conheca-lancamentos-card-info-container h2 {
  font-weight: 500;
  color: white;
  transition: all 0.5s;
}

@media screen and (min-width: 200px) and (max-width: 920px) {
  .conheca-lancamentos-card-container .conheca-lancamentos-card-info-container {
    height: 6.5em;
  }
  .conheca-lancamentos-card-container .conheca-lancamentos-card-info-container h2 {
    font-size: 1.2em;
  }
}
.lancamentos-pimaco-item-container {
  position: relative;
  width: 100%;
  height: 250px;
}

.h1-section-title {
  font-weight: bold;
}

.lancamentos-pimaco-image {
  height: 250px;
}
.lancamentos-pimaco-image img {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  -o-object-fit: cover;
     object-fit: cover;
}

.conheca-lancamentos-initial-texts {
  text-align: center;
}

.lancamentos-pimaco-image-overlay-details {
  text-align: center;
}

.lancamentos-pimaco-image-overlay {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.25s;
}

.lancamentos-pimaco-image-overlay > * {
  transform: translateY(20px);
  transition: transform 0.25s;
}

.lancamentos-pimaco-image-overlay:hover {
  opacity: 1;
}

.lancamentos-pimaco-image-overlay:hover > * {
  transform: translateY(0);
}

@media screen and (max-width: 900px) {
  .conheca-lancamentos-card-container {
    width: 36vw;
  }
}
@media screen and (max-width: 600px) {
  .conheca-lancamentos-card-container {
    width: 60vw;
  }
}
.dicas-uso-produtos-container {
  width: 90%;
  margin: 0 auto;
  max-width: 1400px;
  padding: 40px 0;
}
.dicas-uso-produtos-container h1 {
  font-weight: bold;
}
.dicas-uso-produtos-container .dicas-uso-produtos-feed-container {
  width: 100%;
  height: 650px;
  overflow-y: scroll;
  padding: 15px;
  padding-left: 0;
  /* Handle */
  /* Handle on hover */
}
.dicas-uso-produtos-container .dicas-uso-produtos-feed-container .dicas-uso-produtos-item {
  width: 100%;
  height: 100%;
  max-height: 400px;
  position: relative;
}
.dicas-uso-produtos-container .dicas-uso-produtos-feed-container .dicas-uso-produtos-item .dicas-uso-produtos-item-text-container {
  position: absolute;
  width: 100%;
  height: 100%;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  transition: all 0.25s;
  opacity: 80%;
  color: #202020;
}
.dicas-uso-produtos-container .dicas-uso-produtos-feed-container .dicas-uso-produtos-item .dicas-uso-produtos-item-text-container .dicas-uso-produtos-item-logo-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 20%;
}
.dicas-uso-produtos-container .dicas-uso-produtos-feed-container .dicas-uso-produtos-item .dicas-uso-produtos-item-text-container .dicas-uso-produtos-item-logo-container .footer-social-logo {
  width: 2em;
  height: 2em;
  padding: 10px;
}
.dicas-uso-produtos-container .dicas-uso-produtos-feed-container .dicas-uso-produtos-item .dicas-uso-produtos-item-text-container p {
  height: 80%;
  text-overflow: ellipsis;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 10px;
  margin: 0;
  font-size: 1.5em;
  word-break: break-word;
  overflow: hidden;
  visibility: hidden;
  transition: all 0s;
}
.dicas-uso-produtos-container .dicas-uso-produtos-feed-container .dicas-uso-produtos-item:hover .dicas-uso-produtos-item-text-container {
  background-color: gold;
  visibility: visible;
  transition: all 0.25s;
}
.dicas-uso-produtos-container .dicas-uso-produtos-feed-container .dicas-uso-produtos-item:hover .dicas-uso-produtos-item-text-container p {
  visibility: visible;
  transition: all 0s;
}
.dicas-uso-produtos-container .dicas-uso-produtos-feed-container .dicas-uso-produtos-item img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.dicas-uso-produtos-container .dicas-uso-produtos-feed-container::-webkit-scrollbar {
  width: 5px;
}
.dicas-uso-produtos-container .dicas-uso-produtos-feed-container::-webkit-scrollbar-track {
  background: #ebebe3;
}
.dicas-uso-produtos-container .dicas-uso-produtos-feed-container::-webkit-scrollbar-thumb {
  background: #202020;
  width: 5px;
  border-radius: 10px;
}
.dicas-uso-produtos-container .dicas-uso-produtos-feed-container::-webkit-scrollbar-thumb:hover {
  background: black;
}
.dicas-uso-produtos-container .page-error-container {
  flex-direction: column;
  height: 100%;
}
.dicas-uso-produtos-container .page-error-container h3 {
  margin: 10px;
}
.dicas-uso-produtos-container .page-error-container p {
  margin: 5px;
}

.escolha-melhor-etiqueta-container {
  background-color: #ffcb05;
  display: flex;
  height: 650px;
}
.escolha-melhor-etiqueta-container .escolha-melhor-etiqueta-content {
  width: 90%;
  display: flex;
  flex-direction: row;
  max-width: 1400px;
  margin: 0 auto;
  height: 650px;
}
.escolha-melhor-etiqueta-container .escolha-melhor-etiqueta-content .escolha-melhor-etiqueta-text-container {
  width: 50%;
  margin: auto;
}
.escolha-melhor-etiqueta-container .escolha-melhor-etiqueta-content .escolha-melhor-etiqueta-text-container h1 {
  font-size: 3.5em;
  font-weight: bold;
}
.escolha-melhor-etiqueta-container .escolha-melhor-etiqueta-content .escolha-melhor-etiqueta-image-container {
  width: 50%;
}
.escolha-melhor-etiqueta-container .escolha-melhor-etiqueta-content .escolha-melhor-etiqueta-image-container img {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  width: -webkit-fill-available;
}

@media screen and (max-width: 600px) {
  .escolha-melhor-etiqueta-container {
    background-color: #ffcb05;
    display: flex;
    height: auto;
  }
  .escolha-melhor-etiqueta-container .escolha-melhor-etiqueta-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 1400px;
    height: auto;
  }
  .escolha-melhor-etiqueta-container .escolha-melhor-etiqueta-content .escolha-melhor-etiqueta-text-container {
    width: 90%;
    padding-right: 20%;
    text-align: center;
    padding: 30px 20px;
    margin: 0 auto;
  }
  .escolha-melhor-etiqueta-container .escolha-melhor-etiqueta-content .escolha-melhor-etiqueta-text-container h1 {
    font-size: 2em;
  }
  .escolha-melhor-etiqueta-container .escolha-melhor-etiqueta-content .escolha-melhor-etiqueta-image-container {
    width: 100%;
  }
  .escolha-melhor-etiqueta-container .escolha-melhor-etiqueta-content .escolha-melhor-etiqueta-image-container img {
    -o-object-fit: cover;
       object-fit: cover;
    height: -webkit-fill-available;
    width: -webkit-fill-available;
  }
}
.experimente-assistente-busca-container, .experimente-assistente-impressao-container {
  position: relative;
}
.experimente-assistente-busca-container .experimente-assistente-busca-image-container, .experimente-assistente-impressao-container .experimente-assistente-busca-image-container {
  height: 100vh;
  position: relative;
}
.experimente-assistente-busca-container .experimente-assistente-busca-image-container img, .experimente-assistente-impressao-container .experimente-assistente-busca-image-container img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100vh;
  -o-object-position: 15% 50%;
     object-position: 15% 50%;
}
.experimente-assistente-busca-container img, .experimente-assistente-impressao-container img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  height: 800px;
}
.experimente-assistente-busca-container .experimente-asssistente-busca-text-container, .experimente-assistente-busca-container .experimente-asssistente-impressao-text-container, .experimente-assistente-impressao-container .experimente-asssistente-busca-text-container, .experimente-assistente-impressao-container .experimente-asssistente-impressao-text-container {
  position: absolute;
  right: 10%;
  top: 50%;
  width: 30%;
  transform: translateY(-50%);
}
.experimente-assistente-busca-container .experimente-asssistente-busca-text-container, .experimente-assistente-impressao-container .experimente-asssistente-busca-text-container {
  top: 50%;
}
.experimente-assistente-busca-container .experimente-asssistente-busca-text-container h1, .experimente-assistente-impressao-container .experimente-asssistente-busca-text-container h1 {
  font-weight: bold;
  font-size: 2.5em;
}
.experimente-assistente-busca-container .experimente-asssistente-busca-text-container p, .experimente-assistente-impressao-container .experimente-asssistente-busca-text-container p {
  font-size: 1.5em;
}
.experimente-assistente-busca-container .experimente-asssistente-impressao-text-container, .experimente-assistente-impressao-container .experimente-asssistente-impressao-text-container {
  top: 0;
  transform: translateY(25%);
}
.experimente-assistente-busca-container .experimente-asssistente-impressao-text-container h1, .experimente-assistente-impressao-container .experimente-asssistente-impressao-text-container h1 {
  font-weight: bold;
  font-size: 2.5em;
}
.experimente-assistente-busca-container .experimente-asssistente-impressao-text-container p, .experimente-assistente-impressao-container .experimente-asssistente-impressao-text-container p {
  font-size: 1.5em;
}
.experimente-assistente-busca-container .custom-shape-divider-experimente-assistente-busca, .experimente-assistente-impressao-container .custom-shape-divider-experimente-assistente-busca {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}
.experimente-assistente-busca-container .custom-shape-divider-experimente-assistente-busca svg, .experimente-assistente-impressao-container .custom-shape-divider-experimente-assistente-busca svg {
  position: relative;
  display: block;
  width: calc(168% + 1.3px);
  height: 150px;
  transform: rotateY(180deg);
}
.experimente-assistente-busca-container .custom-shape-divider-experimente-assistente-busca .shape-fill, .experimente-assistente-impressao-container .custom-shape-divider-experimente-assistente-busca .shape-fill {
  fill: #FFFFFF;
}

.experimente-assistente-impressao-container img {
  -o-object-position: 65% 50%;
     object-position: 65% 50%;
}

@media screen and (max-width: 900px) {
  .experimente-assistente-busca-container, .experimente-assistente-impressao-container {
    position: relative;
  }
  .experimente-assistente-busca-container .experimente-assistente-busca-image-container, .experimente-assistente-impressao-container .experimente-assistente-busca-image-container {
    position: relative;
    height: 800px;
  }
  .experimente-assistente-busca-container .experimente-assistente-busca-image-container img, .experimente-assistente-impressao-container .experimente-assistente-busca-image-container img {
    -o-object-position: 50%;
       object-position: 50%;
    height: 800px;
  }
  .experimente-assistente-busca-container img, .experimente-assistente-impressao-container img {
    height: 100vw;
  }
  .experimente-assistente-busca-container .experimente-asssistente-busca-text-container, .experimente-assistente-impressao-container .experimente-asssistente-busca-text-container {
    width: 90%;
    text-align: center;
    position: static;
    transform: none;
  }
  .experimente-assistente-busca-container .experimente-asssistente-busca-text-container h1, .experimente-assistente-impressao-container .experimente-asssistente-busca-text-container h1 {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    font-size: 1.8em;
  }
  .experimente-assistente-busca-container .experimente-asssistente-busca-text-container:not(h1), .experimente-assistente-impressao-container .experimente-asssistente-busca-text-container:not(h1) {
    margin: 0 auto;
  }
  .experimente-assistente-busca-container .experimente-asssistente-impressao-text-container, .experimente-assistente-impressao-container .experimente-asssistente-impressao-text-container {
    width: 90%;
    text-align: center;
    position: static;
    transform: none;
    margin: 0 auto;
  }
  .experimente-assistente-busca-container .experimente-asssistente-impressao-text-container h1, .experimente-assistente-impressao-container .experimente-asssistente-impressao-text-container h1 {
    font-size: 1.8em;
  }
  .experimente-assistente-busca-container .experimente-asssistente-impressao-text-container p, .experimente-assistente-impressao-container .experimente-asssistente-impressao-text-container p {
    font-size: 1em;
  }
  .experimente-assistente-busca-container .custom-shape-divider-experimente-assistente-busca svg, .experimente-assistente-impressao-container .custom-shape-divider-experimente-assistente-busca svg {
    height: 50px;
  }
}
.explore-categorias-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #ebebe3;
  padding: 40px 0;
}
.explore-categorias-container .btn-slider-container.btn-slider-next {
  border-color: white;
  color: white;
}
.explore-categorias-container .slick-slider {
  width: 100%;
}
.explore-categorias-container .MuiGrid-root {
  display: flex !important;
}
.explore-categorias-container .explore-categorias-item {
  width: 100%;
  height: 30vw;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.explore-categorias-container .explore-categorias-item .explore-categorias-item-text {
  position: absolute;
  width: 25vw;
  height: 25vw;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 80%;
  visibility: hidden;
  text-align: center;
  padding: 0 25px;
  box-sizing: border-box;
}
.explore-categorias-container .explore-categorias-item .explore-categorias-item-text a, .explore-categorias-container .explore-categorias-item .explore-categorias-item-text a:link, .explore-categorias-container .explore-categorias-item .explore-categorias-item-text a:visited, .explore-categorias-container .explore-categorias-item .explore-categorias-item-text a:hover, .explore-categorias-container .explore-categorias-item .explore-categorias-item-text a:active {
  color: #202020;
}
.explore-categorias-container .explore-categorias-item img {
  -o-object-fit: cover;
     object-fit: cover;
  height: 25vw;
  margin: auto;
  width: 100%;
}
.explore-categorias-container .explore-categorias-item:hover {
  transform: scale(1.2);
  transition: all 0.3s;
}
.explore-categorias-container .explore-categorias-item:hover .explore-categorias-item-text {
  visibility: visible;
  background-color: #ffd100;
  transition: all 0.3s;
}

@media screen and (max-width: 900px) {
  .explore-categorias-container .explore-categorias-item {
    height: 50vw;
  }
  .explore-categorias-container .explore-categorias-item .explore-categorias-item-text {
    width: 42vw;
    height: 42vw;
  }
  .explore-categorias-container .explore-categorias-item img {
    height: 42vw;
  }
  .explore-categorias-container .explore-categorias-item:hover .explore-categorias-item-text {
    width: 50vw;
  }
}
.visite-duvidas-container {
  display: flex;
  width: 90%;
  max-width: 1400px;
  margin: 100px auto;
  justify-content: center;
}
.visite-duvidas-container .visite-duvidas-image-container img {
  width: 350px;
}
.visite-duvidas-container .visite-duvidas-text-container {
  width: 45%;
}
.visite-duvidas-container .visite-duvidas-text-container h1 {
  font-size: 3em;
  margin: 0;
  margin-left: -50px;
  font-weight: bold;
}
.visite-duvidas-container .visite-duvidas-text-container .btn, .visite-duvidas-container .visite-duvidas-text-container p {
  margin-left: 30px;
  margin-top: 30px;
}
.visite-duvidas-container.visite-duvidas-como-ler-etiquetas {
  position: relative;
  background-color: #ffcb05;
  margin: 0;
  padding: 250px 0 150px;
  max-width: none;
  width: 100%;
}
.visite-duvidas-container.visite-duvidas-como-ler-etiquetas .visite-duvidas-text-container {
  width: 30%;
}
.visite-duvidas-container.visite-duvidas-como-ler-etiquetas .shape-divider-top-visite-duvidas-frequentes-como-ler-etiquetas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}
.visite-duvidas-container.visite-duvidas-como-ler-etiquetas .shape-divider-top-visite-duvidas-frequentes-como-ler-etiquetas svg {
  position: relative;
  display: block;
  width: calc(157% + 1.3px);
  height: 150px;
}
.visite-duvidas-container.visite-duvidas-como-ler-etiquetas .shape-divider-top-visite-duvidas-frequentes-como-ler-etiquetas .shape-fill {
  fill: #FFFFFF;
}

@media screen and (max-width: 1200px) {
  .visite-duvidas-container .visite-duvidas-image-container img {
    width: 300px;
  }
  .visite-duvidas-container .visite-duvidas-text-container {
    width: 45%;
  }
  .visite-duvidas-container .visite-duvidas-text-container h1 {
    font-size: 2.3em;
  }
}
@media screen and (max-width: 900px) {
  .visite-duvidas-container {
    width: 100%;
    margin: 35px 0;
    padding: 80px 0;
    background-color: #ededed;
  }
  .visite-duvidas-container .visite-duvidas-image-container {
    width: 45%;
    margin-left: 5%;
  }
  .visite-duvidas-container .visite-duvidas-image-container img {
    width: 100%;
  }
  .visite-duvidas-container .visite-duvidas-text-container {
    width: auto;
    margin-right: 5%;
  }
  .visite-duvidas-container .visite-duvidas-text-container h1 {
    font-size: 2.5em;
    margin-left: 30px;
  }
  .visite-duvidas-container.visite-duvidas-como-ler-etiquetas .visite-duvidas-text-container {
    width: auto;
  }
}
@media screen and (max-width: 600px) {
  .visite-duvidas-container {
    width: 100%;
    margin: 35px 0;
    padding: 80px 0;
    background-color: #ededed;
  }
  .visite-duvidas-container .visite-duvidas-image-container {
    width: 80%;
  }
  .visite-duvidas-container .visite-duvidas-image-container img {
    width: 100%;
  }
  .visite-duvidas-container .visite-duvidas-text-container {
    width: auto;
  }
  .visite-duvidas-container .visite-duvidas-text-container h1 {
    font-size: 1.5em;
    margin-left: 30px;
  }
}
.leitura-etiquetas-categoria-selector-container {
  display: flex;
  width: 90%;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1400px;
}
.leitura-etiquetas-categoria-selector-container .leitura-etiquetas-categoria-selector-item-container {
  width: 14vw;
  max-width: 200px;
  cursor: pointer;
  transition: all 0.25s;
}
.leitura-etiquetas-categoria-selector-container .leitura-etiquetas-categoria-selector-item-container .leitura-etiquetas-categoria-item-image-container {
  width: 14vw;
  background-color: #ededed;
  max-width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.25s;
}
.leitura-etiquetas-categoria-selector-container .leitura-etiquetas-categoria-selector-item-container .leitura-etiquetas-categoria-item-image-container img {
  width: -webkit-fill-available;
  padding: 16px;
  filter: grayscale(100%);
}
.leitura-etiquetas-categoria-selector-container .leitura-etiquetas-categoria-selector-item-container .leitura-etiquetas-categoria-item-text-container {
  background-color: #d3d3cb;
  display: flex;
  justify-content: center;
  height: 4em;
  align-items: center;
  text-align: center;
  transition: all 0.25s;
}
.leitura-etiquetas-categoria-selector-container .leitura-etiquetas-categoria-selector-item-container .leitura-etiquetas-categoria-item-text-container h3 {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1em;
}
.leitura-etiquetas-categoria-selector-container .leitura-etiquetas-categoria-selector-item-container.active-item .leitura-etiquetas-categoria-item-image-container {
  background-color: #ffcb05;
}
.leitura-etiquetas-categoria-selector-container .leitura-etiquetas-categoria-selector-item-container.active-item .leitura-etiquetas-categoria-item-image-container img {
  filter: none;
}
.leitura-etiquetas-categoria-selector-container .leitura-etiquetas-categoria-selector-item-container.active-item .leitura-etiquetas-categoria-item-text-container {
  background-color: #d5aa05;
  color: white;
}
.leitura-etiquetas-categoria-selector-container .leitura-etiquetas-categoria-selector-button-group-container {
  display: none;
}

.leitura-etiquetas-description-container {
  background-color: #ebebe3;
  padding: 40px 0;
}
.leitura-etiquetas-description-container h1 {
  font-weight: bold;
}
.leitura-etiquetas-description-container .leitura-etiquetas-description-content {
  width: 90%;
  margin: 0 auto;
  max-width: 1400px;
}
.leitura-etiquetas-description-container .leitura-etiquetas-description-content img {
  width: 100%;
}

.leitura-etiquetas-info-embalagem-container {
  width: 90%;
  margin: 0 auto;
  max-width: 1400px;
  padding: 40px 0;
}
.leitura-etiquetas-info-embalagem-container h1 {
  font-weight: bold;
}
.leitura-etiquetas-info-embalagem-container .leitura-etiquetas-info-embalagem-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.leitura-etiquetas-info-embalagem-container .leitura-etiquetas-info-embalagem-item h3 {
  font-weight: 500;
  text-transform: uppercase;
}
.leitura-etiquetas-info-embalagem-container .leitura-etiquetas-info-embalagem-item img {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .leitura-etiquetas-description-container .leitura-etiquetas-description-content {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .leitura-etiquetas-description-container .leitura-etiquetas-description-content img {
    margin: 20px auto;
    width: 60%;
  }
}
@media screen and (max-width: 900px) {
  .leitura-etiquetas-categoria-selector-container .leitura-etiquetas-categoria-selector-item-container {
    width: 28vw;
  }
  .leitura-etiquetas-categoria-selector-container .leitura-etiquetas-categoria-selector-item-container .leitura-etiquetas-categoria-item-image-container {
    width: 28vw;
  }
  .leitura-etiquetas-categoria-selector-container .leitura-etiquetas-categoria-selector-button-group-container {
    display: block;
  }
  .leitura-etiquetas-categoria-selector-container .leitura-etiquetas-categoria-selector-button-group-container .btn-slider-container.btn-slider-next {
    border-color: #ebebe3;
  }
  .leitura-etiquetas-info-embalagem-container .leitura-etiquetas-info-embalagem-item {
    display: flex;
    flex-direction: column;
  }
  .leitura-etiquetas-info-embalagem-container .leitura-etiquetas-info-embalagem-item img {
    width: 75%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 600px) {
  .leitura-etiquetas-categoria-selector-container .leitura-etiquetas-categoria-selector-item-container {
    width: 45vw;
  }
  .leitura-etiquetas-categoria-selector-container .leitura-etiquetas-categoria-selector-item-container .leitura-etiquetas-categoria-item-image-container {
    width: 45vw;
  }
}
.produto-card-container {
  width: 300px;
  margin: 10px 15px;
}
.produto-card-container .produto-card-image-description-container {
  position: relative;
  height: 350px;
}
.produto-card-container .produto-card-image-description-container img {
  width: 100%;
  -o-object-fit: scale-down;
     object-fit: scale-down;
  height: 350px;
}
.produto-card-container .produto-card-image-description-container .produto-card-description-container {
  position: absolute;
  background-color: #ffd100;
  top: 100%;
  right: 0;
  width: 100%;
  height: 0;
  transition: top 0.5s, height 0.5s;
  visibility: hidden;
}
.produto-card-container .produto-card-image-description-container .produto-card-description-container p {
  color: transparent;
}
.produto-card-container .produto-card-image-description-container .produto-card-description-container .produto-card-description-text-container {
  padding: 15px;
}
.produto-card-container p {
  margin-top: 0;
}
.produto-card-container:hover .produto-card-description-container {
  top: 0;
  height: 100%;
  visibility: visible;
}
.produto-card-container:hover .produto-card-description-container p {
  color: black;
  transition: color 0.5s;
  transition-delay: 0.25s;
}
.produto-card-container .produto-card-info-container {
  background-color: #ebebe3;
  transition: background-color 0.5s;
  padding: 25px;
}
.produto-card-container .produto-card-info-container p.double-line {
  height: 2em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.produto-card-container .produto-card-info-container p.triple-line {
  height: 3em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.produto-card-container:hover .produto-card-info-container {
  background-color: goldenrod;
  color: white;
}
.produto-card-container:hover .produto-card-info-container .btn {
  border-color: white;
  color: white;
}

.categoria-header-base-text-container h1 {
  font-size: 3.5em;
  font-weight: bold;
}

.product-list {
  margin: 40px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.product-list .produto-card-container {
  width: 23%;
  margin: 10px 1.33%;
  opacity: 0;
  transform: translate(0, 30%);
  visibility: hidden;
  transition: opacity 300ms ease-out, transform 300ms ease-out;
  will-change: opacity, visibility;
}
.product-list .produto-card-container.visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
.product-list .produto-card-container:nth-of-type(4n+1), .product-list .produto-card-container:first-of-type {
  margin-left: 0;
  margin-right: 1.33%;
}
.product-list .produto-card-container:nth-of-type(4n) {
  margin-left: 1.33%;
  margin-right: 0;
}

.produto-filter-input-label {
  margin-bottom: 5px;
  font-family: "Manrope";
  color: #202020;
}

.MuiMenuItem-root {
  font-family: "Manrope";
}

.MuiInputLabel-root {
  font-family: "Manrope";
  color: #202020;
}

.MuiFormHelperText-root {
  font-family: "Manrope";
  color: #202020;
}

.produto-filter-filtrar-button {
  height: 56px;
}

.pimaco-product-filter-container {
  background-color: #ffcb05;
  padding: 10px;
  margin-top: 20px;
}
.pimaco-product-filter-container .form-clear-selection {
  right: 40px;
  display: flex;
  margin-top: 7px;
  position: absolute;
  cursor: pointer;
}

.categoria-header-base-text-container h1 {
  font-size: 3.5em;
  font-weight: bold;
}

@media screen and (max-width: 1200px) {
  .product-list .produto-card-container {
    width: 30%;
    margin: 10px 1.66%;
  }
  .product-list .produto-card-container:nth-of-type(4n+1), .product-list .produto-card-container:nth-of-type(4n) {
    margin: 10px 1.66%;
  }
  .product-list .produto-card-container:nth-of-type(3n+1), .product-list .produto-card-container:first-of-type {
    margin-left: 0;
    margin-right: 3.32%;
  }
  .product-list .produto-card-container:nth-of-type(3n) {
    margin-left: 3.32%;
    margin-right: 0;
  }
}
@media screen and (max-width: 900px) {
  .product-list .produto-card-container {
    width: 45%;
    margin: 10px 0;
  }
  .product-list .produto-card-container:nth-of-type(4n+1), .product-list .produto-card-container:nth-of-type(4n), .product-list .produto-card-container:nth-of-type(3n+1), .product-list .produto-card-container:nth-of-type(3n) {
    margin: 10px 0;
  }
  .product-list .produto-card-container:nth-of-type(2n+1), .product-list .produto-card-container:first-of-type {
    margin-left: 0;
    margin-right: 5%;
  }
  .product-list .produto-card-container:nth-of-type(2n) {
    margin-left: 5%;
    margin-right: 0;
  }
  .pimaco-product-filter-container .MuiFilledInput-input {
    padding: 7px 12px;
  }
  .pimaco-product-filter-container .MuiFormHelperText-root {
    line-height: 0;
  }
}
@media screen and (max-width: 600px) {
  .product-list .produto-card-container {
    width: 100%;
    margin: 10px 0;
  }
  .product-list .produto-card-container:nth-of-type(4n+1), .product-list .produto-card-container:nth-of-type(4n), .product-list .produto-card-container:nth-of-type(3n+1), .product-list .produto-card-container:nth-of-type(3n), .product-list .produto-card-container:nth-of-type(2n+1), .product-list .produto-card-container:nth-of-type(2n) {
    margin-left: 0;
    margin-right: 0;
  }
}
.product-page-header-container {
  background-color: #ebebe3;
  display: flex;
  justify-content: center;
}
.product-page-header-container .product-page-header-content {
  width: 90%;
  max-width: 1400px;
  display: flex;
  justify-content: center;
}
.product-page-header-container .product-page-header-content .product-page-image-container {
  width: 50%;
  display: flex;
  justify-content: center;
  height: 600px;
  padding: 40px 0;
}
.product-page-header-container .product-page-header-content .product-page-image-container img {
  -o-object-fit: scale-down;
     object-fit: scale-down;
  max-width: 100%;
}
.product-page-header-container .product-page-header-content .product-page-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
}
.product-page-header-container .product-page-header-content .product-page-text-container h1 {
  font-weight: bold;
  font-size: 3.5em;
  margin: 0;
}
.product-page-header-container .product-page-header-content .product-page-text-container h3 {
  margin: 5px;
  font-size: 2em;
  font-weight: 600;
}
.product-page-header-container .product-page-header-content .product-page-text-container p {
  font-size: 1.3em;
}

.related-products-container {
  background-color: #ffcb05;
}

.related-products-content-container {
  margin: 50px auto;
  padding: 35px 5%;
  max-width: 1400px;
  width: 90%;
}
.related-products-content-container h1 {
  font-weight: bold;
  margin: auto;
  font-size: 3.5em;
  margin-bottom: 30px;
}
.related-products-content-container .produto-card-container {
  margin: 10px auto;
  width: 300px !important;
}
.related-products-content-container .produto-card-container .produto-card-image-description-container {
  background-color: white;
}
.related-products-content-container .produto-card-container:hover .produto-card-image-description-container .produto-card-description-container {
  background-color: #fefefe;
}
.related-products-content-container .produto-card-container:hover .produto-card-info-container {
  background-color: #dbdbdb;
  color: black;
}
.related-products-content-container .produto-card-container:hover .produto-card-info-container .btn {
  border-color: black;
  color: black;
}
.related-products-content-container .btn-slider-container.btn-slider-next {
  border-color: #ebebe3;
}

.faca-mais-com-etiquetas-container {
  width: 100%;
  height: 800px;
  position: relative;
}
.faca-mais-com-etiquetas-container img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: -webkit-fill-available;
}
.faca-mais-com-etiquetas-container .faca-mais-com-etiquetas-text-container {
  position: absolute;
  right: 5%;
  bottom: 55%;
  transform: translateY(50%);
  width: 30%;
}
.faca-mais-com-etiquetas-container .faca-mais-com-etiquetas-text-container h1 {
  font-weight: bold;
  font-size: 2.5em;
}
.faca-mais-com-etiquetas-container .faca-mais-com-etiquetas-text-container button {
  height: 100%;
}

@media screen and (max-width: 900px) {
  .product-page-header-container {
    background-color: #ebebe3;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 35px;
    align-items: center;
  }
  .product-page-header-container .product-page-header-content {
    justify-content: center;
    flex-direction: column;
  }
  .product-page-header-container .product-page-header-content .product-page-image-container {
    width: auto;
    display: flex;
    height: 90vw;
    max-height: 500px;
    padding: 40px 0;
    justify-content: center;
  }
  .product-page-header-container .product-page-header-content .product-page-image-container img {
    height: 90vw;
    max-height: 500px;
  }
  .product-page-header-container .product-page-header-content .product-page-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    text-align: center;
    margin: 0 auto;
  }
  .product-page-header-container .product-page-header-content .product-page-text-container h1 {
    font-weight: bold;
    font-size: 2em;
    margin: 0;
  }
  .product-page-header-container .product-page-header-content .product-page-text-container h3 {
    margin: 5px;
    font-size: 1.17em;
    font-weight: 600;
  }
  .product-page-header-container .product-page-header-content .product-page-text-container p {
    font-size: 1.3em;
  }
  .product-page-header-container .product-page-header-content .product-content {
    text-align: center;
  }
  .related-products-content-container {
    margin: 50px 0;
    padding: 35px 5%;
  }
  .related-products-content-container .produto-card-container {
    margin: 20px;
  }
  .related-products-content-container .btn-slider-container.btn-slider-next {
    border-color: #ebebe3;
  }
  .faca-mais-com-etiquetas-container {
    width: 100%;
    height: auto;
    position: relative;
  }
  .faca-mais-com-etiquetas-container img {
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    height: auto;
  }
  .faca-mais-com-etiquetas-container .faca-mais-com-etiquetas-text-container {
    position: initial;
    display: block;
    right: unset;
    bottom: unset;
    transform: none;
    width: 100%;
    text-align: center;
    background-color: #efefef;
    padding: 50px;
    box-sizing: border-box;
  }
  .faca-mais-com-etiquetas-container .faca-mais-com-etiquetas-text-container h1 {
    font-weight: bold;
    font-size: 2.5em;
  }
  .faca-mais-com-etiquetas-container .faca-mais-com-etiquetas-text-container button {
    height: 100%;
  }
}
.MuiToolbar-root {
  font-family: "Manrope";
  padding: 0 16px;
  font-size: 1.3em;
  background-color: #b0afaa;
  color: white;
  border: 1px solid #202020;
  border-bottom: none;
}

.MuiTable-root {
  padding: 16px;
}

.MuiTableCell-root {
  font-family: "Manrope";
  font-weight: 500;
  font-size: 1em;
  border: 1px solid #202020;
}

.MuiTableBody-root > :nth-of-type(odd) {
  background-color: #ebebe3;
}
.MuiTableBody-root > :nth-of-type(even) {
  background-color: #d3d3cb;
}

.pimaco-product-filter-container {
  background-color: #ffcb05;
  padding: 10px;
  margin-top: 20px;
}
.pimaco-product-filter-container .form-clear-selection {
  right: 20px;
  display: flex;
  transform: translateY(50%);
  bottom: 45%;
  position: absolute;
  cursor: pointer;
}

body {
  margin: 0;
  font-family: "Manrope";
  background-color: white;
  font-weight: 500;
}
body h1, body h2 {
  font-family: "Stara";
}

.page-content {
  max-width: 1400px;
  width: 90%;
  margin: 0 auto;
}
.page-content h1, .page-content h2 {
  font-family: "Stara";
}
.page-content h2 {
  font-weight: 600;
  font-size: 1.8em;
}
.page-content.empresa-content {
  overflow-wrap: break-word;
  margin: 40px auto;
}
.page-content.empresa-content span.pilares-empresa-content p.page-paragraph {
  margin: 0.5em 0;
}

.page-error-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-active {
  background: white;
  filter: brightness(0.5);
  pointer-events: none;
}

.whatsapp-box-container {
  position: fixed;
  right: 4px;
  bottom: 75px;
  width: 450px;
  display: none;
  flex-direction: column;
  z-index: 5;
}
.whatsapp-box-container.show {
  display: flex;
}
.whatsapp-box-container h1, .whatsapp-box-container h1-form-title {
  margin-bottom: 10px;
  margin-top: 10px;
}
.whatsapp-box-container h3.h3-form-subtitle {
  margin-top: 0;
  margin-bottom: 15px;
}
.whatsapp-box-container h1 {
  font-size: 1.7em;
}
.whatsapp-box-container .MuiFilledInput-input {
  padding: 9px 12px;
}
.whatsapp-box-container .whatsapp-header-container {
  background-color: goldenrod;
  width: 100%;
  box-sizing: border-box;
  padding: 0 30px;
  border-radius: 5px 5px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.whatsapp-box-container .whatsapp-header-container .pimaco-icons {
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
}
.whatsapp-box-container .whatsapp-chat-container {
  width: 100%;
  background-color: #ffcb05;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 0 0 5px 5px;
}
.whatsapp-box-container .whatsapp-chat-container p {
  margin: 0;
}
.whatsapp-box-container .btn {
  padding: 5px 50px;
}

@media screen and (max-width: 600px) {
  .overlay-active .container {
    overflow-y: hidden;
  }
  .whatsapp-box-container {
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    top: 0;
    left: 0;
  }
}
.pimaco-icons.vetor-impressao .st0 {
  fill: none;
  stroke: #FFFFFF;
  stroke-width: 3;
  stroke-miterlimit: 10;
}
.pimaco-icons.vetor-impressao .st1 {
  fill: #FFFFFF;
}
.pimaco-icons.vetor-impressao .st2 {
  font-family: "Montserrat";
}
.pimaco-icons.vetor-impressao .st3 {
  font-size: 18px;
}
.pimaco-icons.vetor-impressao .st4 {
  font-family: "Montserrat";
  font-weight: 600;
}
.pimaco-icons.vetor-impressao .st5 {
  font-size: 26px;
}
.pimaco-icons.vetor-impressao .st6 {
  font-family: "HelveticaNeue-CondensedBold";
}
.pimaco-icons.vetor-impressao .st7 {
  font-size: 18.8741px;
}
.pimaco-icons.vetor-impressao .st8 {
  font-size: 15.7178px;
}
.pimaco-icons.vetor-impressao .st9 {
  font-size: 92.9322px;
}
.pimaco-icons.vetor-impressao .st10 {
  font-size: 38.8909px;
}
.pimaco-icons.vetor-impressao .st11 {
  font-size: 26.6443px;
}
.pimaco-icons.vetor-impressao .st12 {
  font-size: 24.5125px;
}
.pimaco-icons.vetor-impressao .st13 {
  font-size: 59.2358px;
}
.pimaco-icons.vetor-impressao .st14 {
  fill: none;
  stroke: #FFFFFF;
  stroke-miterlimit: 10;
}
.pimaco-icons.vetor-impressao .st15 {
  font-size: 45.4368px;
}/*# sourceMappingURL=main.css.map */