$primary-yellow: #ffcb05;
$secondary-yellow: #ffd100;
$thertiary-yellow: #d5aa05;

$primary-black: black;
$secondary-black: #202020;
$thertiary-black: #231F20;

$primary-grey: #b0afaa;
$secondary-grey: #ededed;
$thertiary-grey: #ebebe3;
$grey-four: #d3d3cb;

$website-width: 90%;

$shortest-transition-time: 0.25s;
$short-transition-time: 0.5s;
$medium-transition-time: 0.75s;
$long-transition-time: 1s;