@import './timeline';

.politica-privacidade-image {
    object-position: 15%;
}

.content-header-politica-privacidade {
    position: absolute;
    top: 30%;
    left: 20%;
    font-size: 3.5em;
    color: white;
    font-weight: 500;
    width: 20%;
}

.quem-somos-image {
    object-position: 20%;
}

.content-header-quem-somos-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    max-width: 1400px;
    transform: translateX(-50%);

    h1.content-header-quem-somos {
        color: white;
        width: 40%;
        max-width: 1400px;
        font-weight: bold;
        font-size: 3.5em;
    }
}

@media screen and (max-width:1200px) {
    .content-header-politica-privacidade {
        top: 20%;
        left: 10%;
        font-size: 2em;
        color: white;
        font-weight: 500;
        width: 20%;
    }
}

.page-paragraph {

    table,
    td {
        border: 1px solid black;
        border-collapse: collapse;
    }
}

td {
    padding: 10px 5px;
}

.termo-td-first-width {
    width: 330px;
}

.termo-td-second-width {
    width: 930px;
}