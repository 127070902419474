.pimaco-product-filter-container {
    background-color: $primary-yellow;
    padding: 10px; 
    margin-top: 20px;

    .form-clear-selection {
        right: 20px;
        display: flex;
        transform: translateY(50%);
        bottom: 45%;
        position: absolute;
        cursor: pointer;
    }
}