.explore-categorias-container {   
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: $thertiary-grey;
    padding: 40px 0;

    .btn-slider-container.btn-slider-next {
        border-color: white;
        color: white;
    }

    .slick-slider {
        width: 100%;
    }

    .MuiGrid-root {
        display: flex !important;
    }


    .explore-categorias-item {
        width: 100%;
        height: 30vw;
        transition: all 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;

        .explore-categorias-item-text {
            position: absolute;
            width: 25vw;
            height: 25vw;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 80%;
            visibility: hidden;
            text-align: center;
            padding: 0 25px;
            box-sizing: border-box;

            a, a:link, a:visited, a:hover, a:active {
                color: $secondary-black;
            }
        }

        img {
            object-fit: cover;
            height: 25vw;
            margin: auto;
            width: 100%;
        }

        &:hover {
            transform: scale(1.2);
            transition: all 0.3s;

            .explore-categorias-item-text {
                visibility: visible;
                // TODO: AJUSTE DE CORES
                background-color: $secondary-yellow;
                transition: all 0.3s;
            }
        }
    }
}


@media screen and (max-width: 900px) {
    .explore-categorias-container {   
             
        .explore-categorias-item {
            height: 50vw;

            .explore-categorias-item-text {
                width: 42vw;
                height: 42vw;
            }

            img {        
                height: 42vw;
            }

            &:hover {
    
                .explore-categorias-item-text {
                    width: 50vw;
                }
            }
        }
    }
}