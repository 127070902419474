@import './../common/common';
@import './_login.scss';
@import "./header/header";
@import './seletor/common';
@import './common/modals';
@import './steps/menu';
@import "./steps/propertyBar";
@import "./toolsBar/toolsBar";
@import './steps/tagEditor';
@import './steps/components/components';

.main-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr 40px;
    grid-template-areas: 'editor-header''editor-content''editor-footer';
    height: 100vh;

    .editor-header {
        grid-area: editor-header;
        height: 60px;
        max-height: 60px;
    }

    .editor-content {
        grid-area: editor-content;
        display: flex;
        flex-direction: column;

        .MuiFilledInput-input {
            padding: 9px 12px;
        }
    }

    .editor-footer {
        grid-area: editor-footer;
    }
}

.editor-menu {
    background-color: #f2f2f2;
    padding: 8px 0;
    display: flex;

}

.canvas-container {
    width: 100% !important;
}

.MuiBox-root, .MuiTableContainer-root, .accordion__panel {
    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        
      }
      
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $thertiary-grey;
        width: 5px;
        border-radius: 10px;
      }
      
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #dbdbd3;
      }

}

.accordion {
    width: 100%;
    border: none;

    .accordion__item {
        margin-bottom: 5px;
        outline: 1px solid #bbbbbb;
    }
}

.modal-alert-strong {
    font-weight: bold;
    color: #d32f2f;
}
                
.accordion__heading {
    padding: 15px 10px;
    background-color: #f8f8f8;

    .accordion__button {
        padding: 0;
        background-color: transparent;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 15px;
        text-transform: uppercase;

        svg {
            height: 1.2em;
            width: 2em;
        }

        &:hover {
            background-color: transparent;
        }

        &:before {
            display: none;
        }
    }

    .accordion__button[aria-expanded='true'] .pimaco-icons,
    .accordion__button[aria-selected='true'] .pimaco-icons {
        transform: rotate(180deg);
    }
}

.menu-accordion-produtos {
    .accordion__heading {
        padding: 15px 30px;
    }
}

.editor-menu-categoria-selector-container {

    .accordion {
        margin-top: 1px;
    }

    .accordion__button span.active {
        font-weight: 600;
    }
    .accordion__item {
        margin-right: 1px;
    }

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        
      }
      
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $thertiary-grey;
        width: 5px;
        border-radius: 10px;
      }
      
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #dbdbd3;
      }
}

.accordion__panel {
    padding: 15px 10px;
    background-color: #f8f8f8;
    max-height: 30vh;
    overflow-y: scroll;

    ul {
        list-style: none;
        padding-left: 0;
        margin: 0;

        li {
            margin: 5px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 0;
            padding-left: 15px;
            cursor: pointer;

            //text-transform: uppercase;

            &:hover, &.active {
                background-color: #e6e6e6;
                font-weight: 600;
                
            }
            
        }
    }

    .btn {
        padding: 10px 20px;
        width: 100%;
    }

    label {
        text-align: center;
    }

    .accordion__panel-filtros {
        padding: 0 0 10px;

        ul {
            list-style: none;
            padding-left: 0;
            margin: 0;

            .menu-produtos-subcategoria-item {
                margin: 5px 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 15px 0;
                padding-left: 50px;
                text-transform: uppercase;
                
            }
        }
    }
}

.accordion__item + .accordion__item {
    border-top: none !important;
}

.menu-accordion-produtos {
    .accordion__item + .accordion__item {
        border-top: none;
    }
}

ul.ul-navbar-mobile-itens {
    list-style: none;
    padding: 0 30px;
    margin: 0;

    li {
        padding: 15px 0;

    }
}

.accordion-menu-btn-container {
    margin: 15px 0;
}

.extra-bottom-margin {
    margin-bottom: 30px;
}

.editor-request-access-endereco-adornment {
    position: absolute;
    top: 15%;

    .MuiTypography-root {
        color: #1c1c1b;
        font-weight: 700;
    }
}

.produto-info-tooltip {
    background-color: white;
    color: $secondary-black;
    font-family: 'Manrope';
    font-size: 16px;
}

.canvas-preview {
    height: auto;
}