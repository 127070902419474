.form-container {
    background-color: $primary-yellow;
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;

    form {
        padding: 40px;
    }
}

@media screen and (max-width: 900px) {
    .form-container form {
        padding: 0;
        width: $website-width;
        margin: 0 auto;
    }
    
}

.contato-button-switcher-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;

    .btn {
        margin: 0 20px;
    }
}

.tela-agradecimento-container {
    text-align: center;

    h1 {
        font-weight: 600;
    }
}

@media screen and (max-width:600px) {
    .contato-button-switcher-container {
        flex-direction: column;
        width: $website-width;
        margin: 20px auto;
        
        .btn {
            margin: 10px 0;
        }
    }
    
}

div.dropzone-curriculo {
    cursor: pointer;
    outline: 1px solid black;
    padding: 30px 20px;
    margin: 25px 0;

    &.dropzone-curriculo-erro {
        outline: 3px solid red;
    }
}

h1.content-header-trabalhe-conosco {
    position: absolute;
    top: 30%;
    left: 15%;
    font-size: 3.5em;
    color: white;
    font-weight: bold;
}

h1.content-header-fale-conosco {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, 50%);
    font-size: 2.5em;
    font-weight: 600;
}

.h1-form-title, .h3-form-subtitle {
    font-weight: 600;
}

p.form-contato-initial-paragraph {
    font-size: 1.1em;
}

@media screen and (max-width:600px) {
    h1.content-header-trabalhe-conosco {
        top: 35%;
        left: 5%;
        font-size: 2em;
    }

    p.form-contato-initial-paragraph {
        text-align: center;
    }

    
    h1.content-header-fale-conosco {  
        top: 20%;
        transform: translate(-50%, 50%);
        font-size: 2em;
    }
}

.grid-titulos {
    display: flex;
}

.escolaridade-titulo {
    display: flex;
}

.formulario-main-text {
    display: flex;
}

.idiomas-titulo {
    display: flex;
}

.idioma-opcoes {
    display: flex;
}