
.editor-menu {
    background-color: #f2f2f2;
    padding: 8px;
    display: flex;
    justify-content: space-between;

    .editor-menu-list {
        display: flex;
    }

    .menu-item {
        padding: 0px 4px;
        border-left: 1px solid $secondary-grey;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:first-child {
            border-left: unset;
        }

        .btn {
            margin: 0px 4px;
            //font-size: 11px !important;
            color: $secondary-black;
            font-weight: 500;
            padding: 5px 16px !important;
            border-width: 2px;
            border-style: solid;
            border-radius: 8px;
            border-color: transparent;
            text-transform: initial;
            justify-content: flex-start;
            font-size: 12px;

            &.btn-header-editor {
                .MuiButton-startIcon {
                    margin-right: 0;
                    margin-left: 0;
                }
            }

            .MuiSvgIcon-root {
                font-size: 16px;
            }

            &:disabled {
                opacity: 0.39;
            }

            &:hover {
                background-color: rgb(235, 235, 227);
            }
        }
    }

    &.background-white {
        background-color: white;
    }

    &.darken-green-color {
        .menu-item {
            .btn {
                color: $primary-yellow !important;
            }
        }
    }
}

.MuiDialogActions-root {
    .btn-print-page {
        margin: 0px 4px;
        color: $secondary-black;
        font-weight: 500;
        padding: 5px 16px !important;
        border-width: 2px;
        border-style: solid;
        border-radius: 8px;
        border-color: transparent;
        text-transform: initial;
        justify-content: flex-start;
        font-size: 12px;

        
            .MuiButton-startIcon {
                margin-right: 0;
                margin-left: 0;
            }
    }
}



.menu-dropdown {
    overflow: visible;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32));
    margin-top: 8px;
    background-color: $primary-yellow;
    border-radius: 0px;
    color: $primary-black;
    box-shadow: none;
    
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: -36px;
        left: 24px;
        border: 28px solid $primary-yellow;
        border-top-color: transparent;
        border-right-color: transparent;
        border-left-color: transparent;
        z-index: 0;
        transform: rotateX(70deg)
    }


    .MuiMenuItem-root {
        color: $primary-black;

        .MuiTypography-root, .MuiSvgIcon-root {
            font-size: 14px;
            color: $primary-black;
        }

        .MuiSvgIcon-root {
            font-size: 20px;
        }
    }
}

.btn-delete-page {
    border: transparent;
    color: $primary-black;

    &:hover {
        background-color: #d32f2f !important;
        color: white;
    }
}

.btn-add-page {
    border: transparent;
    width: 100% !important;

    &:hover {
        background-color: rgba(0, 0, 0, 0.05) !important;
    }
}


.btn-delete-page, .btn-add-page {
    padding: 10px 5px !important;
    font-size: 0.7em;
}