footer {
    background-color: #c2c3bd;

    a, a:link, a:visited, a:hover, a:active {
        color: $primary-black;
    }

    .footer-navbar-container {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding: 15px;
        max-width: 1600px;
        margin: 0 auto;

    }

    .MuiSvgIcon-root {
        transition: transform 0.5s;
        cursor: pointer;

        &.expanded {
            transform: rotate(180deg);
            transition: transform 0.5s;
        }
    }

    .navbar-footer {
        max-width: 1400px;
        width: $website-width;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 15px;

        .product-li {
            width: 16.6%;
        }

        > ul {
            list-style-type: none;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            margin: 0 15px;
            align-items: flex-start;
            padding-left: 0;
            font-weight: 500;

            > li {
                display: flex;
                cursor: pointer;

                .list-item-item {
                    display: flex;
                    flex-direction: column;

                    .footer-navbar-ul {
                        list-style-type: none;
                        padding-left: 0;
                        color: transparent;
                        max-height: 0;
                        transition: all 0.5s;

                        li {
                            height: 0;
                            padding: 0;
                            font-weight: 500;
                        
                            a, a:link, a:visited, a:hover, a:active {
                                color: transparent;
                                text-decoration: none;
                                height: 0;
                                padding: 0;
                                font-weight: 500;
                                transition: all 0.5s;
                            }

                        }

                        &.show {                            
                            max-height: 500px;
                            color: $primary-black;
                            margin-top: 20px;

                            li {
                                height: auto;
                                padding: 5px 0;

                                a, a:link, a:visited, a:hover, a:active {
                                    color: $primary-black;
                                    height: 0;
                                    padding: 0;
                                    font-weight: 500;
                                    transition: all 0.5s;
                                }
                            }

                            
                        }

                    }
                }

            }
        }
    }

    .search-language-switcher-container {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-bottom: 20px;

        .language-switcher-text {
            text-transform: uppercase;
            cursor: pointer;

            &.active {
                font-weight: 600;
            }
        }

        .hidden {
            display: none;
            cursor: default;
        }

        svg {
            padding: 0 5px;
            height: 1em;
            width: 1em;   
        }
    }

    .footer-social-icons-container  {
        display: flex;

        .footer-social-logo {
            height: 20px;
            padding: 0 5px;
        }
    }
}

.footer-credits-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    p {
        font-size: 0.9em;
    }
}

@media screen and (max-width:1200px) {
    footer .footer-navbar-container {
        justify-content: space-between;
        align-items: center;
        width: $website-width;

        .logo {
            height: 60px;
        }
    }
}