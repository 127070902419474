@import './navbar-mobile';
@import './navbar-produtos';

.header {
    width: 100%;
    background-color: white;

    .header-top-half-container {
        max-width: 1400px;
        margin: 0 auto;
        width: $website-width;

        .logo-row-container {
            display: flex;
            justify-content: center;
            position: relative;

            .logo {
                height: 100px;
                padding: 5px;
            }

            .search-language-switcher-container {
                position: absolute;
                right: 0;
                padding: 20px 5px;
                display: flex;
                align-items: center;
                cursor: pointer;

                .language-switcher-text {
                    text-transform: uppercase;
                    cursor: pointer;

                    &.active {
                        font-weight: 600;
                    }
                }

                .hidden {
                    display: none;
                    cursor: default;
                }

                svg {
                    padding: 0 5px;
                    height: 1em;
                    width: 1em;   
                }

                .search-text-field {
                    width: 0;
                    visibility: hidden;
                    transition: all 0.5s;

                    & .MuiFilledInput-input {
                        padding-top: 0 ;
                        padding-bottom: 10px;
                    }

                    &.search-text-field-active {
                        width: 300px;
                        visibility: visible;
                        transition: all 0.5s;
                    }
                }

               
            }
        }
    }

    .navbar-mobile-accordion-container {
        
        .search-language-switcher-container {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-bottom: 20px;

            .language-switcher-text {
                text-transform: uppercase;
                cursor: pointer;
    
                &.active {
                    font-weight: 600;
                }
            }
    
            .hidden {
                display: none;
                cursor: default;
            }
    
            svg {
                padding: 0 5px;
                height: 1em;
                width: 1em;   
            }
        }


    }

    .navbar-dropdown-menu {
        display: none;
        position: absolute;
        background-color: white;
        top: 100%;
        width: auto;
        z-index: 1;
        font-weight: 500;

        ul {
            display: flex;
            flex-direction: column !important;
            padding-left: 0;
            width: initial !important;
            padding-right: 15px;
            white-space: nowrap;
            align-items: flex-start !important;

            li {
                padding-left: 15px !important;
                width: 100%;

                &:hover {
                    background-color: $thertiary-grey;
                }
            }
        }

        &.navbar-dropdown-menu-active {
            display: initial;
        }
    }

}

@media screen and (min-width: 1200px) {
    .header {
        position: sticky;
        top: 0;
        z-index: 10;
        
        .header-top-half-container { 

            .logo-row-container {
                
                .logo {
                    transition: 0.5s;

                    &.small-logo {
                        height: 50px;
                        transition: 0.5s;
                    }
                }     
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .header {

        .header-top-half-container { 

            .logo-row-container {
                justify-content: space-between;

                .logo {
                    height: 60px;
                }

                .menu-icon-container {
                    display: flex;
                    align-items: center;

                    svg {
                        background-color: $thertiary-grey;
                        height: 1.7em;
                        width: 1.7em;
                        padding: 10px;
                        border-radius: 15px;
                        cursor: pointer;
                    }
                }

            }
        }
    }
}



.navbar-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: $primary-yellow;

    .navbar {
        max-width: 1400px;
        width: $website-width;
        display: flex;
        justify-content: center;
        align-items: center;

        ul {
            list-style-type: none;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            margin: 0;
            align-items: center;
            padding-left: 0;

            li {
                display: flex;
                cursor: pointer;
                text-transform: uppercase;
                position: relative;
                padding: 15px 0;
                font-size: 0.95em;

                a {
                    text-decoration: none;
                }
            
                a:link, a:visited, a:hover, a:active {
                    color: $primary-black;
                }

                .search-icon-navbar {
                    cursor: pointer;
                }

                .pimaco-icons {
                    height: 1em;
                    width: 1em;
                }

            }

            > li.active-route {
                font-weight: 600;
            }
        }
    }

    .search-bar-container {
        width: 90%;
        height: 0;
        transition: all 0.5s;
        display: flex;
        align-items: center;
        max-width: 1400px;

        .search-bar-form {
            flex-grow: 1;
        }

        .pimaco-icons {
            height: 2em;
            width: 2em;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        form {

            .MuiFilledInput-root {
                align-items: center;
            }

            .pimaco-icons {
                width: 2em;
                height: 2em;
            }
        }

        & > * {
            visibility: hidden;
        }

        &.show {
            padding: 20px 0;
            height: 60px;
            transition: all 0.5s;

            & > * {
                visibility: visible;
                transition: visibility 0.5s;
                transition-delay: 0.25s;
                margin: 0 10px;
            }

            form {
                width: 100%;
            }

            .btn {
                height: 100%;
            }
        }


    }
}
