 .header {
    .navbar-produtos-container {
        position: absolute;
        //top: 0;
        background-color: white;
        display: flex;
        flex-direction: row;
        // max-height: 570px;
        width: 100%;
        z-index: 2;

        a {
            text-decoration: none;
        }
    
        a:link, a:visited, a:hover, a:active {
            color: $primary-black
        }

        .navbar-produtos-filtros-header {
            padding-bottom: 5px;
            width: 60%;
            border-bottom: 1px solid $primary-black;
            text-transform: uppercase;
            font-weight: 600;
        }

        .navbar-produtos-categorias-container {
            background-color: $thertiary-grey;
            width: 25%;
            padding-left: 100px;
            padding-top: 30px;


            ul.navbar-produtos-categorias-list {
                padding-left: 0px;
                padding-right: 0;
                list-style-type: none;

                li.navbar-produtos-categorias-list-item {
                    padding: 13px;
                    padding-left: 0;
                    background-color: transparent;
                    cursor: pointer;
                    display: flex;

                    a {
                        width: 100%;
                    }

                    &:hover,&.active-item {
                        background-color: white;
                    }

                    &.selected-item {
                        background-color: white;
                        font-weight: 700;
                    }
                }
            }

        }

        .navbar-produtos-categorias-filtros-container {
            width: 75%;
            flex-direction: row;
            flex-wrap: wrap;
            padding-left: 40px;
            padding-top: 30px;
            padding-right: 5%;
            display: flex;
            justify-content: flex-start;

            .navbar-produtos-filtros-list-container {
                width: 25%;


                ul.navbar-produtos-filtros-list {
                    list-style-type: none;
                    padding-left: 0;
                    width: 95%;

                    li.navbar-produtos-filtros-list-item {
                        padding: 4px 0;
                    }
                }
            }
        }
    }
}