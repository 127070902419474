span.accordion-menu.accordion-menu-selected {
    display: flex;
}

span.accordion-menu {
    display: none;
}

span.accordion-menu.accordion-filtros-selected {
    display: flex;
    flex-direction: column;
}

.navbar-mobile-search-container {
    display: flex;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 15px;
    height: 62px;
    width: $website-width;

    .navbar-mobile-search-textfield {
        width: 100%;
        height: 100%;
        margin: 0 15px;
        justify-content: center;
        margin-left: 0;
    }

    .btn {
        height: 100%;
        margin: 0 15px;
        margin-right: 0;
        min-width: 200px;
        padding: 10px 30px;
    }
}

@media screen and (max-width: 600px) {
    .navbar-mobile-search-container {
        .btn {
            min-width: 0;
        }
    }
}

footer .navbar-mobile-search-container {
    display: none;
}

.accordion-menu {
    width: $website-width;
    display: flex;
    margin: 0 auto;
    margin-bottom: 15px;

    .accordion {
        width: 100%;
        border: none;
    }

    a {
        text-decoration: none;
    }

    a:link, a:visited, a:hover, a:active {
        color: $secondary-black
    }

    .menu-produtos-category-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .accordion__heading {
        padding: 15px 10px;

        .accordion__button {
            padding: 0;
            background-color: transparent;
            display: flex;
            justify-content: space-between;
            align-items: center;
            //text-transform: uppercase;

            svg {
                height: 1.2em;
                width: 2em;
            }

            &:hover {
                background-color: transparent;
            }

            &:before {
                display: none;
            }
        }

        .accordion__button[aria-expanded='true'] .pimaco-icons,
        .accordion__button[aria-selected='true'] .pimaco-icons {
            transform: rotate(180deg);
        }
    }

    .menu-accordion-produtos {
        .accordion__heading {
            padding: 15px 30px;
        }
    }

    .accordion__panel {
        padding: 0;

        .accordion__panel-filtros {
            padding: 0 0 10px;

            ul {
                list-style: none;
                padding-left: 0;
                margin: 0;

                .menu-produtos-subcategoria-item {
                    margin: 5px 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 15px 0;
                    padding-left: 50px;
                    text-transform: uppercase;
                    
                }
            }
        }
    }

    .accordion__item + .accordion__item {
        border-top: 0.5px solid rgba (0, 0, 0, 0.7);
    }

    .menu-accordion-produtos {
        .accordion__item + .accordion__item {
            border-top: none;
        }
    }

    ul.ul-navbar-mobile-itens {
        list-style: none;
        padding: 0 30px;
        margin: 0;

        li {
            padding: 15px 0;

        }
    }

    .accordion-menu-btn-container {
        margin: 15px 0;
    }
}

header {
    .accordion-menu {

        .accordion__heading {
            .accordion__button {
                color: $secondary-black;
            }
        }

        .accordion__panel {
            background-color: #ededef;

            .accordion__panel-filtros {
                ul {
                    .menu-produtos-subcategoria-item {
                        background-color: #f7f7f7;
                    }
                }

            }
        }

        &.accordion-filtros-selected .accordion__panel {
            background-color: #f7f7f7;
        }
    }
}

footer {
    .accordion-menu {

        .accordion__heading {
            .accordion__button {
                color: $secondary-black;
            }
        }

        .accordion__panel {
            background-color: #d5d6d2;

            .accordion__panel-filtros {
                ul {
                    .menu-produtos-subcategoria-item {
                        background-color: #f7f7f7;
                    }
                }

            }
        }

        &.accordion-filtros-selected .accordion__panel {
            background-color: #f7f7f7;
        }
    }
}