.whatsapp-floating-button {
  position: fixed;
  right: 0;
  top: 50%;
}

.btn-link {
  text-decoration: none;
}
.btn-link.download-text {
  font-weight: bold;
}
.btn-link.download-text:hover, .btn-link.download-text:link, .btn-link.download-text:visited, .btn-link.download-text:active {
  color: inherit;
}

.btn {
  padding: 10px 50px;
  border-radius: 0;
  font-family: "Stara";
  font-weight: bolder;
}

@media screen and (max-width: 600px) {
  .btn {
    padding: 10px 25px;
  }
}
.btn-contained-primary {
  background-color: #202020;
  border: 2px solid #202020;
}
.btn-contained-primary:hover {
  background-color: black;
}

.btn-contained-secondary {
  background-color: white;
  color: #202020;
  border: 2px solid #202020;
  transition: all 0.5s;
}
.btn-contained-secondary:hover {
  background-color: #202020;
  color: white;
  transition: all 0.5s;
}

.btn-contained-danger {
  background-color: #D32F2F;
  color: white;
  transition: all 0.5s;
}
.btn-contained-danger :not(.Mui-disabled) {
  border: 2px solid #D32F2F;
}
.btn-contained-danger:hover {
  background-color: #A82424;
  color: white;
  transition: all 0.5s;
}

.btn-contained-thertiary {
  background-color: #ffcb05;
  color: black;
  border: 2px solid #ffcb05;
  transition: all 0.5s;
}
.btn-contained-thertiary:hover {
  background-color: #ffd100;
  color: black;
  transition: all 0.5s;
}

.btn-outlined-primary {
  background-color: transparent;
  border: 2px solid black;
  color: black;
}
.btn-outlined-primary:hover {
  border: 2px solid black;
}

.button-slider-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 35px !important;
  flex-direction: row;
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
}

.btn-slider-container {
  border-bottom: 4px solid;
  width: 170px;
  display: flex;
  cursor: pointer;
}
.btn-slider-container.btn-slider-next {
  justify-content: flex-end;
  border-color: white;
}
.btn-slider-container.btn-slider-prev {
  justify-content: flex-start;
  border-color: black;
}
.btn-slider-container p {
  margin-bottom: 2px;
  color: black;
}

.white-bg-btn .btn-slider-container.btn-slider-next {
  border-color: #ebebe3;
}

.MuiFilledInput-root {
  background-color: white;
  outline: 1px solid black;
  font-family: "Manrope";
  border-radius: 0;
}
.MuiFilledInput-root:hover {
  background-color: rgba(255, 255, 255, 0.9);
}
.MuiFilledInput-root.Mui-disabled:hover {
  background-color: rgba(0, 0, 0, 0.12);
}
.MuiFilledInput-root.Mui-focused {
  background-color: white;
}
.MuiFilledInput-root:after, .MuiFilledInput-root::before {
  display: none;
}

.MuiFilledInput-input {
  padding: 18px 12px;
  font-weight: 500;
}

.MuiAutocomplete-popper, .MuiAutocomplete-listbox {
  font-family: "Manrope";
}

.MuiAutocomplete-groupLabel {
  font-family: "Manrope";
  font-weight: 800;
  font-size: 1rem;
}

.MuiAutocomplete-inputRoot {
  padding-top: 9px !important;
  padding-bottom: 9px;
}

.MuiFormControlLabel-root .MuiSvgIcon-root {
  background-color: white;
  color: transparent;
  width: 0.9em;
  height: 0.9em;
}
.MuiFormControlLabel-root .MuiCheckbox-root.Mui-checked .MuiSvgIcon-root {
  color: #202020;
}

.MuiMenuItem-root {
  font-family: "Manrope";
  font-weight: 500;
}

.MuiInputLabel-root, .MuiFormControlLabel-root, .MuiTypography-root {
  font-family: "Manrope";
  color: #202020;
  font-weight: 600;
}

.MuiInputAdornment-root {
  color: black;
  font-weight: 600;
  margin-top: 0 !important;
}
.MuiInputAdornment-root.MuiInputAdornment-positionEnd {
  padding: 0 10px;
}

.MuiFilledInput-root.number-input .MuiFilledInput-input {
  padding-left: 0;
}
.MuiFilledInput-root.number-input .MuiInputAdornment-root .MuiTypography-root {
  font-size: 0.9em;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
}

.MuiFilledInput-root.Mui-error {
  outline: 3px solid red;
}

@media screen and (max-width: 900px) {
  .MuiTextField-root {
    width: 100%;
  }
}
.content-header-container {
  width: 100%;
  position: relative;
  height: 600px;
}
.content-header-container .content-header-image {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 900px) {
  .content-header-container {
    height: 250px;
  }
}
.content-box-container {
  width: 90%;
  max-width: 400px;
  text-align: center;
  margin: 40px auto;
  background-color: #ebebe3;
}
.content-box-container .content-box-content {
  padding: 20px;
}
.content-box-container .content-box-content h3 {
  font-weight: 500;
  font-size: 1.5em;
  margin: 5px;
}
.content-box-container .content-box-content p {
  margin: 5px;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
.placeholder {
  display: flex;
  justify-content: center;
}
.placeholder .loading-image {
  height: 160px;
  width: 120px;
  background-color: #dddddd;
  border-radius: 4px;
  max-width: 10vw;
}
.placeholder .loading-text {
  background-color: #dddddd;
  border-radius: 4px;
  height: 20px;
  width: 200px;
  max-width: 10vw;
}
.placeholder .loading-text.short {
  width: 100px;
  max-width: 5vw;
}
.placeholder .loading-text.long {
  width: 325px;
  max-width: 17vw;
}
.placeholder .loading-text.extra-long {
  width: 450px;
  max-width: 30vw;
}
.placeholder .loading-button {
  background-color: #dddddd;
  border-radius: 4px;
  height: 70px;
  width: 200px;
  max-width: 13vw;
}
.placeholder .loading-button.actions {
  width: 70px;
}
.placeholder.shimmer {
  overflow: hidden;
  position: relative;
}
.placeholder.shimmer::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  animation: shimmer 1s infinite;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0) 100%);
}

.floating-button {
  position: fixed;
  right: 0;
  bottom: 5px;
  margin-right: 5px;
  cursor: pointer;
}

svg.whatsapp-icon {
  height: 3.5em;
  width: 3.5em;
}
svg.whatsapp-icon .st0 {
  fill: #FFFFFF;
}
svg.whatsapp-icon .st1 {
  fill: #38AF4A;
}

p.page-paragraph, li.page-paragraph {
  font-size: 1.2em;
}

li.page-paragraph span {
  display: block;
  margin: 1em 0;
}

.MuiSnackbar-root .MuiPaper-root {
  justify-content: center;
  font-family: "Stara";
  font-weight: 500;
}
.MuiSnackbar-root.info .MuiPaper-root {
  color: #202020;
  background-color: #ffd100;
}
.MuiSnackbar-root.success .MuiPaper-root {
  color: white;
  background-color: #47b44d;
}

.clickable-span {
  cursor: pointer;
}

.login-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.editor-login {
  width: 50%;
  display: flex;
  overflow-y: auto;
}
.editor-login .MuiFormControlLabel-asterisk {
  display: none;
}

.editor-login-container {
  width: 80%;
  margin: auto;
}

.editor-login-authentication-image {
  text-align: center;
  margin: 15px 0px 35px 0px;
}
.editor-login-authentication-image .editor-login-authentication-image-img {
  width: 25%;
}

.editor-login-authentication {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffcb05;
  padding-bottom: 25px;
  text-align: center;
  margin-top: 20px;
}
.editor-login-authentication .MuiGrid-item {
  width: 80%;
}
.editor-login-authentication.modal-grid {
  background-color: transparent;
}
.editor-login-authentication .modal-userdata-buttons {
  display: flex;
  justify-content: flex-end;
}
.editor-login-authentication .modal-userdata-buttons button + button {
  margin-left: 10px;
}

.editor-login-authentication-text {
  text-align: center;
  font-weight: 500;
}

.editor-login-authentication-cadastro-fields {
  width: 80%;
}

.editor-login-button {
  text-align: center;
  margin: 5px 0px 20px 0px;
}

.editor-login-paragraphs {
  font-size: 13px;
  text-decoration: underline;
  margin: 0px 0px 10px 0px;
  cursor: pointer;
  color: black;
}

.editor-login-eula {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.editor-image {
  width: 50%;
  height: 100vh;
}

.editor-login-image-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: bottom;
     object-position: bottom;
}

.editor-login-authentication-fields.editor-request-access-endereco .MuiInputBase-multiline .MuiInputAdornment-root.MuiInputAdornment-disablePointerEvents {
  margin-top: 0px;
}

.editor-login-authentication-fields.editor-request-access-endereco .MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-root textarea.MuiFilledInput-input.MuiInputBase-input {
  padding: 25px 0px 0px 0px;
}

.editor-request-access-endereco-label {
  margin-top: 0px;
  position: absolute;
  top: 15%;
}
.editor-request-access-endereco-label .MuiTypography-root {
  color: black;
  font-weight: 500;
  font-family: "Manrope";
}

.editor-login-cadastro-checkbox {
  display: flex;
  align-items: center;
}
.editor-login-cadastro-checkbox .fc-final-checkbox-label {
  text-align: left;
  display: flex;
  align-items: center;
}

.page-paragraph .autenticacao-texto {
  text-decoration: underline;
  cursor: pointer;
}

.modal-pwa-button {
  display: none;
}

.editor-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.editor-header .logo {
  height: 95%;
  padding: 5px;
}
.editor-header .btn {
  padding-left: 24px;
  padding-right: 24px;
  color: #d5aa05;
  margin-left: 12px;
  text-transform: initial;
}
.editor-header .btn.btn-header-editor .MuiButton-startIcon {
  margin-right: 0;
  margin-left: 0;
}
.editor-header .editor-stepper-container {
  display: flex;
  text-align: center;
}
.editor-header .editor-stepper-container .editor-stepper-item {
  width: 250px;
  background-color: #ebebeb;
  margin: 0 5px;
  cursor: pointer;
}
.editor-header .editor-stepper-container .editor-stepper-item strong {
  font-family: "Stara";
}
.editor-header .editor-stepper-container .editor-stepper-item.editor-active-step {
  background-color: #FFCB05;
}

.MuiMenuItem-root .btn-link {
  display: flex;
  align-items: center;
}
.MuiMenuItem-root .btn-link:link, .MuiMenuItem-root .btn-link:visited, .MuiMenuItem-root .btn-link:hover, .MuiMenuItem-root .btn-link:active {
  color: #202020;
}

.editor-menu .editor-menu-filters {
  width: 90%;
  margin: 0 25px;
}
.editor-menu .editor-menu-filters .btn {
  margin: 0px 4px;
  color: #202020;
  font-weight: 500;
  padding: 5px 16px !important;
  border-width: 2px;
  border-style: solid;
  border-radius: 8px;
  border-color: transparent;
  text-transform: initial;
  justify-content: flex-start;
  font-size: 12px;
}
.editor-menu .editor-menu-filters .btn.btn-header-editor .MuiButton-startIcon {
  margin-right: 0;
  margin-left: 0;
}
.editor-menu .editor-menu-filters .btn .MuiSvgIcon-root {
  font-size: 16px;
}
.editor-menu .editor-menu-filters .btn:disabled {
  opacity: 0.39;
}
.editor-menu .editor-menu-filters .btn:hover {
  background-color: rgb(235, 235, 227);
}
.editor-menu .editor-menu-filters > :first-child {
  padding-left: 0;
}
.editor-menu .editor-menu-filters-spacing {
  width: 15%;
  min-width: 300px;
}

.editor-menu-etiqueta-selection {
  display: flex;
  flex-direction: row;
  height: 84vh;
  margin-top: 10px;
}
.editor-menu-etiqueta-selection .MuiBox-root {
  display: flex;
  width: 90%;
  margin: 0 25px;
  flex-direction: column;
}
.editor-menu-etiqueta-selection .editor-menu-categoria-selector-error {
  font-size: 1.2em;
  margin: 20px;
}
.editor-menu-etiqueta-selection img.produto-imagem-etiquetas {
  height: 160px;
  width: auto;
  margin: 0 auto;
}

.MuiTableContainer-root .MuiTableCell-root {
  font-family: "Manrope";
}
.MuiTableContainer-root .MuiTableCell-head {
  background-color: #ffcb05;
}
.MuiTableContainer-root .MuiTable-root .MuiTableBody-root .MuiTableRow-root {
  border-radius: 5px;
  outline: 1px solid rgb(224, 224, 224);
}
.MuiTableContainer-root .MuiTable-root .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
  border: none;
}

.editor-menu .editor-menu-pesquisa-template {
  max-width: 1000px;
  margin: 0 auto;
  width: 50%;
}
.editor-menu .search-field-faq svg {
  width: 2em;
  height: 2em;
}
.editor-menu .editor-menu-filters {
  width: 90%;
  margin: 0 25px;
}
.editor-menu .editor-menu-filters > :first-child {
  padding-left: 0;
}
.editor-menu .editor-menu-filters-spacing {
  width: 15%;
  min-width: 300px;
}

.dialog-preview-template {
  overflow-x: hidden;
}

.editor-menu-template-selection {
  display: flex;
  flex-direction: row;
  height: 85vh;
  /* Handle */
  /* Handle on hover */
}
.editor-menu-template-selection .MuiBox-root {
  width: 65%;
  overflow-y: scroll;
  margin: 0 25px;
}
.editor-menu-template-selection .MuiBox-root .MuiGrid-container {
  padding-right: 32px;
}
.editor-menu-template-selection .MuiFormGroup-root {
  margin-right: 1px;
  margin-top: 1px;
}
.editor-menu-template-selection::-webkit-scrollbar {
  width: 10px;
}
.editor-menu-template-selection::-webkit-scrollbar-track {
  background: transparent;
}
.editor-menu-template-selection::-webkit-scrollbar-thumb {
  background: #ebebe3;
  width: 5px;
  border-radius: 10px;
}
.editor-menu-template-selection::-webkit-scrollbar-thumb:hover {
  background: #dbdbd3;
}

.editor-menu-prod-selecionado-info-container {
  width: 25%;
  max-width: 300px;
}
.editor-menu-prod-selecionado-info-container.tooltip {
  width: 100%;
}
.editor-menu-prod-selecionado-info-container .editor-menu-prod-selecionado-content {
  display: flex;
  width: 100%;
  padding-top: 32px;
}
.editor-menu-prod-selecionado-info-container .editor-menu-prod-selecionado-content img {
  width: 50%;
  max-height: 300px;
  -o-object-fit: scale-down;
     object-fit: scale-down;
}
.editor-menu-prod-selecionado-info-container .editor-menu-prod-selecionado-content .editor-menu-prod-selecionado-data {
  padding: 10px;
  width: 50%;
  word-wrap: break-word;
  box-sizing: border-box;
}

.editor-menu-template-item {
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  cursor: pointer;
  position: relative;
  border: 1px solid #dddddd;
  border-radius: 10px;
}
.editor-menu-template-item .editor-menu-template-item-buttons-admin {
  opacity: 0;
  cursor: none;
  pointer-events: none;
  transition: 0.25s;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: white;
  border-radius: 10px;
  padding: 2px;
}
.editor-menu-template-item:hover .editor-menu-template-item-buttons-admin {
  opacity: 100%;
  cursor: initial;
  pointer-events: initial;
  transition: 0.25s;
}
.editor-menu-template-item .editor-menu-template-item-data-container {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #ffefb4;
  display: flex;
  width: 100%;
  padding: 10px 0;
  box-sizing: border-box;
  padding-left: 10px;
  margin-top: 10px;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}
.editor-menu-template-item .editor-menu-template-item-data-container p {
  margin: 0;
}

.editor-menu-categoria-selector-container {
  width: 15%;
  min-width: 300px;
  height: 100%;
  background-color: transparent;
  box-sizing: content-box;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Handle */
  /* Handle on hover */
}
.editor-menu-categoria-selector-container .btn {
  padding: 5px 25px;
}
.editor-menu-categoria-selector-container::-webkit-scrollbar {
  width: 10px;
}
.editor-menu-categoria-selector-container::-webkit-scrollbar-track {
  background: transparent;
}
.editor-menu-categoria-selector-container::-webkit-scrollbar-thumb {
  background: #ebebe3;
  width: 5px;
  border-radius: 10px;
}
.editor-menu-categoria-selector-container::-webkit-scrollbar-thumb:hover {
  background: #dbdbd3;
}
.editor-menu-categoria-selector-container fieldset.MuiFormControl-root {
  width: 100%;
}
.editor-menu-categoria-selector-container fieldset.MuiFormControl-root .MuiFormControlLabel-root {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 5px;
  background-color: #f8f8f8;
  margin-top: 0;
  padding: 7px 10px;
  outline: 1px solid #bbbbbb;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
}
.editor-menu-categoria-selector-container fieldset.MuiFormControl-root .MuiFormControlLabel-root .MuiSvgIcon-root {
  background-color: transparent;
  color: transparent;
  width: 0.9em;
  height: 0.9em;
}
.editor-menu-categoria-selector-container fieldset.MuiFormControl-root .MuiFormControlLabel-root .MuiCheckbox-root.Mui-checked .MuiSvgIcon-root {
  color: #d5aa05;
}
.editor-menu-categoria-selector-container fieldset.MuiFormControl-root .MuiFormControlLabel-root .MuiTypography-root {
  font-family: "Manrope";
}
.editor-menu-categoria-selector-container a {
  text-decoration: none;
}
.editor-menu-categoria-selector-container a:link, .editor-menu-categoria-selector-container a:visited, .editor-menu-categoria-selector-container a:hover, .editor-menu-categoria-selector-container a:active {
  color: #202020;
}

.form-clear-selection {
  right: 20px;
  display: flex;
  transform: translateY(50%);
  bottom: 43.5%;
  position: absolute;
  cursor: pointer;
}

.minhas-etiquetas-message {
  text-align: center;
}

.editor-menu {
  background-color: #f2f2f2;
  padding: 8px;
  display: flex;
  justify-content: space-between;
}
.editor-menu .editor-menu-list {
  display: flex;
}
.editor-menu .menu-item {
  padding: 0px 4px;
  border-left: 1px solid #ededed;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.editor-menu .menu-item:first-child {
  border-left: unset;
}
.editor-menu .menu-item .btn {
  margin: 0px 4px;
  color: #202020;
  font-weight: 500;
  padding: 5px 16px !important;
  border-width: 2px;
  border-style: solid;
  border-radius: 8px;
  border-color: transparent;
  text-transform: initial;
  justify-content: flex-start;
  font-size: 12px;
}
.editor-menu .menu-item .btn.btn-header-editor .MuiButton-startIcon {
  margin-right: 0;
  margin-left: 0;
}
.editor-menu .menu-item .btn .MuiSvgIcon-root {
  font-size: 16px;
}
.editor-menu .menu-item .btn:disabled {
  opacity: 0.39;
}
.editor-menu .menu-item .btn:hover {
  background-color: rgb(235, 235, 227);
}
.editor-menu.background-white {
  background-color: white;
}
.editor-menu.darken-green-color .menu-item .btn {
  color: #ffcb05 !important;
}

.MuiDialogActions-root .btn-print-page {
  margin: 0px 4px;
  color: #202020;
  font-weight: 500;
  padding: 5px 16px !important;
  border-width: 2px;
  border-style: solid;
  border-radius: 8px;
  border-color: transparent;
  text-transform: initial;
  justify-content: flex-start;
  font-size: 12px;
}
.MuiDialogActions-root .btn-print-page .MuiButton-startIcon {
  margin-right: 0;
  margin-left: 0;
}

.menu-dropdown {
  overflow: visible;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32));
  margin-top: 8px;
  background-color: #ffcb05;
  border-radius: 0px;
  color: black;
  box-shadow: none;
}
.menu-dropdown:before {
  content: "";
  display: block;
  position: absolute;
  top: -36px;
  left: 24px;
  border: 28px solid #ffcb05;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  z-index: 0;
  transform: rotateX(70deg);
}
.menu-dropdown .MuiMenuItem-root {
  color: black;
}
.menu-dropdown .MuiMenuItem-root .MuiTypography-root, .menu-dropdown .MuiMenuItem-root .MuiSvgIcon-root {
  font-size: 14px;
  color: black;
}
.menu-dropdown .MuiMenuItem-root .MuiSvgIcon-root {
  font-size: 20px;
}

.btn-delete-page {
  border: transparent;
  color: black;
}
.btn-delete-page:hover {
  background-color: #d32f2f !important;
  color: white;
}

.btn-add-page {
  border: transparent;
  width: 100% !important;
}
.btn-add-page:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.btn-delete-page, .btn-add-page {
  padding: 10px 5px !important;
  font-size: 0.7em;
}

.property-bar-container {
  background-color: #ededed;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  position: fixed;
  left: 20px !important;
  top: 220px !important;
  cursor: move;
  z-index: 2;
}
.property-bar-container .MuiGrid-item {
  min-width: 250px;
}
.property-bar-container .MuiGrid-item.double-width-grid-item {
  min-width: 500px;
}
.property-bar-container.etiqueta-editor-pages {
  width: 350px;
  right: 20px !important;
  top: 200px !important;
  left: unset !important;
}
.property-bar-container.etiqueta-editor-pages .btn {
  padding: 7px 25px;
  width: 90%;
}
.property-bar-container.etiqueta-editor-pages .MuiGrid-item {
  min-width: unset;
  display: flex;
  justify-content: center;
}
.property-bar-container.etiqueta-editor-pages .etiqueta-editor-pages-container .etiqueta-editor-pages-info-container {
  display: flex;
  flex-direction: row;
  padding: 5px 20px;
  background-color: rgb(146, 146, 146);
  color: white;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 5px;
}
.property-bar-container.etiqueta-editor-pages .etiqueta-editor-pages-container .etiqueta-editor-pages-info-container .cursor-button {
  color: white;
}
.property-bar-container.etiqueta-editor-pages .etiqueta-editor-pages-container .etiqueta-editor-pages-preview-container {
  height: 35vh;
  width: 100%;
}
.property-bar-container.etiqueta-editor-pages .etiqueta-editor-pages-container .canvas-container {
  background-color: white;
}

.property-bar-title {
  background-color: #595959;
  color: white;
  text-align: center;
  padding: 12px 0px;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
}
.property-bar-title .icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.property-bar-title .icon.left {
  left: 20px;
}
.property-bar-title .icon.right {
  right: 20px;
}

.etiqueta-editor-pages .property-bar-title {
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
  align-items: center;
}
.etiqueta-editor-pages .property-bar-title button {
  border: none;
  background: none;
  color: white;
}
.etiqueta-editor-pages .property-bar-title .icon {
  cursor: pointer;
}
.etiqueta-editor-pages .etiqueta-editor-pages-page-switcher-container {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
}
.etiqueta-editor-pages .etiqueta-editor-pages-page-switcher-container button {
  border: none;
  background: none;
}
.etiqueta-editor-pages .etiqueta-editor-pages-page-switcher-container .etiqueta-editor-pages-inputs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.etiqueta-editor-pages .etiqueta-editor-pages-page-switcher-container .etiqueta-editor-pages-inputs .MuiFilledInput-input {
  padding: 9px 12px;
}
.etiqueta-editor-pages .etiqueta-editor-pages-page-switcher-container .etiqueta-editor-pages-inputs.add-remove-page-container {
  width: 100%;
  justify-content: space-between;
}

.property-bar-text-itens {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  margin-bottom: 10px;
  min-height: 41px;
  font-size: 0.9em;
}
.property-bar-text-itens .MuiFilledInput-input {
  padding: 9px 12px;
  font-size: 0.9em;
}

.property-bar-text-color-input {
  width: 70px;
  height: 40px;
  cursor: pointer;
  border: 0px;
  background-color: white;
}

.property-bar-text-options-label {
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
}

.property-bar-text-options-container {
  display: flex;
  justify-content: space-evenly;
  height: 41px;
  margin-bottom: 10px;
}
.property-bar-text-options-container.text-options-align-left {
  justify-content: flex-start;
}

.property-bar-text-options-button {
  padding: 5px;
}
.property-bar-text-options-button.active {
  background-color: #ffcb05;
  cursor: pointer;
}
.property-bar-text-options-button.active:hover {
  background-color: #d5aa05;
}
.property-bar-text-options-button:hover {
  background-color: #ffeb9b;
}

.property-bar-text-options-label {
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
}

.MuiSlider-root {
  color: #ffcb05;
}

.MuiSlider-thumb {
  border: 2px solid #d5aa05;
  width: 24px;
  height: 24px;
  color: white;
}

.editor-shapes-menu-images {
  width: 50px;
  height: 50px;
}

.editor-shapes-grid-item {
  display: flex;
  justify-content: center;
}

.editor-shapes-grid-item .MuiMenuItem-root.MuiMenuItem-gutters:focus {
  background-color: #fff4cc;
}

.editor-numeracao-input-font {
  font-size: 16px;
  font-weight: 300;
}

.editor-numeracao-fields-container .MuiOutlinedInput-root.MuiInputBase-root .MuiOutlinedInput-input {
  padding: 10px 0px;
}

.editor-tb-menu .MuiPaper-root {
  background-color: white;
}

.menu-dropdown {
  width: 300px;
}

.editor-tb-menu .menu-dropdown:before {
  content: "";
  display: block;
  position: absolute;
  top: -36px;
  left: 24px;
  border: 28px solid white;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  z-index: 0;
  transform: rotateX(70deg);
}

.editor-tb-menu-item {
  cursor: default;
}

.editor-tb-menu-item:hover {
  background-color: white !important;
}

.editor-tb-button-div {
  display: flex;
  justify-content: flex-end;
}

.editor-tb-button {
  background-color: #1f6a4b;
  color: white;
  font-size: 11px;
  padding: 10px 15px;
  cursor: pointer;
  border: 0px;
  border-radius: 5px;
}

.orientation-portrait-icon {
  transform: rotate(90deg);
}

.tb-main-grid {
  padding: 10px;
}
.tb-main-grid .qr-bar-code-error-text {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  cursor: auto;
  padding: 0 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.barcode-valor-input span {
  width: 30%;
}
.barcode-valor-input .value-input {
  width: 65%;
}

.barcode-legenda-select {
  width: 70%;
}

.input-main-width {
  width: 35%;
}

.tb-clickable-itens {
  cursor: pointer;
  color: black;
}

.curved-text-kerning-span {
  margin-right: 15px;
}

.shape-item-container {
  width: 65px;
  height: 50px;
}

.statusbar-container {
  position: fixed;
  display: flex;
  justify-content: right;
  align-items: center;
  background-color: #ededed !important;
  padding: 4px;
  border-radius: 50px;
}
.statusbar-container.drag-container {
  bottom: 15px;
  right: 50%;
  transform: translateX(50%);
}
.statusbar-container.info-container {
  bottom: 15px;
  left: 12px;
}
.statusbar-container .statusbar-zoom {
  display: flex;
  align-items: center;
  padding: 5px 8px;
}
.statusbar-container .statusbar-zoom-buttons {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
  margin-left: 5px;
}
.statusbar-container .statusbar-zoom-buttons button {
  padding: 5px;
}
.statusbar-container span {
  padding: 8px;
}
.statusbar-container .cursor-button {
  color: #b0afaa !important;
}
.statusbar-container .cursor-button.active {
  color: #d5aa05 !important;
  background-color: rgba(213, 170, 5, 0.075);
}

.dados-etiqueta-print-modal {
  text-align: center;
}

.editor-container {
  flex: 1 !important;
  position: relative;
}
.editor-container .editor-components {
  position: absolute;
  top: 8px;
  left: 0;
  z-index: 1;
}
.editor-container .toolbar-container {
  display: flex;
  align-items: flex-start;
  margin-left: 5px;
  margin-top: 5px;
  z-index: 2;
  position: absolute;
  left: 0;
  transition: left 0.5s;
}
.editor-container .toolbar-container .MuiIconButton-root {
  background-color: #f2f2f2;
  border-radius: 5px;
  margin-left: 5px;
  transition: transform 0.5s;
}
.editor-container .toolbar-container .toolbar-accordion-container {
  width: 250px;
}
.editor-container .toolbar-container.hidden {
  left: -250px;
}
.editor-container .toolbar-container.hidden .MuiIconButton-root {
  transform: rotate(180deg);
}
.editor-container .toolbar-container .search-field-faq {
  margin-bottom: 10px;
}
.editor-container .toolbar-container .search-field-faq .pimaco-icons {
  width: 1em;
  height: 1em;
}
.editor-container .editor-tb-button-container {
  display: flex;
  justify-content: center;
  margin: 5px 0;
  position: sticky;
  bottom: 0;
  background: #f8f8f8;
}

.etiqueta-paginator-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 25px;
  z-index: 2;
  display: flex;
  align-items: center;
}
.etiqueta-paginator-container button {
  border: none;
  background: none;
}
.etiqueta-paginator-container .etiqueta-paginator-arrow {
  color: #f2f2f2;
  padding: 5px;
}
.etiqueta-paginator-container .etiqueta-paginator-arrow.active-button {
  color: #ffcb05;
  background-color: #f2f2f2;
  border-radius: 50%;
  cursor: pointer;
}
.etiqueta-paginator-container span.etiqueta-paginator-text {
  padding: 0 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
}
.etiqueta-paginator-container span.etiqueta-paginator-text .etiqueta-paginator-current-page {
  color: #ffcb05;
}

.etiqueta-editor-pages-value {
  width: 65%;
}

.main-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr 40px;
  grid-template-areas: "editor-header" "editor-content" "editor-footer";
  height: 100vh;
}
.main-container .editor-header {
  grid-area: editor-header;
  height: 60px;
  max-height: 60px;
}
.main-container .editor-content {
  grid-area: editor-content;
  display: flex;
  flex-direction: column;
}
.main-container .editor-content .MuiFilledInput-input {
  padding: 9px 12px;
}
.main-container .editor-footer {
  grid-area: editor-footer;
}

.editor-menu {
  background-color: #f2f2f2;
  padding: 8px 0;
  display: flex;
}

.canvas-container {
  width: 100% !important;
}

.MuiBox-root, .MuiTableContainer-root, .accordion__panel {
  /* Handle */
  /* Handle on hover */
}
.MuiBox-root::-webkit-scrollbar, .MuiTableContainer-root::-webkit-scrollbar, .accordion__panel::-webkit-scrollbar {
  width: 10px;
}
.MuiBox-root::-webkit-scrollbar-track, .MuiTableContainer-root::-webkit-scrollbar-track, .accordion__panel::-webkit-scrollbar-track {
  background: transparent;
}
.MuiBox-root::-webkit-scrollbar-thumb, .MuiTableContainer-root::-webkit-scrollbar-thumb, .accordion__panel::-webkit-scrollbar-thumb {
  background: #ebebe3;
  width: 5px;
  border-radius: 10px;
}
.MuiBox-root::-webkit-scrollbar-thumb:hover, .MuiTableContainer-root::-webkit-scrollbar-thumb:hover, .accordion__panel::-webkit-scrollbar-thumb:hover {
  background: #dbdbd3;
}

.accordion {
  width: 100%;
  border: none;
}
.accordion .accordion__item {
  margin-bottom: 5px;
  outline: 1px solid #bbbbbb;
}

.modal-alert-strong {
  font-weight: bold;
  color: #d32f2f;
}

.accordion__heading {
  padding: 15px 10px;
  background-color: #f8f8f8;
}
.accordion__heading .accordion__button {
  padding: 0;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  text-transform: uppercase;
}
.accordion__heading .accordion__button svg {
  height: 1.2em;
  width: 2em;
}
.accordion__heading .accordion__button:hover {
  background-color: transparent;
}
.accordion__heading .accordion__button:before {
  display: none;
}
.accordion__heading .accordion__button[aria-expanded=true] .pimaco-icons,
.accordion__heading .accordion__button[aria-selected=true] .pimaco-icons {
  transform: rotate(180deg);
}

.menu-accordion-produtos .accordion__heading {
  padding: 15px 30px;
}

.editor-menu-categoria-selector-container {
  /* Handle */
  /* Handle on hover */
}
.editor-menu-categoria-selector-container .accordion {
  margin-top: 1px;
}
.editor-menu-categoria-selector-container .accordion__button span.active {
  font-weight: 600;
}
.editor-menu-categoria-selector-container .accordion__item {
  margin-right: 1px;
}
.editor-menu-categoria-selector-container::-webkit-scrollbar {
  width: 10px;
}
.editor-menu-categoria-selector-container::-webkit-scrollbar-track {
  background: transparent;
}
.editor-menu-categoria-selector-container::-webkit-scrollbar-thumb {
  background: #ebebe3;
  width: 5px;
  border-radius: 10px;
}
.editor-menu-categoria-selector-container::-webkit-scrollbar-thumb:hover {
  background: #dbdbd3;
}

.accordion__panel {
  padding: 15px 10px;
  background-color: #f8f8f8;
  max-height: 30vh;
  overflow-y: scroll;
}
.accordion__panel ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}
.accordion__panel ul li {
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  padding-left: 15px;
  cursor: pointer;
}
.accordion__panel ul li:hover, .accordion__panel ul li.active {
  background-color: #e6e6e6;
  font-weight: 600;
}
.accordion__panel .btn {
  padding: 10px 20px;
  width: 100%;
}
.accordion__panel label {
  text-align: center;
}
.accordion__panel .accordion__panel-filtros {
  padding: 0 0 10px;
}
.accordion__panel .accordion__panel-filtros ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}
.accordion__panel .accordion__panel-filtros ul .menu-produtos-subcategoria-item {
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  padding-left: 50px;
  text-transform: uppercase;
}

.accordion__item + .accordion__item {
  border-top: none !important;
}

.menu-accordion-produtos .accordion__item + .accordion__item {
  border-top: none;
}

ul.ul-navbar-mobile-itens {
  list-style: none;
  padding: 0 30px;
  margin: 0;
}
ul.ul-navbar-mobile-itens li {
  padding: 15px 0;
}

.accordion-menu-btn-container {
  margin: 15px 0;
}

.extra-bottom-margin {
  margin-bottom: 30px;
}

.editor-request-access-endereco-adornment {
  position: absolute;
  top: 15%;
}
.editor-request-access-endereco-adornment .MuiTypography-root {
  color: #1c1c1b;
  font-weight: 700;
}

.produto-info-tooltip {
  background-color: white;
  color: #202020;
  font-family: "Manrope";
  font-size: 16px;
}

.canvas-preview {
  height: auto;
}/*# sourceMappingURL=main.css.map */