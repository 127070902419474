h1.content-header-ferramenta-busca {
    position: absolute;
    top: 20%;
    width: 20px;
    font-weight: 600;
    color: white;
    font-size: 3.5em;
    left: 60%;
}


.ferramenta-busca-header-image {
    object-position: 0% 0;
}

@media screen and (max-width: 900px) {
    h1.content-header-ferramenta-busca {
        right: 40%;
        font-size: 1.5em;
    }
}

@media screen and (max-width: 600px) {
    h1.content-header-ferramenta-busca {
        top: 25%;
        right: 150px;
        font-size: 1.2em;
    }
}