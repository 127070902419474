.dicas-uso-produtos-container {
    width: 90%;
    margin: 0 auto;
    max-width: 1400px;
    padding: 40px 0;

    h1 {
        font-weight: bold;
    }

    .dicas-uso-produtos-feed-container {
        width: 100%;
        height: 650px;
        //background-color: $thertiary-grey;
        overflow-y: scroll;
        padding: 15px;
        //box-sizing: border-box;
        padding-left: 0;

        .dicas-uso-produtos-item {
            width: 100%;
            height: 100%;
            max-height: 400px;
            position: relative;

            .dicas-uso-produtos-item-text-container {
                position: absolute;
                width: 100%;
                height: 100%;
                visibility: hidden;
                display: flex;
                flex-direction: column;
                transition: all $shortest-transition-time;
                opacity: 80%;
                color: $secondary-black;

                .dicas-uso-produtos-item-logo-container {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    height: 20%;

                    .footer-social-logo {
                        width: 2em;
                        height: 2em;
                        padding: 10px;
                    }
                }

                p {
                    height: 80%;
                    text-overflow: ellipsis;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-end;
                    padding: 10px;
                    margin: 0;
                    font-size: 1.5em;
                    word-break: break-word;
                    overflow: hidden;
                    visibility: hidden;
                    transition: all 0s;
                }


            }

            &:hover {
    
                .dicas-uso-produtos-item-text-container {
                    background-color: gold;
                    visibility: visible;
                    transition: all $shortest-transition-time;

                    p {
                        visibility: visible;
                        transition: all 0s;
                    }
                }
            }

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: $thertiary-grey;
            
          }
          
          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: $secondary-black;
            width: 5px;
            border-radius: 10px;
          }
          
          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: $primary-black;
          }
    }

    .page-error-container {
        flex-direction: column;
        height: 100%;

        h3 {
            margin: 10px
        }

        p {
            margin: 5px;
        }
    }
}