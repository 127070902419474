.escolha-melhor-etiqueta-container {
    background-color: $primary-yellow;
    display: flex;
    height: 650px;

    .escolha-melhor-etiqueta-content {
        width: $website-width;
        display: flex;
        flex-direction: row;
        max-width: 1400px;
        margin: 0 auto;
        height: 650px;

        .escolha-melhor-etiqueta-text-container {
            width: 50%;
            margin: auto;

            h1 {
                font-size: 3.5em;
                font-weight: bold;
            }
        }

        .escolha-melhor-etiqueta-image-container {
            width: 50%;

            img {
                object-fit: cover;
                height: 100%;
                width: -webkit-fill-available;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .escolha-melhor-etiqueta-container {
        background-color: $primary-yellow;
        display: flex;
        height: auto;
    
        .escolha-melhor-etiqueta-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            max-width: 1400px;
            height: auto;
    
            .escolha-melhor-etiqueta-text-container {
                width: $website-width;
                padding-right: 20%;
                text-align: center;
                padding: 30px 20px;
                margin: 0 auto;

                h1 {
                    font-size: 2em;
                }
            }
    
            .escolha-melhor-etiqueta-image-container {
                width: 100%;
    
                img {
                    object-fit: cover;
                    height: -webkit-fill-available;
                    width: -webkit-fill-available;
                }
            }
        }
    }
}