
.MuiAccordionSummary-content {
    margin: 24px 12px;

    &.Mui-expanded {
        margin: 24px 12px;
    }
}

.accordion-faq-duvidas {
    margin: 15px 0;
    background-color: $thertiary-grey;
    font-family: 'Manrope';
    font-weight: 500;

    h2 {
        margin: 0;
    }
}

.accordion-faq-duvidas:before {
    background-color: transparent;
}

.accordion-faq-duvidas-details {
    background-color: white;
    font-family: 'Manrope';
    font-weight: 500;
    padding: 20px 28px;
    white-space: pre-wrap;
    line-height: 150%;
}

.MuiPaper-root-MuiAccordion-root:first-of-type {
    border-radius: 0 !important;
}

.search-field-faq {
    display: flex;
    margin: 30px auto;
    width: 70%;

    .pimaco-icons {
        height: 2em;
        width: 2em;
    }

    .MuiFilledInput-root {
        align-items: center;
    }
}


.faq-category-chooser-container {
    background-color: $primary-yellow;
    width: $website-width;
    margin: 0 auto;
    max-width: 1400px;

    .grid-faq-category-chooser {
        padding: 40px;
    }
}

.faq-category-chooser-item-container {
    background-color: white;
    padding: 5px 15px;
    margin: 10px;
    text-align: center;
    height: 8em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    outline: 2px solid black;
    cursor: pointer;
    transition: all 0.5s;

    h4 {
        text-transform: uppercase;
    }

    &.active-faq-category-chooser-item {
        background-color: transparent;
        transition: all 0.5s;
    }
}

h1.content-header-faq {
    position: absolute;
    top: 25%;
    left: 20%;
    font-size: 3.5em;
    width: 25%;
    font-weight: 600;
}

@media screen and (max-width:900px) {

    h1.content-header-faq {
        top: 0;
        left: 5%;        
        width: 20%;
        font-size: 2em;

    }
}

@media screen and (max-width:600px) {

    .faq-category-chooser-container {
        padding: 40px 0;
        width: 100%;

        p {
            display: none;
        }
    }
}