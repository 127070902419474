.visite-duvidas-container {
    display: flex;
    width: $website-width;
    max-width: 1400px;
    margin: 100px auto;
    justify-content: center;

    .visite-duvidas-image-container {

        img {
            width: 350px;
        }
    }

    .visite-duvidas-text-container {
        width: 45%;

        h1 {
            font-size: 3em;
            margin: 0;
            margin-left: -50px;
            font-weight: bold;
        }

        .btn, p {
            margin-left: 30px;
            margin-top: 30px;
        }
    }

    &.visite-duvidas-como-ler-etiquetas {
        position: relative;
        background-color: $primary-yellow;
        margin: 0;
        padding: 250px 0 150px;
        max-width: none;
        width: 100%;

        .visite-duvidas-text-container {
            width: 30%;
        }

        .shape-divider-top-visite-duvidas-frequentes-como-ler-etiquetas {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            overflow: hidden;
            line-height: 0;
        }
        
        .shape-divider-top-visite-duvidas-frequentes-como-ler-etiquetas svg {
            position: relative;
            display: block;
            width: calc(157% + 1.3px);
            height: 150px;
        }
        
        .shape-divider-top-visite-duvidas-frequentes-como-ler-etiquetas .shape-fill {
            fill: #FFFFFF;
        }

    }
}

@media screen and (max-width:1200px) {
    .visite-duvidas-container {
    
        .visite-duvidas-image-container {
            img {
                width: 300px;
            }
        }
    
        .visite-duvidas-text-container {
            width: 45%;
    
            h1 {
                font-size: 2.3em;
            }
    
        }
    }
}

@media screen and (max-width:900px) {
    .visite-duvidas-container {
        width: 100%;
        margin: 35px 0;
        padding: 80px 0;
        background-color: $secondary-grey;
    
        .visite-duvidas-image-container {
            width: 45%;
            margin-left: 5%;
            img {
                width: 100%;
            }
        }
    
        .visite-duvidas-text-container {
            width: auto;
            margin-right: 5%;
    
            h1 {
                font-size: 2.5em;
                margin-left: 30px;
            }
    
        }

        &.visite-duvidas-como-ler-etiquetas {
    
            .visite-duvidas-text-container {
                width: auto;
            }
    
        }
    }
}

@media screen and (max-width:600px) {
    .visite-duvidas-container {
        width: 100%;
        margin: 35px 0;
        padding: 80px 0;
        background-color: $secondary-grey;
    
        .visite-duvidas-image-container {
            width: 80%;
            img {
                width: 100%;
            }
        }
    
        .visite-duvidas-text-container {
            width: auto;
    
            h1 {
                font-size: 1.5em;
                margin-left: 30px;
            }
    
        }
    }
}