
.editor-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
        height: 95%;
        padding: 5px;

    }

    .btn {
        padding-left: 24px;
        padding-right: 24px;
        color: $thertiary-yellow;
        margin-left: 12px;
        text-transform: initial;

        &.btn-header-editor {
            .MuiButton-startIcon {
                margin-right: 0;
                margin-left: 0;
            }
        }
    }

    .editor-stepper-container {
        display: flex;
        text-align: center;

        .editor-stepper-item {
            width: 250px;
            background-color: #ebebeb;
            margin: 0 5px;
            cursor: pointer;

            strong {
                font-family: 'Stara';
            }

            &.editor-active-step {
                background-color: #FFCB05;
            }
        }
    }
}

.MuiMenuItem-root {
    .btn-link {
        display: flex;
        align-items: center;

        &:link, &:visited, &:hover, &:active {
            color: $secondary-black
        }
    }
}