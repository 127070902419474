.MuiFilledInput-root {
    background-color: white;
    outline: 1px solid black;
    font-family: 'Manrope';
    border-radius: 0;

    &:hover {
        background-color: rgba(255, 255, 255, 0.9);
    }

    &.Mui-disabled {
        &:hover {
            background-color: rgba(0, 0, 0, 0.12);
        }
    }

    &.Mui-focused {
        background-color: white;
    }

    &:after, &::before {
        display: none;
    }
}

.MuiFilledInput-input {
    padding: 18px 12px;
    font-weight: 500;
}

.MuiAutocomplete-popper, .MuiAutocomplete-listbox {
    font-family: 'Manrope';
}

.MuiAutocomplete-groupLabel {
    font-family: 'Manrope';
    font-weight: 800;
    font-size: 1rem;
}

.MuiAutocomplete-inputRoot {
    padding-top: 9px !important;
    padding-bottom: 9px;
}

.MuiFormControlLabel-root {
    .MuiSvgIcon-root {
        background-color: white;
        color: transparent;
        width: 0.9em;
        height: 0.9em;
    }

    .MuiCheckbox-root.Mui-checked .MuiSvgIcon-root {
        color: $secondary-black;
    }
}

.MuiMenuItem-root {
    font-family: 'Manrope';
    font-weight: 500;
}

.MuiInputLabel-root, .MuiFormControlLabel-root, .MuiTypography-root {
    font-family: 'Manrope';
    color: $secondary-black;
    font-weight: 600;
}

.MuiInputAdornment-root {
    color: black;
    font-weight: 600;
    margin-top: 0 !important;

    &.MuiInputAdornment-positionEnd {
        padding: 0 10px;
    }
}

.MuiFilledInput-root.number-input {
    .MuiFilledInput-input {
        padding-left: 0;

    }

    .MuiInputAdornment-root {
        .MuiTypography-root {
            font-size: 0.9em;
            color: rgba(0, 0, 0, 0.7);
            font-weight: 500;
        }
    }
}


.MuiFilledInput-root.Mui-error {
    outline: 3px solid red;
}

@media screen and (max-width: 900px) {
    .MuiTextField-root {
        width: 100%;
    }
}