.editor-menu {

    .editor-menu-pesquisa-template {
        max-width: 1000px;
        margin: 0 auto;
        width: 50%;
    }

    .search-field-faq {

        svg {
            width: 2em;
            height: 2em;
        }
    }

    .editor-menu-filters {
        width: 90%;
        margin: 0 25px;

        &>:first-child {
            padding-left: 0;
        }
    }

    .editor-menu-filters-spacing {
        width: 15%;
        min-width: 300px;
    }

}

.dialog-preview-template {
    overflow-x: hidden;
}

.editor-menu-template-selection {
    display: flex;
    flex-direction: row;
    height: 85vh;

    .MuiBox-root {
        width: 65%;
        overflow-y: scroll;
        margin: 0 25px;

        .MuiGrid-container {
            padding-right: 32px;

            
        }
        
    }

    .MuiFormGroup-root {
        margin-right: 1px;
        margin-top: 1px;
    }

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        
      }
      
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $thertiary-grey;
        width: 5px;
        border-radius: 10px;
      }
      
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #dbdbd3;
      }
}

.editor-menu-prod-selecionado-info-container {
    width: 25%;
    max-width: 300px;

    &.tooltip {
        width: 100%;
    }

    .editor-menu-prod-selecionado-content {
        display: flex;
        width: 100%;
        padding-top: 32px;

        img {
            width: 50%;
            max-height: 300px;
            object-fit: scale-down;
        }

        .editor-menu-prod-selecionado-data {
            padding: 10px;
            width: 50%;
            word-wrap: break-word;
            box-sizing: border-box;
        }
    }
}

.editor-menu-template-item {
    width: 100%;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    cursor: pointer;
    position: relative;
    border: 1px solid #dddddd;
    border-radius: 10px;

    .editor-menu-template-item-buttons-admin {
        opacity: 0;
        cursor: none;
        pointer-events: none;
        transition: 0.25s;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 2;
        background-color: white;
        border-radius: 10px;
        padding: 2px;
    }

    &:hover {
        .editor-menu-template-item-buttons-admin {
            opacity: 100%;
            cursor: initial;
            pointer-events: initial;
            transition: 0.25s;
            
        }
    }

    .editor-menu-template-item-data-container {
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: #ffefb4;
        display: flex;
        width: 100%;
        padding: 10px 0;
        box-sizing: border-box;
        padding-left: 10px;
        margin-top: 10px;
        border-radius: 10px;
        justify-content: space-between;
        align-items: center;
        z-index: 1;

        p {
            margin: 0;
        }
    }
    
    // canvas, img {
    //     background-color: blue;
    //     max-width: 100%;
    //     height: 100%;
    //     object-fit: contain;
    // }
}