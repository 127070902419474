.whatsapp-floating-button {
    position: fixed;
    right: 0;
    top: 50%;
}

.btn-link {
    text-decoration: none;

    &.download-text {
        font-weight: bold;

        &:hover, &:link, &:visited, &:active {
            color: inherit;
        }
    }
}

.btn {
    padding: 10px 50px;
    border-radius: 0;
    font-family: 'Stara';
    font-weight: bolder;
}

@media screen and (max-width: 600px) {
    .btn {
        padding: 10px 25px;
    }
}

.btn-contained-primary {
    background-color: $secondary-black;
    border: 2px solid $secondary-black;

    &:hover {
        background-color: $primary-black;
    }
}

.btn-contained-secondary {
    background-color: white;
    color: $secondary-black;
    border: 2px solid $secondary-black;
    transition: all 0.5s;
    
    &:hover {
        background-color: $secondary-black;
        color: white;
        transition: all 0.5s;

    }


}

.btn-contained-danger {
    background-color: #D32F2F;
    color: white;
    transition: all 0.5s;

    :not(.Mui-disabled) {
        border: 2px solid #D32F2F;
    }

    &:hover {
        background-color: #A82424;
        color: white;
        transition: all 0.5s;
    }
}

.btn-contained-thertiary {
    background-color: $primary-yellow;
    color: $primary-black;
    border: 2px solid $primary-yellow;
    transition: all 0.5s;

    &:hover {
        background-color: $secondary-yellow;
        color: $primary-black;
        transition: all 0.5s;
    }
}

.btn-outlined-primary {
    background-color: transparent;
    border: 2px solid $primary-black;
    color: $primary-black;

    &:hover {
        border: 2px solid $primary-black;
    }
}

.button-slider-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 35px !important;
    flex-direction: row;
    -webkit-user-select: none;
    user-select: none;
}


.btn-slider-container {
    border-bottom: 4px solid;
    width: 170px;
    display: flex;
    cursor: pointer;
    
    &.btn-slider-next {
        justify-content: flex-end;
        border-color: white;
    }

    &.btn-slider-prev {
        justify-content: flex-start;
        border-color: black;

    }


    p {
        margin-bottom: 2px;
        color: black;
    }
}

.white-bg-btn {
    .btn-slider-container.btn-slider-next {
        border-color: $thertiary-grey;
    }
}