@import './gabaritos';
@import './parametros';

div.downloads-paragraph {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 50px;
}

.pimaco-download-box-container {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px auto;
    justify-content: space-evenly;
}

div.downloads-group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    
    transition: all $shortest-transition-time;

    .pimaco-download-box-container {
        background-color: $secondary-grey;
        transition: all $shortest-transition-time;
    }

    &>:nth-child(odd).pimaco-download-box-container {
        background-color: $thertiary-grey;
        transition: all $shortest-transition-time;
    }

    .pimaco-download-box-container:hover {
        background-color: $primary-yellow;
        color: white;
        transition: background-color $shortest-transition-time;
    
        svg {
            fill: white;
            transition: all $shortest-transition-time;
        }

        .pimaco-download-box-info-container {
            background-color: goldenrod;
            transition: all $shortest-transition-time;
        }

        .btn {
            border-color: white;
            color: white;
        }
    
    }

}

.pimaco-download-box-info-container {
    background-color: lightgray;
    width: 100%;
    display: flex;
    align-items: flex-end;
}

div.pimaco-download-box-text-container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    justify-content: space-between ;
    padding: 10px ;
    width: 100%;
}

p.pimaco-download-box-titulo, p.pimaco-download-box-code {
    text-align: center;
    margin: 0;
    padding-bottom: 15px;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.saiba-mais-downloads-container {
    background-color: $primary-yellow;
    text-align: center;
    padding: 15px;
}

// ASSISTENTE DE IMPRESSÃO
.assistente-impressao-page-content {
    text-align: center;
    padding: 50px 0;

    .destaque-header-title {
        font-weight: 800;
        text-transform: uppercase;
        color: $primary-yellow;
    }

}

.assistente-impressao-page-content-button-tutorial {
    display: flex;
    margin: 40px 0;
    align-items: center;
    justify-content: center;
}

.assistente-impressao-page-content-new-assistant-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    //height: 70vh;

    .assistente-impressao-page-content-image {
        width: 50%;
        height: 100%;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    .assistente-impressao-page-content-text {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 40px;
        box-sizing: border-box;
        width: 50%;

        .MuiGrid-item {
            display: flex;
            align-items: center;
        }

        .destaques-assistente-impressao {
            display: flex;
            flex-direction: row;
            align-items: center;

            .icon {
                color: $primary-yellow;
            }

            span {
                font-size: 1.7em;
                text-align: left;
            }
        }

        .accesse-container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .text-center {
                text-align: center;
                margin: 0 auto;
            }
    
            .button-container {
                display: flex;
                flex-direction: row;
                gap: 20px;
            }
        }

        

        .extra-info {
            color: $secondary-black;
            font-size: 0.9em;
        }
    }
}

.assistente-impressao-checkbox-container {
    text-align: right;

    .MuiSvgIcon-root {
        border: 1px solid $primary-black;
    }
    
}

@media screen and (max-width: 600px) {

    div.downloads-group {
        justify-content: space-between;
    }

}

@media screen and (max-width: 900px) {

    .assistente-impressao-page-content-new-assistant-container {
        flex-direction: column;

        .assistente-impressao-page-content-image {
            height: 400px;
            width: 100%;
            object-position: top;
        }

        .assistente-impressao-page-content-text {
            width: 100%;

            .text-center {
                display: none;
            }

            .button-container {
                display: none;
            }

            .extra-info {
                margin-bottom: 15px;
            }
        }
    }
}




@media screen and (max-width: 1200px) {

    .assistente-impressao-buttons-container {
        display: flex;
        max-width: 1400px;
        width: 90%;
        margin: 20px auto;
        justify-content: center;
    }

}


@media screen and (min-width: 1200px) {

    .pimaco-download-box-container {     
        margin: 10px;
    }

    .assistente-impressao-buttons-container {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -100%);
        display: flex;
        justify-content: space-evenly;
        gap: 20px;
        flex-direction: column;
        top: 50%;
        width: 90%;
        max-width: 1400px;
        margin: 0 auto;
        align-items: flex-end;

        button {
            width: 300px;
        }
    }

}
