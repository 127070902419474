@import '../common/_common.scss';
@import './assistente/assistente';
@import './assistente/ferramentaBusca';
@import './downloads/_downloads.scss';
@import './empresa/empresa';
@import './faq/_faq.scss';
@import './forms/forms';
@import './forms/trabalheConosco';
@import './header-footer/_header.scss';
@import './header-footer/_footer.scss';
@import "./landing_page/_landingPagePimaco.scss";
@import './leitura-etiquetas/_leitura-etiquetas';
@import "./produto/_produto.scss";

body {
    margin: 0;
    font-family: 'Manrope';
    background-color: white;
    font-weight: 500;

    h1, h2 {
        font-family: 'Stara';
    }
}

.page-content {
    max-width: 1400px;
    width: $website-width;
    margin: 0 auto;

    h1, h2 {
        font-family: 'Stara';
    }

    h2 {
        font-weight: 600;
        font-size: 1.8em;
    }

    &.empresa-content {
        overflow-wrap: break-word;
        margin: 40px auto;

        span.pilares-empresa-content p.page-paragraph {
            margin: 0.5em 0;
        }
    }
}

.page-error-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay-active {
    background: white;
    filter: brightness(0.5);
    pointer-events: none;

}

.whatsapp-box-container {
    position: fixed;
    right: 4px;
    bottom: 75px;
    width: 450px;
    display: none;
    flex-direction: column;
    z-index: 5;

    &.show {
        display: flex;
    }

    h1,h1-form-title {
        margin-bottom: 10px;
        margin-top: 10px;
    }

    h3.h3-form-subtitle {
        margin-top: 0;
        margin-bottom: 15px;
    }

    h1 {
        font-size: 1.7em;
    }

    .MuiFilledInput-input {
        padding: 9px 12px;
    }

    .whatsapp-header-container {
        background-color: goldenrod;
        width: 100%;
        box-sizing: border-box;
        padding: 0 30px;
        border-radius: 5px 5px 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .pimaco-icons {
            width: 1.5em;
            height: 1.5em;
            cursor: pointer;
        }
    }

    .whatsapp-chat-container {
        width: 100%;
        background-color: $primary-yellow;
        box-sizing: border-box;
        padding: 20px;
        border-radius: 0 0 5px 5px;

        p {
            margin: 0;
        }
    }

    .btn {
        padding: 5px 50px;
    }
}

@media screen and (max-width: 600px) {
    .overlay-active {
        .container {
            overflow-y: hidden;
        }
    }

    .whatsapp-box-container {
        position: fixed;
        width: 100%;
        height: 100vh;
        overflow-y: scroll;
        top: 0;
        left: 0;
    }

    
}

.pimaco-icons.vetor-impressao {
    .st0{fill:none;stroke:#FFFFFF;stroke-width:3;stroke-miterlimit:10;}
	.st1{fill:#FFFFFF;}
	.st2{font-family:'Montserrat'; }
	.st3{font-size:18px;}
	.st4{font-family:'Montserrat'; font-weight: 600;}
	.st5{font-size:26px;}
	.st6{font-family:'HelveticaNeue-CondensedBold';}
	.st7{font-size:18.8741px;}
	.st8{font-size:15.7178px;}
	.st9{font-size:92.9322px;}
	.st10{font-size:38.8909px;}
	.st11{font-size:26.6443px;}
	.st12{font-size:24.5125px;}
	.st13{font-size:59.2358px;}
	.st14{fill:none;stroke:#FFFFFF;stroke-miterlimit:10;}
	.st15{font-size:45.4368px;}
}