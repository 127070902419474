.conheca-lançamentos-container {
    width: 100%;

    .btn-slider-container.btn-slider-next {
        color: $thertiary-grey;
        border-color: $thertiary-grey;
    }
}

.conheca-lancamentos-slider {
    width: $website-width;
    margin-left: 10%;

    .slick-list {
        padding: 0 100px 0 0 !important;
    }
}

.conheca-lancamentos-content-container {
    position: relative;
    padding: 40px 0;

    h1 {
        width: $website-width;
        margin-left: auto;
    }
}


.conheca-lancamentos-card-container {
    width: 25vw;
    margin: 10px 30px 10px 0;

    &.invisible {
        visibility: hidden;
    }

    .conheca-lancamentos-card-image-description-container {
        position: relative;
        height: 350px;
    
        img {
            width: 100%;
            object-fit: cover;
            height: -webkit-fill-available;
        }
    
        .conheca-lancamentos-card-description-container {
            position: absolute;
            background-color: $primary-yellow;
            top: 100%;
            right: 0;
            width: 100%;
            height: 0;
            transition: top 0.5s, height 0.5s;

            p, button {
                color: transparent;  
                border: transparent;         
            }

            .conheca-lancamentos-card-description-text-container {
                padding: 15px;
            }
        }
    }

    p {
        margin-top: 0;
    }

    &:hover .conheca-lancamentos-card-description-container {
        top: 0;
        height: 100%;

        p {
            color: black;
            transition: color 0.5s;
            transition-delay: 0.25s;
        }

        button {
            background-color: transparent;
            border: 2px solid $primary-black;
            color: $primary-black;
            transition: all 0.5s;
        }
    }

    .conheca-lancamentos-card-info-container {
        background-color: $thertiary-grey;
        transition: background-color 0.5s;
        padding: 15px;
        display: flex;
        align-items: center;
        height: 4em;

        h2 {
            font-family: 'Stara';
            font-weight: 500;
            margin: 0;
            font-size: 19.2px;
            color: $thertiary-black;

        }
    }

    &:hover .conheca-lancamentos-card-info-container {

        h2 {
            font-weight: 500;
            color: white;
            transition: all 0.5s;
        }

        background-color: $thertiary-yellow;
        color: white;
        transition: all 0.5s;
        
    }
}

@media screen and (min-width: 200px) and (max-width: 920px) {
    .conheca-lancamentos-card-container .conheca-lancamentos-card-info-container {
        height: 6.5em;

        h2 {
            font-size: 1.2em;
        }
    }
}

.lancamentos-pimaco-item-container {
    position: relative;
    width: 100%;
    height: 250px;
}

.h1-section-title {
    font-weight: bold;
}

.lancamentos-pimaco-image {
    height: 250px;

    img {
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        object-fit: cover;
    }
}

.conheca-lancamentos-initial-texts {
    text-align: center;
}

.lancamentos-pimaco-image-overlay-details {
    text-align: center;
}

.lancamentos-pimaco-image-overlay {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.25s;
}

.lancamentos-pimaco-image-overlay > * {
    transform: translateY(20px);
    transition: transform 0.25s;
}

.lancamentos-pimaco-image-overlay:hover {
    opacity: 1;
}

.lancamentos-pimaco-image-overlay:hover > * {
    transform: translateY(0);
}

@media screen and (max-width: 900px) {
    .conheca-lancamentos-card-container {
        width: 36vw;
    }
}

@media screen and (max-width: 600px) {
    .conheca-lancamentos-card-container {
        width: 60vw;
    }
}
