.product-list {
    margin: 40px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    .produto-card-container {
        width: 23%;
        margin: 10px 1.33%;
        opacity: 0;
        transform: translate(0, 30%);
        visibility: hidden;
        transition: opacity 300ms ease-out, transform 300ms ease-out;
        will-change: opacity, visibility;

        &.visible {
            opacity: 1;
            transform: none;
            visibility: visible;
        }

        &:nth-of-type(4n+1), &:first-of-type {
            margin-left: 0;
            margin-right: 1.33%;
        }

        &:nth-of-type(4n) {
            margin-left: 1.33%;
            margin-right: 0;
        }
    }
}

.produto-filter-input-label {
    margin-bottom: 5px;
    font-family: 'Manrope';
    color: $secondary-black;
}

.MuiMenuItem-root {
    font-family: 'Manrope';
}

.MuiInputLabel-root {
    font-family: 'Manrope';
    color: $secondary-black;
}

.MuiFormHelperText-root {
    font-family: 'Manrope';
    color: #202020;
}

.produto-filter-filtrar-button {
    height: 56px;
}

.pimaco-product-filter-container {
    background-color: $primary-yellow;
    padding: 10px; 
    margin-top: 20px;

    .form-clear-selection {
        right: 40px;
        display: flex;
        margin-top: 7px;
        position: absolute;
        cursor: pointer;
    }
}

.categoria-header-base-text-container {

    h1 {
        font-size: 3.5em;
        font-weight: bold;
    }
}

@media screen and (max-width: 1200px) {
    .product-list {    
        .produto-card-container {
            width: 30%;
            margin: 10px 1.66%;

            &:nth-of-type(4n+1), &:nth-of-type(4n) {
                margin: 10px 1.66%;
            }
    
            &:nth-of-type(3n+1), &:first-of-type {
                margin-left: 0;
                margin-right: 3.32%;
            }
    
            &:nth-of-type(3n) {
                margin-left: 3.32%;
                margin-right: 0;
            }
        }
    }
    
}

@media screen and (max-width: 900px) {
    .product-list {    
        .produto-card-container {
            width: 45%;
            margin: 10px 0;

            &:nth-of-type(4n+1), &:nth-of-type(4n), &:nth-of-type(3n+1), &:nth-of-type(3n) {
                margin: 10px 0;
            }
    
            &:nth-of-type(2n+1), &:first-of-type {
                margin-left: 0;
                margin-right: 5%;
            }
    
            &:nth-of-type(2n) {
                margin-left: 5%;
                margin-right: 0;
            }
        }
    } 

    .pimaco-product-filter-container {
    
        .MuiFilledInput-input {
            padding: 7px 12px;
        }
    
        .MuiFormHelperText-root {
            line-height: 0;
        }
    }
}

@media screen and (max-width: 600px) {
    .product-list {    
        .produto-card-container {
            width: 100%;
            margin: 10px 0;

            &:nth-of-type(4n+1), &:nth-of-type(4n), &:nth-of-type(3n+1), &:nth-of-type(3n),  &:nth-of-type(2n+1), &:nth-of-type(2n) {
                margin-left: 0;
                margin-right: 0;
            }

        }
    } 
}