.editor-shapes-menu-images {
    width: 50px;
    height: 50px;
}

.editor-shapes-grid-item {
    display: flex;
    justify-content: center;
}

.editor-shapes-grid-item .MuiMenuItem-root.MuiMenuItem-gutters:focus {
    background-color: #fff4cc;
}

.editor-numeracao-input-font {
    font-size: 16px;
    font-weight: 300;
}

.editor-numeracao-fields-container .MuiOutlinedInput-root.MuiInputBase-root .MuiOutlinedInput-input {
    padding: 10px 0px;
}

.editor-tb-menu {

    .MuiPaper-root {
        background-color: white;
    }
}

.menu-dropdown {
    width: 300px;
}

.editor-tb-menu {

    .menu-dropdown:before {
        content: '';
        display: block;
        position: absolute;
        top: -36px;
        left: 24px;
        border: 28px solid white;
        border-top-color: transparent;
        border-right-color: transparent;
        border-left-color: transparent;
        z-index: 0;
        -webkit-transform: rotateX(70deg);
        transform: rotateX(70deg);
    }
}

.editor-tb-menu-item {
    cursor: default;
}

.editor-tb-menu-item:hover {
    background-color:white !important;
}

.editor-tb-button-div {
    display: flex;
    justify-content: flex-end;
}

.editor-tb-button {
    background-color: #1f6a4b;
    color: white;
    font-size: 11px;
    padding: 10px 15px;
    cursor: pointer;
    border: 0px;
    border-radius: 5px;
}

.orientation-portrait-icon {
    transform: rotate(90deg);
}

.tb-main-grid {
    padding: 10px;


    .qr-bar-code-error-text {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        cursor: auto;
        padding: 0 10px;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.barcode-valor-input {
    span {
        width: 30%;
    }
    .value-input {
        width: 65%;
    }
}

.barcode-legenda-select {
    width: 70%;
}

.input-main-width {
    width: 35%;
}

.tb-clickable-itens {
    cursor: pointer;
    color: black;
}

.curved-text-kerning-span {
    margin-right: 15px;
}

.shape-item-container {
    width: 65px;
    height: 50px;
}

.statusbar-container {
    position: fixed;
    
    display: flex;
    justify-content: right;
    align-items: center;
    background-color: $secondary-grey !important;
    padding: 4px;
    border-radius: 50px;

    &.drag-container {
        bottom: 15px;
        right: 50%;
        transform: translateX(50%);
    }

    &.info-container {
        bottom: 15px;
        left: 12px;

    }

    .statusbar-zoom {
        display: flex;
        align-items: center;
        padding: 5px 8px;
    }

    .statusbar-zoom-buttons {
        display: flex;
        flex-direction: column;
        gap: 2px;
        align-items: center;
        margin-left: 5px;

        button {
            padding: 5px;
        }
    }

    span {
        padding: 8px;
    }

    .cursor-button {
        color: $primary-grey !important;

        &.active {
            color: $thertiary-yellow !important;
            background-color: rgba(213, 170, 5, 0.075);
        }
    }

}

.dados-etiqueta-print-modal {
    text-align: center;
}