.login-container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.editor-login {
    width: 50%;
    display: flex;
    overflow-y: auto;

    .MuiFormControlLabel-asterisk {
        display: none;
    }
}

.editor-login-container {
    width: 80%;
    margin: auto;
}

.editor-login-authentication-image {
    text-align: center;
    margin: 15px 0px 35px 0px;

    .editor-login-authentication-image-img {
        width: 25%;
    }
}

.editor-login-authentication {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffcb05;
    padding-bottom: 25px;
    text-align: center;
    margin-top: 20px;

    .MuiGrid-item {
        width: 80%;
    }

    &.modal-grid {
        background-color: transparent;
    }

    .modal-userdata-buttons {
        display: flex;
        justify-content: flex-end;

        button + button {
            margin-left: 10px;
        }
    }
}

.editor-login-authentication-text {
    text-align: center;
    font-weight: 500;
}


.editor-login-authentication-cadastro-fields {
    width: 80%;
}



.editor-login-button {
    text-align: center;
    margin: 5px 0px 20px 0px;
}

.editor-login-paragraphs {
    font-size: 13px;
    text-decoration: underline;
    margin: 0px 0px 10px 0px;
    cursor: pointer;
    color: black;
}
.editor-login-eula {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    
  }


.editor-image {
    width: 50%;
    height: 100vh;
}

.editor-login-image-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom;
}

.editor-login-authentication-fields.editor-request-access-endereco {
    .MuiInputBase-multiline {
        .MuiInputAdornment-root.MuiInputAdornment-disablePointerEvents {
            margin-top: 0px;
        }
    }
}

.editor-login-authentication-fields.editor-request-access-endereco {
    .MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-root {
        textarea.MuiFilledInput-input.MuiInputBase-input {
            padding: 25px 0px 0px 0px;
        }
    }
}

.editor-request-access-endereco-label {
    
    margin-top: 0px;
    position: absolute;
    top: 15%;

    .MuiTypography-root {
        color: black;
        font-weight: 500;
        font-family: 'Manrope';
    }
}

.editor-login-cadastro-checkbox {
    display: flex;
    align-items: center;

    .fc-final-checkbox-label {
        text-align: left;
        display: flex;
        align-items: center;
    }
}

.page-paragraph .autenticacao-texto {
    text-decoration: underline;
    cursor: pointer;
}

.modal-pwa-button {
    display: none;
}