.produto-card-container {
    width: 300px;
    margin: 10px 15px;

    .produto-card-image-description-container {
        position: relative;
        height: 350px;
    
        img {
            width: 100%;
            object-fit: scale-down;
            height: 350px;
        }
    
        .produto-card-description-container {
            position: absolute;
            background-color: $secondary-yellow;
            top: 100%;
            right: 0;
            width: 100%;
            height: 0;
            transition: top 0.5s, height 0.5s;
            visibility: hidden;

            p {
                color: transparent;           
            }

            .produto-card-description-text-container {
                padding: 15px;
            }
        }
    }

    p {
        margin-top: 0;
    }

    &:hover .produto-card-description-container {
        top: 0;
        height: 100%;
        visibility: visible;

        p {
            color: black;
            transition: color 0.5s;
            transition-delay: 0.25s;
        }

    }

    .produto-card-info-container {
        background-color: $thertiary-grey;
        transition: background-color 0.5s;
        padding: 25px;

        p.double-line {
            height: 2em;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        p.triple-line {
            height: 3em;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    &:hover .produto-card-info-container {
        background-color: goldenrod;
        color: white;

        .btn {
            border-color: white;
            color: white;
        }
    }
}
