.editor-menu {

    .editor-menu-filters {
        width: 90%;
        margin: 0 25px;

        .btn {
            margin: 0px 4px;
            //font-size: 11px !important;
            color: $secondary-black;
            font-weight: 500;
            padding: 5px 16px !important;
            border-width: 2px;
            border-style: solid;
            border-radius: 8px;
            border-color: transparent;
            text-transform: initial;
            justify-content: flex-start;
            font-size: 12px;

            &.btn-header-editor {
                .MuiButton-startIcon {
                    margin-right: 0;
                    margin-left: 0;
                }
            }

            .MuiSvgIcon-root {
                font-size: 16px;
            }

            &:disabled {
                opacity: 0.39;
            }

            &:hover {
                background-color: rgb(235, 235, 227);
            }
        }

        &>:first-child {
            padding-left: 0;
        }
    }

    .editor-menu-filters-spacing {
        width: 15%;
        min-width: 300px;
    }

}

.editor-menu-etiqueta-selection {
    display: flex;
    flex-direction: row;
    height: 84vh;
    margin-top: 10px;


    .MuiBox-root {
        display: flex;
        width: 90%;
        margin: 0 25px;
        flex-direction: column;

    }

    .editor-menu-categoria-selector-error {
        font-size: 1.2em;
        margin: 20px;
    }

    img.produto-imagem-etiquetas {
        height: 160px;
        width: auto;
        margin: 0 auto;
    }
}

.MuiTableContainer-root {

    .MuiTableCell-root {
        font-family: 'Manrope';
    }

    .MuiTableCell-head {
        background-color: $primary-yellow;
    }

    .MuiTable-root {

        .MuiTableBody-root {
            

            .MuiTableRow-root {
                border-radius: 5px;
                outline: 1px solid rgb(224, 224, 224);

                .MuiTableCell-root {
                    border: none;
                }
            }

        }

    }
}