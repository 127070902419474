@import './_etiquetas.scss';
@import './_template.scss';

.editor-menu-categoria-selector-container {
    width: 15%;
    min-width: 300px;
    height: 100%;
    background-color: transparent;
    box-sizing: content-box;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;

    .btn {
        padding: 5px 25px;
    }

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        
      }
      
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $thertiary-grey;
        width: 5px;
        border-radius: 10px;
      }
      
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #dbdbd3;
      }


    fieldset.MuiFormControl-root {
        width: 100%;

        .MuiFormControlLabel-root {
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 5px;
            background-color: #f8f8f8;
            margin-top: 0;
            padding: 7px 10px;
            outline: 1px solid #bbbbbb;
            justify-content: space-between;
            width: 100%;
            box-sizing: border-box;

            .MuiSvgIcon-root {
                background-color: transparent;
                color: transparent;
                width: 0.9em;
                height: 0.9em;
            }

            .MuiCheckbox-root.Mui-checked .MuiSvgIcon-root {
                color: $thertiary-yellow;
            }

            .MuiTypography-root {
                font-family: 'Manrope';
            }
        }

    }


    a {
        text-decoration: none;
    }

    a:link, a:visited, a:hover, a:active {
        color: $secondary-black
    }
    
}

.form-clear-selection {
    right: 20px;
    display: flex;
    transform: translateY(50%);
    bottom: 43.5%;
    position: absolute;
    cursor: pointer;
}