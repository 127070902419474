

@media screen and (max-width: 600px) {
    .parametros-container, .gabaritos-container {
        .pimaco-download-box-container {
            width: 39%;
        }
    }
}

@media screen and (max-width: 300px) {
    .parametros-container, .gabaritos-container {
    .pimaco-download-box-container {
        width: 100%;
    }
}
}

.gabaritos-container {
    .pimaco-download-box-container {  
        height: 450px;
        width: 300px;
        position: relative;

        svg {
            padding: 20px 15px;
        }
    }
    
    .pimaco-download-box-info-container {       
        height: 9em;

        .pimaco-download-box-text-container {
            height: 8em;
            justify-content: flex-end;

            p.pimaco-download-box-titulo, p.pimaco-download-box-code {
                height: 4em;
                padding-bottom: 5px;
            }

            p.pimaco-download-box-code {
                font-weight: 600;
                font-size: 1.1em;
            }
        }


    } 
}