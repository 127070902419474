.carousel-landing-slider-container {
    position: relative;

    .carousel-landing-slider {
        height: 80vh;

        &.carousel-categorias-slider {
            height: 50vh;
        }
        

        .carousel-landing-slide-item-container, .categoria-header-base-container, .categoria-header-base-image-container {
            object-fit: cover;
            display: flex !important;
            align-items: center;
            justify-content: center;

            img {
                width: -webkit-fill-available;
                height: -webkit-fill-available;
                object-fit: cover;
                height: 100%;
                width: 100%;

                &.right {
                    object-position: right;

                    &.bottom {
                        object-position: bottom right;
                    }

                    &.top {
                        object-position: top right;
                    }
                }

                &.left {
                    object-position: left;

                    &.top {
                        object-position: top left;
                    }
                }

                &.center {
                    object-position: center;
                }

            }
        }

        .carousel-landing-slide-item-container {
            height: 80vh;
        }

        .categoria-header-base-container {
            position: relative;
        }


        .categoria-header-base-text-container {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;  

            .categoria-header-base-text-content {
                width: 90%;
                margin: 0 auto;
                max-width: 1400px;
                display: flex;
                flex-direction: column;

                &.top {
                    justify-content: flex-start;
                }
    
                &.bottom {
                    justify-content: flex-end;
                    margin-bottom: 5%;
                }
    
                &.middle {
                    justify-content: center;
                }
    
                &.left {
                    align-items: flex-start;
                }
    
                &.right {
                    align-items: flex-end;
                }
    
                &.center {
                    align-items: center;
                    text-align: center;
                }

                h1 {
                    background-color: $primary-yellow;
                    padding: 10px;
                    overflow-wrap: anywhere;
                    width: 40%;
                }

                h3 {
                    font-weight: 500;
                    font-size: 1.5em;
                    width: 60%;
                    background-color: $thertiary-yellow;
                    padding: 10px;
                }
            }
        }
    }

    .carousel-landing-button-container {
        position: absolute;
        width: 100%;
        bottom: 50px;
    }
}

.categoria-header-base-image-container, .categoria-header-base-container {
    width: 100%;
    height: 50vh;

    img {
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        object-fit: cover;
        height: 100%;
        width: 100%;

        &.right {
            object-position: right;

            &.bottom {
                object-position: bottom right;
            }

            &.top {
                object-position: top right;
            }
        }

        &.left {
            object-position: left;

            &.top {
                object-position: top left;
            }
        }

        &.center {
            object-position: center;
        }

    }
}


@media screen and (max-width: 900px) {
    .carousel-landing-slider-container {

        .carousel-landing-slider {

            .categoria-header-base-text-container {

                .categoria-header-base-text-content {

                    h1 {
                        width: 100%;
                    }
    
                    h3 {
                        width: 100%;
                    }
                }

            }

        }

    }
}