.leitura-etiquetas-categoria-selector-container {
    display: flex;
    width: 90%;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1400px;

    .leitura-etiquetas-categoria-selector-item-container {
        width: 14vw;
        max-width: 200px;
        cursor: pointer;
        transition: all $shortest-transition-time;

        .leitura-etiquetas-categoria-item-image-container {
            width: 14vw;
            background-color: $secondary-grey;
            max-width: 200px;
            height: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all $shortest-transition-time;

            img {
                width: -webkit-fill-available;
                padding: 16px;
                filter: grayscale(100%)
            }
        }

        .leitura-etiquetas-categoria-item-text-container {
            background-color: $grey-four;
            display: flex;
            justify-content: center;
            height: 4em;
            align-items: center;
            text-align: center;
            transition: all $shortest-transition-time;

            h3 {
                font-weight: 600;
                text-transform: uppercase;
                font-size: 1em;
            }
        }

        &.active-item {

            .leitura-etiquetas-categoria-item-image-container {
                background-color: $primary-yellow;

                img {
                    filter: none;
                }
            }

            .leitura-etiquetas-categoria-item-text-container {
                background-color: $thertiary-yellow;
                color: white;
            }
        }
    }

    .leitura-etiquetas-categoria-selector-button-group-container {
        display: none;
    }
}

.leitura-etiquetas-description-container {
    background-color: $thertiary-grey;
    padding: 40px 0;

    h1 {
        font-weight: bold;
    }

    .leitura-etiquetas-description-content {
        width: 90%;
        margin: 0 auto;
        max-width: 1400px;

        img {
            width: 100%;
        }
    }
}

.leitura-etiquetas-info-embalagem-container {
    width: 90%;
    margin: 0 auto;
    max-width: 1400px;
    padding: 40px 0;

    h1 {
        font-weight: bold;
    }

    .leitura-etiquetas-info-embalagem-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
            font-weight: 500;
            text-transform: uppercase;
        }

        img {
            width: 100%;
        }
    }

}



@media screen and (max-width: 600px) {
    .leitura-etiquetas-description-container {
    
        .leitura-etiquetas-description-content {
            display: flex;
            flex-direction: column;
            text-align: center;
    
            img {
                margin: 20px auto;
                width: 60%;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .leitura-etiquetas-categoria-selector-container {
    
        .leitura-etiquetas-categoria-selector-item-container {
            width: 28vw;
    
            .leitura-etiquetas-categoria-item-image-container {
                width: 28vw;
            }
        }

        .leitura-etiquetas-categoria-selector-button-group-container {
            display: block;

            .btn-slider-container.btn-slider-next {
                border-color: $thertiary-grey;
            }
        }
    }

    .leitura-etiquetas-info-embalagem-container {
    
        .leitura-etiquetas-info-embalagem-item {
            display: flex;
            flex-direction: column;
    
            img {
                width: 75%;
                margin: 0 auto;
            }
        }
    
    }

}

@media screen and (max-width: 600px) {
    .leitura-etiquetas-categoria-selector-container {
    
        .leitura-etiquetas-categoria-selector-item-container {
            width: 45vw;
    
            .leitura-etiquetas-categoria-item-image-container {
                width: 45vw;
            }
        }
    }
}