.etiqueta-paginator-container {
    
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 25px;
    z-index: 2;
    display: flex;
    align-items: center;

    button {
        border: none;
        background: none;
    }

    .etiqueta-paginator-arrow {
        color: #f2f2f2;
        padding: 5px;

        &.active-button {
            color: $primary-yellow;
            background-color: #f2f2f2;
            border-radius: 50%;
            cursor: pointer;
        }
    }

    span.etiqueta-paginator-text {
        padding: 0 10px;
        -webkit-user-select: none;
        user-select: none;

        .etiqueta-paginator-current-page {
            color: $primary-yellow;
        }
    }
}

.etiqueta-editor-pages-value {
    width: 65%;
}