.product-page-header-container {
    background-color: $thertiary-grey;
    display: flex;
    justify-content: center;

    .product-page-header-content {
        width: 90%;
        max-width: 1400px;
        display: flex;
        justify-content: center;

        .product-page-image-container {
            width: 50%;
            display: flex;
            justify-content: center;
            height: 600px;
            padding: 40px 0;

            img {
                object-fit: scale-down;
                max-width: 100%;
            }
            
        }

        .product-page-text-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 50%;

            h1 {
                font-weight: bold;
                font-size: 3.5em;
                margin: 0;
            }

            h3 {
                margin: 5px;
                font-size: 2em;
                font-weight: 600;
            }

            p {
                font-size: 1.3em;
            }
        }
    }
}

.related-products-container {
    background-color: $primary-yellow;
}

.related-products-content-container {
    margin: 50px auto;
    padding: 35px 5%;
    max-width: 1400px;
    width: 90%;

    h1 {
        font-weight: bold;
        margin: auto;
        font-size: 3.5em;
        margin-bottom: 30px;
    }

    .produto-card-container {
        margin: 10px auto;
        width: 300px !important;

        .produto-card-image-description-container {
            background-color: white;

        }

        &:hover {

            .produto-card-image-description-container {
                .produto-card-description-container {
                    background-color: #fefefe;
                }
            }

            .produto-card-info-container {
                background-color: #dbdbdb;
                color: $primary-black;

                .btn {
                    border-color: $primary-black;
                    color: $primary-black;
                }
            }
        }
    }

    .btn-slider-container.btn-slider-next {
        border-color: $thertiary-grey;
    }
}

.faca-mais-com-etiquetas-container {
    width: 100%;
    height: 800px;
    position: relative;

    img {
        object-fit: cover;
        width: 100%;
        height: -webkit-fill-available;
    }

    .faca-mais-com-etiquetas-text-container {
        position: absolute;
        right: 5%;
        bottom: 55%;
        transform: translateY(50%);
        width: 30%;

        h1 {
            font-weight: bold;
            font-size: 2.5em;
        }

        button {
            height: 100%;
        }
    }
}

@media screen and (max-width: 900px) {
    .product-page-header-container {
        background-color: $thertiary-grey;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 35px;
        align-items: center;

        .product-page-header-content {
            justify-content: center;
            flex-direction: column;
    
            .product-page-image-container {
                width: auto;
                display: flex;
                height: 90vw;
                max-height: 500px;
                padding: 40px 0;
                justify-content: center;

                img {
                    height: 90vw;
                    max-height: 500px;
                }
            }
        
            .product-page-text-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 90%;
                text-align: center;
                margin: 0 auto;
        
                h1 {
                    font-weight: bold;
                    font-size: 2em;
                    margin: 0;
                }
        
                h3 {
                    margin: 5px;
                    font-size: 1.17em;
                    font-weight: 600;
                }
        
                p {
                    font-size: 1.3em;
                }
            }

            .product-content {
                text-align: center;
            }

        }

    }

    .related-products-content-container {
        margin: 50px 0;
        padding: 35px 5%;
    
        .produto-card-container {
            margin: 20px;
        }
    
        .btn-slider-container.btn-slider-next {
            border-color: $thertiary-grey;
        }
    }

    .faca-mais-com-etiquetas-container {
        width: 100%;
        height: auto;
        position: relative;
    
        img {
            object-fit: cover;
            width: 100%;
            height: auto;
        }
    
        .faca-mais-com-etiquetas-text-container {
            position: initial;
            display: block;
            right: unset;
            bottom: unset;
            transform: none;
            width: 100%;
            text-align: center;
            background-color: #efefef;
            padding: 50px;
            box-sizing: border-box;
    
            h1 {
                font-weight: bold;
                font-size: 2.5em;
            }
    
            button {
                height: 100%;
            }
        }
    }
}

.MuiToolbar-root {
    font-family: 'Manrope';
    padding: 0 16px;
    font-size: 1.3em;
    background-color: $primary-grey;
    color: white;
    border: 1px solid #202020;
    border-bottom: none;
}

.MuiTable-root {
    padding: 16px;
}

.MuiTableCell-root {
    font-family: 'Manrope';
    font-weight: 500;
    font-size: 1em;
    border: 1px solid #202020;
}


.MuiTableBody-root {
    & > :nth-of-type(odd) {
        background-color: $thertiary-grey;
    }

    & > :nth-of-type(even) {
        background-color: $grey-four;
    }

}