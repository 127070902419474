.experimente-assistente-busca-container, .experimente-assistente-impressao-container {
    position: relative;
    //height: 800px;

    .experimente-assistente-busca-image-container {
        height: 100vh;
        position: relative;

        img {
            width: 100%;
            object-fit: cover;
            height: 100vh;
            object-position: 15% 50%;
        }
    }

    img {
        width: 100%;
        object-fit: cover;
        height: 800px;
    }

    .experimente-asssistente-busca-text-container, .experimente-asssistente-impressao-text-container {
        position: absolute;
        right: 10%;
        top: 50%;
        width: 30%;
        transform: translateY(-50%);
    }

    .experimente-asssistente-busca-text-container {

        top: 50%;
        h1 {
            font-weight: bold;
            font-size: 2.5em;
        }

        p {
            font-size: 1.5em;
        }
    }

    .experimente-asssistente-impressao-text-container {
        top: 0;
        transform: translateY(25%);

        h1 {
            font-weight: bold;
            font-size: 2.5em;
        }

        p {
            font-size: 1.5em;
        }

    }

    .custom-shape-divider-experimente-assistente-busca {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg);
    }
    
    .custom-shape-divider-experimente-assistente-busca svg {
        position: relative;
        display: block;
        width: calc(168% + 1.3px);
        height: 150px;
        transform: rotateY(180deg);
    }
    
    .custom-shape-divider-experimente-assistente-busca .shape-fill {
        fill: #FFFFFF;
    }

}

.experimente-assistente-impressao-container img {
    object-position: 65% 50%;
}

@media screen and (max-width: 900px) {
    .experimente-assistente-busca-container, .experimente-assistente-impressao-container {
        position: relative;
    
        .experimente-assistente-busca-image-container {
            position: relative;
            height: 800px;

            img {
                object-position: 50%;
                height: 800px;
            }
        }

        img {
            height: 100vw;
        }
    
    
        .experimente-asssistente-busca-text-container {
            width: 90%;
            text-align: center;
            position: static;
            transform: none;

            h1 {
                position: absolute;
                top: 10px;
                left: 50%;
                transform: translateX(-50%);
                width: 80%;
                font-size: 1.8em;    
            }

            &:not(h1) {
                margin: 0 auto;
            }
        }

        .experimente-asssistente-impressao-text-container {
            width: 90%;
            text-align: center;
            position: static;
            transform: none;
            margin: 0 auto;

            h1 {
                font-size: 1.8em;    
            }

            p {
                font-size: 1em;
            }
        }
        
        .custom-shape-divider-experimente-assistente-busca svg {
            
            height: 50px;
            
            
        }  
    
    }
}