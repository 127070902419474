@import './_variables.scss';
@import './_buttons.scss';
@import './_inputs.scss';

.content-header-container {
    width: 100%;
    position: relative;
    height: 600px;

    .content-header-image {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width: 900px) {
    .content-header-container {
        height: 250px;
    }
}

.content-box-container {
    width: $website-width;
    max-width: 400px;
    text-align: center;
    margin: 40px auto;
    background-color: $thertiary-grey;

    .content-box-content {
        padding: 20px;

        h3 {
            font-weight: 500;
            font-size: 1.5em;
            margin: 5px;
        }

        p {
            margin: 5px
        }
    }
    
}

@keyframes shimmer {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}

.placeholder {
    //max-width: 200px;
    display: flex;
    justify-content: center;

    .loading-image {
        height: 160px;
        width: 120px;
        background-color: #dddddd;
        border-radius: 4px;
        max-width: 10vw;
    }

    .loading-text {
        background-color: #dddddd;
        border-radius: 4px;
        height: 20px;
        width: 200px;
        max-width: 10vw;

        &.short {
            width: 100px;
            max-width: 5vw;
        }

        &.long {
            width: 325px;
            max-width: 17vw;
        }

        &.extra-long {
            width: 450px;
            max-width: 30vw;
        }
    }

    .loading-button {
        background-color: #dddddd;
        border-radius: 4px;
        height: 70px;
        width: 200px;
        max-width: 13vw;

        &.actions {
            width: 70px;
        }
    }


    &.shimmer {
        overflow: hidden;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 1;
            animation: shimmer 1s infinite;
            background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 0.4) 50%,
                rgba(255, 255, 255, 0) 100%
            );
        }
    }
}

.floating-button {
    position: fixed;
    right: 0;
    bottom: 5px;
    margin-right: 5px;
    cursor: pointer;
}

svg.whatsapp-icon {
    height: 3.5em;
    width: 3.5em;

    .st0 {
        fill:#FFFFFF;
    }
	.st1 {
        fill:#38AF4A;
    }
}

p.page-paragraph, li.page-paragraph {
    font-size: 1.2em;
}

li.page-paragraph span {
    display: block;
    margin: 1em 0;
}

.MuiSnackbar-root {   
    .MuiPaper-root {
        justify-content: center;    
        font-family: 'Stara';
        font-weight: 500;
        //border-radius: 25px;
    }

    &.info {
        .MuiPaper-root {
            color: #202020;
            background-color: $secondary-yellow;
        }
    }

    &.success {
        .MuiPaper-root {
            color: white;
            background-color: #47b44d;
        }
    }
}

.clickable-span {
    cursor: pointer;
}