.assistant-container {
    background-color: $primary-yellow;
    padding: 30px 50px;

    h2 {
        margin: 10px;
        text-transform: uppercase;

        &.step-number {

        }

        &.step-text {
            font-weight: 500;
        }
    }

     button {
        width: 100%;
        padding: 0;
        color: #202020;
        font-family: 'Manrope';
    }

    .assistant-grid-container {
        margin-bottom: 45px;
    }
}

.assistant-grid-item {
    padding: 15px 30px;
    background-color: white;
    display: flex;
    align-items: center;
    outline: 1px solid black;
    justify-content: center;
    cursor: pointer;
    transition: all 0.5s;
    text-align: center;
    width: 100%;

    &:active {
        background-color: $primary-yellow;
        transition: all 0.5s;
    }

    p {
        height: 3em;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
    }
}

.assistant-main-button-container {
    margin: 40px 0;
}

.step-info-box-container {
    padding: 5px 20px;
    outline: 1px solid black;
    margin: 20px 0;

    .page-paragraph {
        display: flex;
        gap: 10px;
    }

    &.old-step {
        background-color: white;
        cursor: pointer;
    }
}
