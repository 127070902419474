.property-bar-container {
    background-color: $secondary-grey;
    box-shadow:
        0px 3px 1px -2px rgba(0,0,0,0.2),
        0px 2px 2px 0px rgba(0,0,0,0.14),
        0px 1px 5px 0px rgba(0,0,0,0.12);
    position: fixed;
    left: 20px !important;
    top: 220px !important;
    cursor: move;
    z-index: 2;

    .MuiGrid-item {
        min-width: 250px;

        &.double-width-grid-item {
            min-width: 500px;
        }
    }

    &.etiqueta-editor-pages {
        width: 350px;
        right: 20px !important;
        top: 200px !important;
        left: unset !important;

        .btn {
            padding: 7px 25px;
            width: 90%
        }

        .MuiGrid-item {
            min-width: unset;
            display: flex;
            justify-content: center;
        }

        .etiqueta-editor-pages-container {

            .etiqueta-editor-pages-info-container {
                display: flex;
                flex-direction: row;
                padding: 5px 20px;
                background-color: rgb(146, 146, 146);
                color: white;
                align-items: center;
                width: 100%;
                justify-content: space-between;
                margin-bottom: 5px;

                .cursor-button {
                    color: white;
                }
            }

            .etiqueta-editor-pages-preview-container {
                height: 35vh;
                width: 100%;
            }

            .canvas-container {
                background-color: white;
            }
        }

    }
}

.property-bar-title {
    background-color: #595959;
    color: white;
    text-align: center;
    padding: 12px 0px;
    margin-bottom: 5px;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    position: relative;

    .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        &.left {
            left: 20px;
        }
    
        &.right {
            right: 20px;
        }
    }

    
}

.etiqueta-editor-pages {

    .property-bar-title {
        display: flex;
        justify-content: space-between;
        padding: 12px 20px;
        align-items: center;

        button {
            border: none;
            background: none;
            color: white;
        }

        .icon {
            cursor: pointer;
        }
    }

    .etiqueta-editor-pages-page-switcher-container {
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;

        button {
            border: none;
            background: none;
        }

        .etiqueta-editor-pages-inputs {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;

            .MuiFilledInput-input {
                padding: 9px 12px;
            }

            &.add-remove-page-container {
                width: 100%;
                justify-content: space-between;
            }
        }
    }
}

.property-bar-text-itens {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    margin-bottom: 10px;
    min-height: 41px;
    font-size: 0.9em;

    .MuiFilledInput-input {
        padding: 9px 12px;
        font-size: 0.9em;
    }
}

.property-bar-text-color-input {
    width: 70px;
    height: 40px;
    cursor: pointer;
    border: 0px;
    background-color: white;
}

.property-bar-text-options-label {
    cursor: pointer;
    width: -moz-fit-content;
    width: fit-content;
}

.property-bar-text-options-container {
    display: flex;
    justify-content: space-evenly;
    height: 41px;
    margin-bottom: 10px;

    &.text-options-align-left {
        justify-content: flex-start;
    }
}

.property-bar-text-options-button {
    padding: 5px;

    &.active {
        background-color: $primary-yellow;
        cursor: pointer;

        &:hover {
            background-color: $thertiary-yellow;
        }
    }

    &:hover {
        background-color: #ffeb9b;
    }
}

.property-bar-text-options-label {
    cursor: pointer;
    width: -moz-fit-content;
    width: fit-content;
}

.MuiSlider-root {
    color: $primary-yellow;
}

.MuiSlider-thumb {
    border: 2px solid #d5aa05;
    width: 24px;
    height: 24px;
    color: white;
}
