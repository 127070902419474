.timeline-container {
    padding: 30px 50px;
    background-color: $primary-yellow;

    .horizontal.timeline-main-wrapper {
        padding: 0;

        .Outline {
            
        }

        & > div {
            width: 100%;

        }

        .timeline-horizontal-container {
            padding-left: 0;
            justify-content: center;

            .timeline-item-title {
                background: transparent;
            }

            .timeline-circle.active:after {
                display: none;
            }
            
            .timeline-circle.active {
                height: 35px;
            }
            .timeline-circle {
                width: 3px;
                border-radius: 1.5px;
                height: 25px;
            }
        }
    }
}

.timeline-slider-container {
    background-color: $primary-yellow;
    padding: 60px 0;
}

.timeline-item-container {
    background-color: rgba(0, 0, 0, 0.15);
    width: $website-width;
    margin: 0 auto;
    display: flex;
    height: 375px;
    max-width: 1400px;

    .timeline-item-image-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40%;

        img {
            max-width: -webkit-fill-available;
        }
    }


    .timeline-item-text-container {
        padding: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h1 {
            margin: 0;
            font-weight: 500;
            font-size: 2.2em;
        }
    }
}

@media screen and (max-width: 900px) {
    .timeline-item-container {
        height: 400px;

        .timeline-item-text-container  {
            padding: 15px;
        }
    }

    .button-slider-container {
        justify-content: center;
    }
    
}

@media (hover: none) and (pointer: coarse) and (max-width: 600px) {
    .timeline-item-container {

        .timeline-item-text-container  {
            overflow-y: scroll;
        }
    }
}

@media screen and (max-width: 600px) {
    .timeline-item-container {
        height: 70vh;
        flex-direction: column;

        .timeline-item-image-container {
            width: 100%;
        }

        .timeline-item-text-container  {
            padding-top: 0;
            margin-top: 15px;
            justify-content: flex-start;
        }
    }
}